/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/react-in-jsx-scope */
import Lottie from "lottie-react"
import Maintenance from "../assets/lottie/maintenance.json"
import { COLORS, SIZE } from "../export/const"

function Error() {
  const styles = {
    container: {
      height: "100vh",
      width: "100vw",
      backgroundColor: COLORS.black,
      color: COLORS.white,
      display: "flex",
      flexDirection: "column",
      fontFamily: "SFProBold",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      margin: "auto",
      textAlign: "center",
      marginTop: "7%",
      marginBottom: 0,
      position: "relative",
      fontSize: "50px",
      fontFamily: "SFProBold",
    },
    subtitle: {
      margin: "auto",
      marginTop: "20px",
      textAlign: "center",
      position: "relative",
      fontSize: "40px",
      fontFamily: "SFProBold",
    },
    lottie: {
      width: "50vh",
      height: "50vh",
      marginTop: "-100px",
      textAlign: "center",
      lineHeight: "100px",
      margin: "auto",
    },
    text: {
      marginTop: "-60px",
      margin: "auto",
      textAlign: "center",
      fontSize: "20px",
      fontFamily: "SFProBold",
    },
  }
  return (
    <div style={styles.container}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            borderRadius: 5,
            fontFamily: "SFProBold",
            background:
              "linear-gradient(to bottom right, #93F5EC 20%, #A77BF3 84%)",
            color: COLORS.black,
            padding: 5,
            fontSize: SIZE.title,
          }}
        >
          BETA
        </div>
        <div>
          <Lottie animationData={Maintenance} style={styles.lottie} loop />
        </div>
        <div
          style={{
            fontSize: SIZE.title,
            paddingBottom: 10,
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          CapitaLille est actuellement en maintenance
        </div>
        <div>Nous serons bientôt de retour.</div>
      </div>
    </div>
  )
}

export default Error
