/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react"
import anime from "animejs/lib/anime.es.js"
import { COLORS } from "../../export/const"
import Button from "../button"
import { ReactComponent as CrownIcon } from "../../assets/svg/crown.svg"

// eslint-disable-next-line import/no-cycle

const BuyHouse = forwardRef((props, ref) => {
  const [displayGameOver, setDisplayGameOver] = useState(false)
  const [winner, setWinner] = useState("")
  const [profil, setProfil] = useState("")
  const [countHouse, setCountHouse] = useState()
  const [countMoney, setCountMoney] = useState()
  const [countPopularity, setCountPopularity] = useState()

  const launchGameOver = (user, photo, nbrHouse, nbrMoney, nbrPopularity) => {
    setWinner(user)
    setProfil(photo)
    setCountHouse(nbrHouse)
    setCountMoney(nbrMoney)
    setCountPopularity(nbrPopularity)
    setDisplayGameOver(true)
  }

  useImperativeHandle(ref, () => ({
    launchGameOver,
  }))

  const style = {
    layer: {
      display: "flex",
      width: "100%",
      height: "100%",
      margin: 0,
      padding: 0,
      zIndex: 20,
      position: "absolute",
    },
    svg: {
      position: "relative",
      top: -220,
      zIndex: 0,
    },
    card: {
      border: `solid ${COLORS.yellow} 5px`,
      display: "flex",
      backgroundColor: COLORS.white,
      borderRadius: 30,
      padding: 30,
      width: 500,
      zIndex: 30,
      top: "55%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: COLORS.black,
      height: "370px",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
    },
    boxProfil: {
      border: `solid ${COLORS.yellow} 5px`,
      borderRadius: 10000,
      position: "absolute",
      top: -105,
      backgroundColor: COLORS.white,
      width: 200,
      height: 200,
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      zIndex: 30,
      display: "flex",
    },
    profil: {
      borderRadius: 10000,
      height: 180,
      width: 180,
      backgroundColor: "green",
      position: "relative",
      zIndex: "9999",
    },
    title: {
      position: "relative",
      fontSize: "26px",
      color: COLORS.black,
      fontFamily: "SFProBold",
      lineHeight: "26px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      top: "-35px",
    },
    boxText: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: COLORS.white,
      boxShadow: "rgba(0, 0, 0, 0.25) 3px 4px 4px",
      borderRadius: 20,
      position: "relative",
      top: -20,
      textAlign: "center",
      display: "flex",
    },
    text: {
      padding: 15,
      color: COLORS.grey,
      fontSize: "20px",
      lineHeight: "20px",
      display: "flex",
    },
    userTag: {
      color: COLORS.black,
    },
    boxStat: {
      width: "100%",
      backgroundColor: COLORS.white,
      boxShadow: "rgba(0, 0, 0, 0.25) 3px 4px 4px",
      borderRadius: 20,
      position: "relative",
      top: 0,
      height: 130,
      flexDirection: "column",
      display: "flex",
    },
    stat: {
      padding: 15,
      paddingLeft: 30,
      color: COLORS.grey,
      fontSize: "18px",
      lineHeight: "18px",
    },
    button: {
      position: "relative",
      display: "flex",
      width: "100%",
      margin: "auto",
      justifyContent: "center",
      fontSize: "17px",
      top: 15,
    },
  }

  useEffect(() => {
    if (!displayGameOver) {
      anime({
        targets: "#GameOverLayer",
        translateY: "100%",
        duration: 1000,
      })
    } else {
      anime({
        targets: "#GameOverLayer",
        translateY: "0%",
        duration: 1000,
      })
    }
  }, [displayGameOver])

  const hideGameOver = () => {
    anime({
      targets: "#GameOverLayer",
      translateY: "100%",
      duration: 1000,
    })
    setTimeout(() => {
      setDisplayGameOver(false)
    }, 1000)
  }

  return (
    <div id="GameOverLayer" style={style.layer}>
      {displayGameOver && (
        <div style={style.card}>
          <CrownIcon
            height={1000}
            width={1000}
            fill={COLORS.yellow}
            style={style.svg}
          />
          <div style={style.boxProfil}>
            <div
              style={{
                width: 300,
                height: 110,
                backgroundColor: COLORS.white,
                position: "absolute",
                top: 100,
                zIndex: 31,
              }}
            >
              *
            </div>
            <img src={profil} alt="" style={style.profil} />
          </div>
          <div style={style.title}>· Fin de la partie ·</div>
          <div style={style.boxText}>
            <div style={style.text}>
              Félicitations à{" "}
              <span style={style.userTag}>&nbsp;{winner}&nbsp;</span> qui a
              remporté la partie.
            </div>
          </div>
          <div style={style.boxStat}>
            <div style={style.stat}>
              <li>Maisons en possession : {countHouse}</li>
              <br />
              <li>Argent amassé : {countMoney}</li>
              <br />
              <li>Popularité gagnée : {countPopularity}</li>
            </div>
          </div>
          <div style={style.button}>
            <Button
              color={COLORS.black}
              text="Rentrer dans la nouvelle partie"
              onClick={() => {
                hideGameOver()
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
})

export default BuyHouse
