/* eslint-disable import/no-cycle */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { COLORS, STYLES } from "../../export/const"
import anime from "animejs/lib/anime.es.js"
import Card from "./card"
import { CSSTransition } from "react-transition-group"
// eslint-disable-next-line import/no-cycle
import { setListenUser } from "../../export/listener"
import "./chanceCard.css"

// eslint-disable-next-line no-var
var cardRotation = 0
// eslint-disable-next-line no-unused-vars

const ChanceCard = forwardRef((props, ref) => {
  const [showFront, setShowFront] = useState(true)
  const [showCard, setShowCard] = useState(true)

  const { setNewMoney, newMoney } = props
  const { setNewReputation, newReputation } = props
  const { setPlayerName } = props
  const { setTurnPlayed } = props

  useEffect(() => {
    if (showCard === true) {
      anime({
        targets: "#flipCardContainer",
        translateY: [0, 900],
        translateX: ["-50%", "-50%"],
        // opacity: [0, 1],
        duration: 1000,
      })
    } else {
      anime({
        targets: "#flipCardContainer",
        translateY: [900, 0],
        translateX: ["-50%", "-50%"],

        // opacity: [1, 0],
        duration: 1000,
      })
    }
  }, [showCard])

  // eslint-disable-next-line prefer-const

  const [titre, setTitre] = useState("")
  const [phrase, setPhrase] = useState(" ")
  const [icon, setIcon] = useState(" ")
  const [varMoney, setVarMoney] = useState(0)
  const [varReputation, setVarReputation] = useState(0)

  const turnCard = () => {
    cardRotation += 1
    if (cardRotation % 2 === 1) {
      setShowFront(false)
      setNewMoney(newMoney + varMoney)
      setNewReputation(newReputation + varReputation)
      setListenUser(setNewMoney, setNewReputation, setPlayerName, setTurnPlayed)
    }
    if (showFront === false) {
      setShowFront(true)
      setShowCard(!showCard)
      cardRotation = 0
    }
  }
  // const [newMoney, setNewMoney] = useState(0)

  function launchCard(
    cardTitre,
    cardPhrase,
    cardIcon,
    cardVarMoney,
    cardVarReputation
  ) {
    setPhrase(cardPhrase)
    setTitre(cardTitre)
    setIcon(cardIcon)
    setVarMoney(cardVarMoney)
    setVarReputation(cardVarReputation)
    setShowCard(!showCard)
  }

  useImperativeHandle(ref, () => ({
    launchCard,
    // newMoney,
  }))
  return (
    <div className="flipCardContainer" id="flipCardContainer">
      <CSSTransition in={showFront} timeout={300} classNames="flip">
        <Card
          onClick={() => {
            turnCard()
          }}
          titre={titre}
          phrase={phrase}
          icon={icon}
          // currentMoney={currentMoney}
        />
      </CSSTransition>
    </div>
  )
})

export default ChanceCard
