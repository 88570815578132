/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from "react"
import { COLORS, RESIZE, STYLES } from "../../export/const"
import anime from "animejs/lib/anime.es.js"

function Avatar({
  setPlayerPos,
  playerPos,
  newPlayerPos,
  setNewPlayerPos,
  index,
  pdp,
  openCard,
}) {
  const [pos, setPos] = useState(playerPos[index].coordinate)
  const [hover, setHover] = useState(false)
  const size = 50

  const styles = {
    container: {
      transform: "translate(-50%, -50%)",
      borderRadius: 2000,
      aspectRatio: 1,
      backgroundColor: COLORS.white,
      cursor: "pointer",
      width: size,
      height: size,
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
  }

  const pourcent = {
    cycles: 0,
  }

  const getSinglePlayerPos = () => playerPos[index].coordinate

  const setSinglePlayerPos = (newPosToAssign) => {
    let playerPosLocal = playerPos
    playerPosLocal[index].coordinate = newPosToAssign
    setPlayerPos([...playerPosLocal])
  }

  const getNewSinglePlayerPos = () => newPlayerPos[index].coordinate

  const setNewSinglePlayerPos = (newPosToAssign) => {
    let playerPosLocal = newPlayerPos
    playerPosLocal[index].coordinate = newPosToAssign
    setNewPlayerPos([...playerPosLocal])
  }

  useEffect(() => {
    const difX = getNewSinglePlayerPos()[0] - pos[0]
    const difY = getNewSinglePlayerPos()[1] - pos[1]
    const oldX = pos[0]
    const oldY = pos[1]

    anime({
      targets: pourcent,
      cycles: [0, 100],
      round: 1,
      easing: "easeInOutExpo",
      update() {
        const addX = difX * (pourcent.cycles / 100)
        const addY = difY * (pourcent.cycles / 100)
        setSinglePlayerPos([oldX + addX, oldY + addY])
        setPos([oldX + addX, oldY + addY])
      },
      duration: 1000,
    })
  }, [newPlayerPos])

  useEffect(() => {
    if (hover === true) {
      anime({
        targets: `#player${playerPos[index].id}`,
        scale: [1, 1.1],
        easing: "easeOutExpo",
        duration: 100,
      })
    } else {
      anime({
        targets: `#player${playerPos[index].id}`,
        scale: [1.1, 1],
        easing: "easeOutExpo",
        duration: 100,
      })
    }
  }, [hover])

  return (
    <div
      style={styles.container}
      id={`player${playerPos[index].id}`}
      onClick={() => {
        openCard("player", playerPos[index].id)
      }}
      onMouseOver={() => {
        setHover(true)
      }}
      onMouseOut={() => {
        setHover(false)
      }}
    >
      <img
        style={{
          borderRadius: 1000,
          backgroundColor: COLORS.white,
          width: size,
          padding: size / 10,
          height: size,
          aspectRatio: 1,
          objectFit: "cover",
        }}
        src={pdp}
        alt="Logo"
      />
    </div>
  )
}

export default Avatar
