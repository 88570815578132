/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import firebase from "firebase/compat/app"

import { useNavigate } from "react-router-dom"
import Lottie from "lottie-react"
import diceAnim from "../assets/lottie/phone.json"
import { Main, mainData } from "./main"
import { COLORS } from "../export/const"
import "firebase/compat/auth"
import gameAnime from "../assets/lottie/loading.json"

function Protector({ casesData, housesData, maptilerProvider }) {
  const [data, setData] = useState(undefined)
  const navigate = useNavigate()

  const mainDataFetch = async () => {
    const result = await mainData()
    setData(result)
  }

  const fetch = async () => {
    firebase.auth().onAuthStateChanged(async (authState) => {
      if (authState !== null) {
        setTimeout(() => {
          mainDataFetch()
        }, 200)
      } else {
        navigate("/login")
      }
    })
  }

  useEffect(() => {
    fetch()
  }, [])

  return data ? (
    <Main
      casesData={casesData}
      housesData={housesData}
      maptilerProvider={maptilerProvider}
      data={data}
    />
  ) : (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          color: COLORS.black,
          justifyContent: "center",
          alignSelf: "center",
          alignItems: "center",
          alignContent: "center",
          borderRadius: 1000,
          width: "20%",
        }}
      >
        <Lottie animationData={gameAnime} />
      </div>
    </div>
  )
  // window.innerWidth < 700 ? (
  // ) : (
  //   <div
  //     style={{
  //       display: "flex",
  //       justifyContent: "center",
  //       alignContent: "center",
  //       alignItems: "center",
  //       height: "100vh",
  //       width: "100vw",
  //       backgroundColor: COLORS.black,
  //       fontFamily: "SFProBold",
  //       color: COLORS.white,
  //       flexDirection: "column",
  //       gap: 30,
  //     }}
  //   >
  //     <div style={{ width: 150, height: 150 }}>
  //       <Lottie
  //         animationData={diceAnim}
  //         width={150}
  //         style={{ transform: "translate(0, -10px)" }}
  //       />
  //     </div>
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "column",
  //         padding: 30,
  //         textAlign: "center",
  //         alignContent: "center",
  //         alignItems: "center",
  //         justifyContent: "center",
  //         gap: 10,
  //       }}
  //     >
  //       <div
  //         style={{
  //           borderRadius: 5,
  //           fontFamily: "SFProBold",
  //           background:
  //             "linear-gradient(to bottom right, #93F5EC 20%, #A77BF3 84%)",
  //           color: COLORS.black,
  //           padding: 5,
  //         }}
  //       >
  //         BETA
  //       </div>
  //       Capitalille ne se joue pas sur téléphone. (pour l'instant)
  //     </div>
  //   </div>
  // )
}

export default Protector
