/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React from "react"
import { COLORS, PRICE } from "../../export/const"
import Button from "../button"
import Player from "../player"
import houseIcon from "../../assets/cards/smallHouse.png"
import discountIcon from "../../assets/svg/reduction.png"

// eslint-disable-next-line import/no-cycle

const BuyHouse = (props) => {
  const { cost, owner, pdp, address, isDiscount, handleClick } = props
  const style = {
    card: {
      display: "flex",
      backgroundColor: COLORS.white,
      borderRadius: 30,
      padding: 30,
      width: 310,
      zIndex: 30,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: COLORS.black,
      height: "200px",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
    },
    icon: {
      position: "relative",
      transform: "translate(0px, -150px)",
      width: "setStyleWidth(50%)",
    },
    title: {
      position: "absolute",
      fontSize: "22px",
      color: COLORS.black,
      fontFamily: "SFProBold",
      lineHeight: "26px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      top: "20px",
    },
    address: {
      color: COLORS.grey,
      textAlign: "center",
      position: "absolute",
      top: "50px",
      fontSize: "15px",
      fontFamily: "SFProMedium",
      lineHeight: "26px",
    },
    boxText: {
      width: "250px",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: COLORS.white,
      boxShadow: "rgba(0, 0, 0, 0.25) 3px 4px 4px",
      borderRadius: 20,
      display: "flex",
      justifyContent: "center",
    },
    text: {
      padding: 15,
      fontSize: "16px",
      lineHeight: "16px",
      color: COLORS.black,
    },
    svg: {
      height: 30,
      width: 30,
      fill: COLORS.white,
      verticalAlign: "middle",
      margin: 5,
    },
    button: {
      position: "relative",
      top: "5px",
      display: "flex",
      width: "270px",
      justifyContent: "center",
    },
    discount: {
      height: 75,
      width: 75,
      position: "absolute",
      top: "170px",
      left: "260px",
    },
  }

  return (
    <div style={style.card}>
      <img src={houseIcon} alt="" style={style.icon} />
      <div style={style.title}>· Loyer ·</div>
      <div style={style.address}>{address}</div>
      <div style={{ position: "absolute", ttrueop: "0px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            position: "relative",
            top: "-20px",
          }}
        >
          <div style={style.boxText}>
            <span style={style.text}>
              Mais c’est ignoble ici ! Comment on fait pour vivre dans un gourbi
              pareil ?
            </span>
          </div>
          <div style={{ marginLeft: 5 }}>
            <Player nextOwnerName={owner} nextOwnerPdp={pdp} />
          </div>
        </div>
      </div>
      <div style={style.button}>
        <div style={{ marginLeft: 1, marginRight: 1 }}>
          <Button
            color={COLORS.black}
            text="Payer "
            cost={PRICE(Math.abs(cost))}
            onClick={() => {
              handleClick()
            }}
          />
        </div>
      </div>
      {isDiscount && <img src={discountIcon} alt="" style={style.discount} />}
    </div>
  )
}

export default BuyHouse
