/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React from "react"
import { COLORS, PRICE } from "../../export/const"
import Button from "../button"
import taxIcon from "../../assets/cards/bank.png"

// eslint-disable-next-line import/no-cycle

const BuyBank = (props) => {
  const { cost, handleClick } = props

  const style = {
    card: {
      display: "flex",
      backgroundColor: COLORS.white,
      borderRadius: 30,
      padding: 30,
      width: 310,
      zIndex: 30,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: COLORS.black,
      height: "170px",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
    },
    icon: {
      position: "relative",
      transform: "translate(0px, -170px)",
      width: "70%",
    },
    title: {
      fontSize: "22px",
      color: COLORS.black,
      fontFamily: "SFProBold",
      lineHeight: "26px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      position: "relative",
      top: "-180px",
    },
    boxText: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: COLORS.white,
      boxShadow: "rgba(0, 0, 0, 0.25) 3px 4px 4px",
      borderRadius: 20,
      position: "relative",
      top: "45px",
    },
    text: {
      padding: 15,
      color: COLORS.grey,
      fontSize: "16px",
      lineHeight: "16px",
      display: "flex",
    },
    svg: {
      height: 30,
      width: 30,
      fill: COLORS.white,
      verticalAlign: "middle",
      margin: 5,
    },
    button: {
      top: "60px",
      position: "relative",
      display: "flex",
      width: "45%",
      justifyContent: "center",
      margin: "auto",
    },
  }

  return (
    <div style={style.card}>
      <img src={taxIcon} alt="" style={style.icon} />
      <div style={style.title}>· Banque ·</div>
      <div style={{ position: "absolute", top: "10px" }}>
        <div style={style.boxText}>
          <span style={style.text}>
            C’est l’or, il est l’or, l’or de donner de l’argent.
          </span>
        </div>
        <div style={style.button}>
          <div style={{ marginLeft: 1, marginRight: 1 }}>
            <Button
              color={COLORS.black}
              text="Payer "
              cost={PRICE(Math.abs(cost))}
              onClick={() => {
                handleClick()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyBank
