/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from "react"
import { COLORS, PRICE, RESIZE, STYLES } from "../../export/const"
import anime from "animejs/lib/anime.es.js"
import { ReactComponent as House } from "../../assets/svg/house.svg"
import { ReactComponent as MoneyBag } from "../../assets/svg/sack-dollar.svg"
import { ReactComponent as Hostel } from "../../assets/svg/hotel.svg"
import { ReactComponent as Selling } from "../../assets/svg/enchere.svg"

import { ReactComponent as Money } from "../../assets/svg/money.svg"
import "./houseOverlay.css"
import { getPlayerByIdLocal } from "../../online/user"

// eslint-disable-next-line no-unused-vars

function HouseOverlay({ openCard, housesData, zoom, playersData }) {
  const [lastZoom, setLastZoom] = useState(zoom)
  const ref = useRef()
  const PR = housesData.levelRentPrice[housesData.level]
  const [owner, setOwner] = useState(undefined)
  const styles = {
    container: {
      cursor: "pointer",
      borderRadius: 50,
      transform: "translate(-50%, -50%)",
      display: "flex",
      fontFamily: "SFProBold",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: 10,
      backgroundColor: housesData.level === 4 ? COLORS.yellow : COLORS.white,
      color: housesData.level === 4 ? COLORS.white : COLORS.black,
    },
  }

  const [enable, setEnable] = useState(0)

  useEffect(() => {
    setEnable(RESIZE(zoom, "houseStat") === 1)

    if (lastZoom > 15) {
      if (zoom <= 15) {
        anime({
          targets: ref.current,
          scale: [1, 0.2],
          duration: 800,
        })
      } else if (Math.abs(lastZoom - zoom) >= 1) {
        anime({
          targets: ref.current,
          scaleX: [0.5, 1],
          duration: 800,
        })
      }
    }
    if (lastZoom <= 15) {
      if (zoom > 15) {
        anime({
          targets: ref.current,
          scale: [0.2, 1],
          duration: 800,
        })
      }
    }
    setLastZoom(zoom)
  }, [zoom])

  useEffect(() => {
    setOwner(getPlayerByIdLocal(housesData.ownerId, playersData))
  }, [])

  return enable ? (
    <div
      onClick={() => {
        openCard("house", housesData.houseId)
      }}
      ref={ref}
      style={{
        ...styles.container,
        padding: owner ? 7.5 : 10,
      }}
    >
      {housesData.state === "selling" ? (
        <Selling
          width={20}
          fill={housesData.level === 4 ? COLORS.white : COLORS.black}
        />
      ) : housesData.ownerId !== "" && owner ? (
        <>
          {/* {owner.name} */}
          {PRICE(PR)}

          {/* <Money fill={COLORS.black} style={{ paddingLeft: 5 }} /> */}
          <img
            src={owner.pdp}
            alt="pdp"
            width={30}
            style={{
              marginLeft: 5,
              borderRadius: 1000,
              aspectRatio: 1,
              objectFit: "cover",
            }}
          />
        </>
      ) : (
        <>
          {PRICE(PR)}
          <MoneyBag width={20} fill={COLORS.black} style={{ paddingLeft: 5 }} />
        </>
      )}
    </div>
  ) : zoom <= 15 ? (
    <div
      ref={ref}
      onClick={() => {
        openCard("house", housesData.houseId)
      }}
      style={{ ...styles.container, padding: 0, width: 40, height: 40 }}
    />
  ) : (
    <div
      onClick={() => {
        openCard("house", housesData.houseId)
      }}
      ref={ref}
      style={styles.container}
    >
      {housesData.state === "selling" ? (
        <Selling
          width={20}
          fill={housesData.level === 4 ? COLORS.white : COLORS.black}
        />
      ) : housesData.ownerId !== "" ? (
        housesData.level === 4 ? (
          <Hostel fill={COLORS.white} />
        ) : (
          <House fill={COLORS.black} />
        )
      ) : (
        <MoneyBag width={20} fill={COLORS.black} />
      )}
    </div>
  )
}

export default HouseOverlay
