/* eslint-disable no-promise-executor-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { COLORS, STYLES } from "../export/const"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"

const Clock = forwardRef((props, ref) => {
  // eslint-disable-next-line no-var
  const navigate = useNavigate()

  let wait = 1000
  const roundDuration = 60 // en min
  const reserve = 60 // en secondes
  let now = new Date() // date et heure actuelle

  function getNextOccurence() {
    const nextOccurrence = new Date(now)
    nextOccurrence.setHours(0, 0, 0, 0) // mettre l'heure à minuit
    while (nextOccurrence.getTime() < now.getTime()) {
      nextOccurrence.setMinutes(nextOccurrence.getMinutes() + roundDuration)
    }
    return nextOccurrence
  }

  function getNearestOccurrence() {
    const nextOccurrence = new Date(now)
    nextOccurrence.setHours(0, 0, 0, 0) // mettre l'heure à minuit
    let previousOccurrence = new Date(
      nextOccurrence.getTime() - roundDuration * 60 * 1000
    )
    while (nextOccurrence.getTime() < now.getTime()) {
      previousOccurrence = new Date(nextOccurrence)
      nextOccurrence.setMinutes(nextOccurrence.getMinutes() + roundDuration)
    }
    const diffNext = nextOccurrence.getTime() - now.getTime()
    const diffPrev = now.getTime() - previousOccurrence.getTime()

    return diffNext < diffPrev ? nextOccurrence : previousOccurrence
  }

  let nextOccurrence = getNextOccurence()

  function calculateRemainingTime(timestampVar) {
    const fiveSecondsBeforeTimestamp = timestampVar - reserve * 1000 // reserve avant le timestamp
    const timeDiff = fiveSecondsBeforeTimestamp - Date.now() // différence de temps entre maintenant et reserve en secondes avant le timestamp
    return {
      hours: Math.floor((timeDiff / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((timeDiff / (1000 * 60)) % 60),
      seconds: Math.floor((timeDiff / 1000) % 60),
    }
  }

  const [remainingTime, setRemainingTime] = useState(
    calculateRemainingTime(nextOccurrence)
  )

  const startCounter =
    new Date(roundDuration * 60 * 1000 - reserve * 1000 * 2) + Date.now()

  const startCounterDetail = {
    hours: Math.floor((startCounter / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((startCounter / (1000 * 60)) % 60),
    seconds: Math.floor((startCounter / 1000) % 60),
  }

  const [showToast, setShowToast] = useState(false)

  const [counter, setCounter] = useState(new Date())

  // fonction pour le back :
  function isReserve() {
    const nearestEndReserve = new Date(
      getNearestOccurrence().getTime() + reserve * 1000
    )
    const nearestStartReserve = new Date(
      getNearestOccurrence().getTime() - reserve * 1000
    )
    const nextOc = getNextOccurence()
    const nearestOc = getNearestOccurrence()
    const nowTime = new Date()
    if (
      (nowTime <= nearestOc && nowTime >= nearestStartReserve) ||
      (nowTime <= nearestEndReserve && nearestOc <= nowTime)
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      now = new Date()
      const remainTime = calculateRemainingTime(nextOccurrence)
      setRemainingTime(remainTime)

      wait = 1000
      if (
        remainTime.hours <= 0 &&
        remainTime.minutes <= 0 &&
        remainTime.seconds <= -5
      ) {
        now = new Date()
        now.setSeconds(now.getSeconds() + 1)
        nextOccurrence = getNextOccurence()
        wait = reserve * 1000
      }

      const remainSec =
        remainTime.hours * 3600 + remainTime.minutes * 60 + remainTime.seconds

      const nearestEndReserve = new Date(
        getNearestOccurrence().getTime() + reserve * 1000
      )
      const nearestOc = new Date(getNearestOccurrence().getTime())

      setCounter(new Date(nearestEndReserve - now))

      if (remainSec <= 0 || (now <= nearestEndReserve && nearestOc <= now)) {
        setShowToast(true)
      } else {
        setShowToast(false) // false
      }
    }, wait)

    return () => clearInterval(intervalId)
  }, [])

  const [toastId, setToastId] = useState(undefined)

  useEffect(() => {
    if (showToast === true) {
      if (toastId !== undefined) {
        toast.loading(
          `Prochain tour dans ${counter
            .getMinutes()
            .toString()
            .padStart(2, "0")}:${counter
            .getSeconds()
            .toString()
            .padStart(2, "0")}`,
          {
            duration: 9999999999,
            style: {
              backgroundColor: COLORS.red,
              color: COLORS.white,
              fontWeight: "bold",
              width: 300,
              height: 35,
              marginTop: -7,
              fontSize: 22,
              padding: 20,
              paddingLeft: 30,
            },
            // icon: "⏲",
            iconTheme: {
              primary: COLORS.red,
              secondary: "#fff",
            },

            id: toastId,
          }
        )
      } else {
        setToastId(
          toast.loading(
            `Prochain tour dans ${counter
              .getMinutes()
              .toString()
              .padStart(2, "0")}:${counter
              .getSeconds()
              .toString()
              .padStart(2, "0")}`,

            {
              duration: 9999999999,
              style: {
                backgroundColor: COLORS.red,
                color: COLORS.white,
                fontWeight: "bold",
                width: 300,
                height: 35,
                marginTop: -7,
                fontSize: 22,
                padding: 20,
                paddingLeft: 30,
                boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
              },
              // icon: "⏲",
              iconTheme: {
                primary: COLORS.red,
                secondary: "#fff",
              },
            }
          )
        )
      }
    }
    if (toastId !== undefined && !showToast) {
      toast.dismiss(toastId)
      navigate("/login")
    }
  }, [showToast, counter])

  return (
    <div style={{ width: 50 }}>
      {remainingTime.hours > 0 ? `${remainingTime.hours}:` : null}
      {(remainingTime.hours <= 0 &&
        remainingTime.minutes <= 0 &&
        remainingTime.seconds <= 0) ||
      (remainingTime.hours <= startCounterDetail.hours &&
        remainingTime.minutes >= startCounterDetail.minutes &&
        remainingTime.seconds >= startCounterDetail.seconds)
        ? "-:-"
        : `${remainingTime.minutes
            .toString()
            .padStart(2, "0")}:${remainingTime.seconds
            .toString()
            .padStart(2, "0")}`}
    </div>
  )
})

export default Clock
