/* eslint-disable no-unused-vars */
import React from "react"
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
  useNavigate,
} from "react-router-dom"
import firebase from "firebase/compat/app"
import { maptiler } from "pigeon-maps/providers"
import { Main, mainData } from "./pages/main"
import geoHouse from "./assets/geoJson/houses"
import geoCases from "./assets/geoJson/cases"
import Login from "./pages/login"
import "firebase/compat/auth"
import Protector from "./pages/protector"
import Error from "./pages/error.js"

function App() {
  const map = maptiler("huVX7hkzwPTJEeV6iaEd", "streets-v2-dark")
  // const map = maptiler("tn9sXO4Jxd0xpshA4sNv", "streets-v2")

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<Error />}>
        <Route path="error" element={<Error />} />

        <Route path="login" element={<Login />} />
        <Route
          path=""
          element={
            <Protector
              casesData={geoCases}
              housesData={geoHouse}
              maptilerProvider={map}
            />
          }
        />
      </Route>
    )
  )

  return <RouterProvider router={router} />
}
export default App
