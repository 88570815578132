/* eslint-disable default-case */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { COLORS, STYLES } from "../../export/const"
import anime from "animejs/lib/anime.es.js"
import { ReactComponent as DiceLogo } from "../../assets/svg/dice.svg"
import { ReactComponent as Luck } from "../../assets/svg/luck.svg"
import "./card.css"
import "./flip-transition.css"
import { Icon } from "@mui/material"
import IconComp from "./iconComp"

// eslint-disable-next-line no-unused-vars

const Card = forwardRef((props, ref) => {
  const [titreContent, setTitreContent] = useState(props.titre)
  const [headCard, setHeadCard] = useState(" ")
  const [borderColor, setBorderColor] = useState(COLORS.green)

  const styles = {
    icon: {
      zIndex: 10,
      marginLeft: "12px",
      marginRight: "12px",
    },
    iconBack: {
      zIndex: 10,
      marginLeft: "12px",
      marginRight: "12px",
      width: "100%",
    },
    cardBack: {
      backgroundColor: borderColor,
    },
    boxText: {
      top: 230,
      height: 180,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    text: {
      color: COLORS.grey,
      fontStyle: "italic",
      fontSize: 16,
    },
  }

  useEffect(() => {
    setTitreContent(props.titre)
  }, [props.titre])
  useEffect(() => {
    switch (props.icon) {
      case "MONEY_BONUS" || "MONEY_Malus":
        setHeadCard("ARGENT")
        setBorderColor(COLORS.yellow)
        break

      case "POPULARITY_MALUS":
        setHeadCard("POPULARITÉ")
        setBorderColor(COLORS.blue)
        break

      case "POPULARITY_BONUS":
        setHeadCard("POPULARITÉ")
        setBorderColor(COLORS.blue)
        break
      case "DICE_BONUS":
        setHeadCard("BONUS DÉS")
        setBorderColor(COLORS.blueLuck)
        break
      case "DICE_MALUS":
        setHeadCard("MALUS DÉS")
        setBorderColor(COLORS.blueLuck)
        break
      case "RENT_DISCOUNT":
        setHeadCard("REMISE DE LOYER")
        setBorderColor(COLORS.greenLow)

        break
      case "IMMUNITY":
        setHeadCard("IMMUNITÉ")
        setBorderColor(COLORS.orange)

        break
      case "MOVE_BONUS":
        setHeadCard("DÉPLACEMENT")
        setBorderColor(COLORS.purple)
        break
      case "MOVE_MALUS":
        setBorderColor(COLORS.purple)
        setHeadCard("DÉPLACEMENT")
        break
      case "HOME_LOSS":
        setHeadCard("PERTE D'UNE MAISON")
        setBorderColor(COLORS.red)
        break
    }
  }, [props.icon])
  return (
    <div className="CardContainer" onClick={props.onClick}>
      <div className="card-back" style={styles.cardBack}>
        <div className="cardIntWhite">
          <div className="cardTitle">
            <h1>{headCard}</h1>
          </div>
          <div className="cardContent">
            <h2>{props.titre}</h2>
          </div>
          <IconComp icon={props.icon} style={styles.iconBack} id="iconBack" />
          <div className="cardPhrase" style={styles.boxText}>
            <p style={styles.text}>{props.phrase}</p>
          </div>
        </div>
      </div>
      <div className="card-front">
        <div className="cardInt">
          <div className="circle">
            <Luck fill={COLORS.green} style={styles.icon} />
          </div>
        </div>
      </div>
    </div>
  )
})

export default Card
