/* eslint-disable no-param-reassign */
/* eslint-disable no-promise-executor-return */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from "react"
import { COLORS, PRICE, SIZE, STYLES } from "../../export/const"
import Button from "../button"
import "firebase/compat/firestore"
import { selling, upgradeHouse } from "../../online/user"
import { toast } from "react-hot-toast"

// eslint-disable-next-line no-unused-vars

function HouseManager({ data, newReputation, setHouseSell, setHouseUp }) {
  const ref = useRef()
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }

  const [alreadyUp, setAlreadyUp] = useState(data.level !== data.nextLevel)
  const [alreadySell, setAlreadySell] = useState(
    data.state === "nextTurnSelling" || data.state === "selling"
  )

  function priceWithReputation(price, reputation) {
    return price + (-reputation / 100) * price
  }

  useEffect(() => {
    setAlreadyUp(data.level !== data.nextLevel)
    setAlreadySell(data.state === "nextTurnSelling" || data.state === "selling")
  }, [data])

  const upgradeButton = () => {
    const p = new Promise((resolve, reject) =>
      upgradeHouse(data).then((result) => {
        if (result.code === 1) {
          setHouseUp(true)
          setAlreadyUp(true)
          // eslint-disable-next-line no-param-reassign
          data.nextLevel += 1
          resolve(result)
        } else {
          reject(result)
        }
      })
    )
    toast.promise(
      p,
      {
        loading: "Etude de vos rénovations...",
        success: (e) => <b>{e.message}</b>,
        error: (e) => <b>{e.message}</b>,
      },
      {
        style: {
          backgroundColor: COLORS.white,
          color: COLORS.black,
          fontFamily: "SFProBold",
          height: 35,
          marginTop: -7,
          fontSize: 20,
          minWidth: 200,
          padding: 20,
          whiteSpace: "nowrap",
          boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
        },
      }
    )
  }

  const sellButton = () => {
    const p = new Promise((resolve, reject) =>
      selling(data).then((result) => {
        if (result.code === 1) {
          setAlreadySell(true)
          data.state = "nextTurnSelling"
          setHouseSell(true)
          // eslint-disable-next-line no-param-reassign
          data.state = "nextTurnSelling"
          resolve(result)
        } else {
          reject(result)
        }
      })
    )
    toast.promise(
      p,
      {
        loading: "Mise de votre maison aux enchères...",
        success: (e) => <b>{e.message}</b>,
        error: (e) => <b>{e.message}</b>,
      },
      {
        style: {
          backgroundColor: COLORS.white,
          color: COLORS.black,
          fontFamily: "SFProBold",
          height: 35,
          marginTop: -7,
          fontSize: 20,
          minWidth: 200,
          padding: 20,
          whiteSpace: "nowrap",
          boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
        },
      }
    )
  }

  const upgrade = () => {
    if (data.level === 4) {
      return { disable: true, sent: "Votre maison est à son niveau maximal." }
    }

    if (data.level === 3) {
      return {
        disable: false,
        sent: "Passer à l'hôtel",
        price: `pour ${PRICE(
          priceWithReputation(data.upgradePrice.hostel, newReputation)
        )}`,
      }
    }

    return {
      disable: false,
      sent: `Passer à ${data.level + 2} maisons`,
      price: `pour ${PRICE(
        priceWithReputation(data.upgradePrice.house, newReputation)
      )}`,
    }
  }

  return (
    <div
      ref={ref}
      style={{
        ...STYLES.scndCard,
        width: 250,
        transform: "translate(0px, 180px)",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontFamily: "SFProBold",
        }}
      >
        <div
          style={{
            width: "100%",
            paddingTop: 5,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontFamily: "SFProBold",
          }}
        >
          <div
            style={{
              paddingLeft: 5,
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              fontSize: SIZE.desc,
              justifyContent: "center",
            }}
          >
            Vous pouvez mettre aux enchères votre maison pour le prochain tour,
            les joueurs pourront placer un ordre autant de fois qu'ils le
            veulent en enchérissant à chaque fois 10% de plus que la mise
            précédente.
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: 5,
          paddingTop: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            color: COLORS.grey,
            fontSize: SIZE.desc,
            flexDirection: "column",
            width: "100%",
            gap: 2.5,
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            color={COLORS.red}
            onClick={() => {
              sellButton()
            }}
            disabled={alreadySell}
            text={
              alreadySell
                ? "La maison a déjà été mis en vente sur le marché"
                : "Mettre aux enchères"
            }
            cost={alreadySell ? "" : `pour ${PRICE(data.levelRentPrice[0])}`}
          />
          effectif au prochain tour.
        </div>
        <div
          style={{
            display: "flex",
            color: COLORS.grey,
            fontSize: SIZE.desc,
            flexDirection: "column",
            width: "100%",
            gap: 2.5,
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            color={COLORS.green}
            onClick={() => {
              upgradeButton()
            }}
            disabled={alreadyUp ? true : upgrade().disable}
            text={upgrade().sent}
            cost={upgrade().price}
            reputationShow
            newReputation={newReputation}
          />
          effectif au prochain tour.
        </div>
      </div>
    </div>
  )
}

export default HouseManager
