/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react"
import anime from "animejs/lib/anime.es.js"
import { COLORS } from "../../export/const"
import Button from "../button"
import { ReactComponent as CoinIcon } from "../../assets/svg/coin.svg"

// eslint-disable-next-line import/no-cycle

const BuyHouse = forwardRef((props, ref) => {
  const [displayCardLose, setDisplayCardLose] = useState(false)
  const [loser, setLoser] = useState("")
  const [profil, setProfil] = useState("")
  const [countHouse, setCountHouse] = useState()
  const [countMoney, setCountMoney] = useState()
  const [countPopularity, setCountPopularity] = useState()

  const launchLose = () => {
    setDisplayCardLose(true)
  }

  useImperativeHandle(ref, () => ({
    launchLose,
  }))

  const style = {
    layer: {
      display: "flex",
      width: "100%",
      height: "100%",
      margin: 0,
      padding: 0,
      zIndex: 20,
      position: "absolute",
    },
    svg: {
      position: "relative",
      top: -10,
      zIndex: 32,
    },
    card: {
      border: `solid ${COLORS.red} 5px`,
      display: "flex",
      backgroundColor: COLORS.white,
      borderRadius: 30,
      padding: 30,
      width: 500,
      zIndex: 30,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: COLORS.black,
      height: "300px",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
    },
    boxProfil: {
      border: `solid ${COLORS.red} 5px`,
      borderRadius: 10000,
      position: "absolute",
      top: -105,
      backgroundColor: COLORS.white,
      width: 200,
      height: 200,
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      zIndex: 30,
      display: "flex",
    },
    profil: {
      borderRadius: 10000,
      height: 180,
      width: 180,
      backgroundColor: "green",
      position: "relative",
      zIndex: "9999",
    },
    title: {
      position: "relative",
      fontSize: "26px",
      color: COLORS.black,
      fontFamily: "SFProBold",
      lineHeight: "26px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      top: 15,
      zIndex: 32,
      padding: 10,
    },
    boxText: {
      width: "100%",
      backgroundColor: COLORS.white,
      boxShadow: "rgba(0, 0, 0, 0.25) 3px 4px 4px",
      borderRadius: 20,
      position: "relative",
      top: "10px",
      zIndex: 32,
    },
    text: {
      padding: 30,
      color: COLORS.grey,
      fontSize: "20px",
      lineHeight: "20px",
      zIndex: 32,
    },
    valueStart: {
      color: COLORS.black,
    },
    button: {
      position: "relative",
      display: "flex",
      width: "100%",
      margin: "auto",
      justifyContent: "center",
      fontSize: "17px",
      top: 20,
      alignItems: "center",
      textAlign: "center",
    },
  }

  useEffect(() => {
    if (!displayCardLose) {
      anime({
        targets: "#loseCardLayer",
        translateY: "120%",
        duration: 1000,
      })
    } else {
      anime({
        targets: "#loseCardLayer",
        translateY: "0%",
        duration: 1000,
      })
    }
  }, [displayCardLose])

  const hideCardLoser = () => {
    anime({
      targets: "#loseCardLayer",
      translateY: "120%",
      duration: 1000,
    })
    setTimeout(() => {
      setDisplayCardLose(false)
    }, 1000)
  }

  return (
    <div id="loseCardLayer" style={style.layer}>
      {displayCardLose && (
        <div style={style.card}>
          <div style={style.boxProfil}>
            <div
              style={{
                width: 300,
                height: 150,
                backgroundColor: COLORS.white,
                color: COLORS.white,
                position: "absolute",
                top: 100,
                zIndex: 31,
              }}
            >
              *
            </div>
            <CoinIcon
              height={100}
              width={100}
              fill={COLORS.red}
              style={style.svg}
            />
          </div>
          <div style={style.title}>· Faillite ·</div>
          <div style={style.boxText}>
            <div style={style.text}>
              <div>
                Lors de votre dernier tour, vous êtes tombés sur une case dont
                ne vous n'avez pas les moyens de payer.
              </div>
              <div style={{ marginTop: 10 }}>
                Vous avez été remis à la case départ avec un solde de
                <span style={style.valueStart}>&nbsp; 500k</span>.
              </div>
            </div>
          </div>
          <div style={style.button}>
            <Button
              color={COLORS.black}
              text="Repartir sur la case de départ"
              onClick={() => {
                hideCardLoser()
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
})

export default BuyHouse
