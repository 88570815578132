/* eslint-disable no-promise-executor-return */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import firebase from "firebase/compat/app"
import React, { useEffect, useRef, useState } from "react"
import { COLORS, STYLES } from "../../../export/const"
import police from "../../../assets/cards/police.png"
import Player from "../../player"
import { complainte } from "../../../online/user"
import { toast } from "react-hot-toast"

// eslint-disable-next-line no-unused-vars

function PoliceCard({ playersData }) {
  const ref = useRef()

  const complainteButton = (targetId) => {
    const p = new Promise((resolve, reject) =>
      complainte(targetId).then((result) => {
        if (result.code === 1) {
          resolve(result)
        } else {
          reject(result)
        }
      })
    )
    toast.promise(
      p,
      {
        loading: "Le fonctionnaire prend en charge votre plainte...",
        success: (e) => <b>{e.message}</b>,
        error: (e) => <b>{e.message}</b>,
      },
      {
        style: {
          backgroundColor: COLORS.white,
          color: COLORS.black,
          fontFamily: "SFProBold",
          height: 35,
          marginTop: -7,
          fontSize: 20,
          minWidth: 200,
          padding: 20,
          whiteSpace: "nowrap",
          boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
        },
      }
    )
  }

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }

  return (
    <div
      style={{
        ...styles.container,
        flexDirection: "row",
        alignItems: "flex-start",
        gap: 5,
      }}
    >
      <div style={styles.container}>
        <img
          src={police}
          alt="police"
          style={{ zIndex: 10, transform: "translate(0, 15px)", width: 200 }}
        />
        <div ref={ref} style={STYLES.card}>
          <div style={STYLES.title}>· Police ·</div>
          <div style={STYLES.desc}>de Lille</div>
          <div style={STYLES.text}>
            Vous êtes au poste de police, ici vous avez la possibilité de porter
            plainte contre n'importe quel joueur de la partie et de faire
            descendre sa réputation dans le jeu.
            <div style={{ fontFamily: "SFProBold" }}>
              Cela implique que le joueur paiera 10% de plus sur tout ses achats
              (hors enchères).
            </div>
          </div>
          <div style={{ ...STYLES.text, paddingTop: 10 }}>
            Choisissez le joueur contre qui vous voulez porter une plainte :
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              fontFamily: "SFProBold",
              color: COLORS.grey,
              marginTop: 20,
              padding: 10,
              background: "#B4B4B4",
              boxShadow:
                " 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 18,
              overflowY: "hidden",
              overfloxX: "scroll",
            }}
          >
            {playersData.map((e) => (
              <div
                onClick={() => {
                  complainteButton(e.playerId)
                }}
              >
                <Player nextOwnerName={e.name} nextOwnerPdp={e.pdp} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PoliceCard
