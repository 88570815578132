/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from "react"
import { COLORS, PRICE, STYLES } from "../../export/const"
import anime from "animejs/lib/anime.es.js"
import house from "../../assets/cards/smallHouse.png"
import { ReactComponent as Money } from "../../assets/svg/money.svg"
import { ReactComponent as House } from "../../assets/svg/house.svg"

import { ReactComponent as Hotel } from "../../assets/svg/hotel.svg"

import Button from "../button"
import HouseCost from "../house/houseCost"
import HouseRent from "../house/houseRent"
import Player from "../player"

function SmallHouseCard({ data, setMapCenter, setZoom, setHouseId, setHouse }) {
  const houses = () => {
    const comp = []
    if (data.level === 4) {
      return [<Hotel width={30} />]
    }
    for (let i = 0; i <= data.level; i++) {
      comp.push(<House width={25} key={`J${i}`} fill={COLORS.black} />)
    }
    return comp
  }
  const ref = useRef()
  const styles = {
    container: {
      display: "flex",
      boxShadow: "3px 4px 4px rgba(0, 0, 0, 0.25)",
      backgroundColor: COLORS.white,
      flexDirection: "row",
      borderRadius: 50,
      padding: 20,
      alignItems: "center",
      cursor: "pointer",
      margin: 10,
    },
  }
  return (
    <div
      style={styles.container}
      onClick={() => {
        setHouseId(data.houseId)
        setHouse(true)
        setMapCenter([data.coo[1], data.coo[0]])
        setZoom(18)
      }}
    >
      <img src={house} alt="house" style={{ width: 125, zIndex: 10 }} />
      <div
        ref={ref}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignContent: "flex-start",
          justifyContent: "flex-start",
          paddingLeft: 10,
          paddingRight: 20,
          width: 175,
        }}
      >
        <div style={{ ...STYLES.title, textAlign: "flex-start" }}>
          {data.address}
        </div>
        <div style={{ ...STYLES.desc, paddingBottom: 0 }}>
          {data.level !== 4 ? `${data.level + 1} maison(s)` : "Hôtel"}
        </div>
        {houses().map((e) => e)}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            fontFamily: "SFProBold",
            color: COLORS.black,
          }}
        >
          {PRICE(data.levelRentPrice[data.level])}
          <Money width={25} fill={COLORS.black} style={{ marginLeft: 2.5 }} />
        </div>
      </div>
    </div>
  )
}

export default SmallHouseCard
