/* eslint-disable import/no-cycle */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
// eslint-disable-next-line no-unused-vars

import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react"

import anime from "animejs/lib/anime.es.js"
import { setListenUser } from "../../export/listener"

import BuyHouse from "./BuyHouse"
import BuyBank from "./BuyBank"
import BuyTax from "./BuyTax"
import BuyPrison from "./BuyPrison"
import BuyPark from "./BuyPark"
import BuyMetro from "./BuyMetro"
import BuyPolice from "./BuyPolice"

const BuyCard = forwardRef((props, ref) => {
  const [displayBuy, setDisplayBuy] = useState(false)

  const [batiment, setBatiment] = useState("")
  const [cost, setCost] = useState("")
  const [owner, setOwner] = useState("")
  const [pdp, setPdp] = useState("")
  const [id, setId] = useState()
  const [bonusPopularite, setBonusPopularite] = useState()
  const [address, setAddress] = useState("")
  const [isDiscount, setIsDiscount] = useState(false)

  const [isHouse, setIsHouse] = useState(false)
  const [isBank, setIsBank] = useState(false)
  const [isTax, setIsTax] = useState(false)
  const [isPrison, setIsPrison] = useState(false)
  const [isPark, setIsPark] = useState(false)
  const [isMetro, setIsMetro] = useState(false)
  const [isPolice, setIsPolice] = useState(false)

  const { setNewMoney, newMoney } = props
  const { setNewReputation, newReputation } = props
  const { setPlayerName } = props
  const { playersData } = props
  const { setTurnPlayed } = props
  const { setZoom } = props
  const { setNewSinglePlayerPos } = props
  const { setPosCam } = props

  const launchBuyCard = (
    type,
    varMoney,
    user,
    photo,
    caseId,
    popularite,
    street,
    discount
  ) => {
    setBatiment(type)
    setCost(varMoney)
    setBonusPopularite(popularite)
    setOwner(user)
    setPdp(photo)
    setId(caseId)
    setAddress(street)
    setDisplayBuy(true)
    setIsDiscount(discount)
  }

  useImperativeHandle(ref, () => ({
    launchBuyCard,
  }))

  useEffect(() => {
    if (!displayBuy) {
      anime({
        targets: "#buyCard",
        translateY: 1100,
        duration: 1000,
      })
    } else {
      anime({
        targets: "#buyCard",
        translateY: 0,
        duration: 1000,
      })
    }
  }, [displayBuy])

  const actionBuyCard = async () => {
    anime({
      targets: "#buyCard",
      translateY: 1000,
      duration: 1000,
    })
    setTimeout(() => {
      setDisplayBuy(false)
    }, 1000)
    if (isHouse || isBank || isTax) {
      setNewMoney(newMoney + cost)
    }
    if (isPark) {
      setNewReputation(newReputation + bonusPopularite)
      await new Promise((resolve) => setTimeout(resolve, 100))
      setListenUser(setNewMoney, setNewReputation, setPlayerName, setTurnPlayed)
    }
    await new Promise((resolve) => setTimeout(resolve, 100))
    setListenUser(setNewMoney, setNewReputation, setPlayerName, setTurnPlayed)
  }

  const style = {
    layer: {
      position: "absolute",
      backgroundColor: "transparent",
      height: "100%",
      width: "100%",
      zIndex: 20,
    },
  }

  useEffect(() => {
    setIsHouse(false)
    setIsBank(false)
    setIsTax(false)
    setIsPrison(false)
    setIsPark(false)
    setIsMetro(false)
    setIsPolice(false)
    switch (batiment) {
      case "house":
        setIsHouse(true)
        break
      case "bank":
        setIsBank(true)
        break
      case "tax":
        setIsTax(true)
        break
      case "prison":
        setIsPrison(true)
        break
      case "park":
        setIsPark(true)
        break
      case "metro":
        setIsMetro(true)
        break
      case "police":
        setIsPolice(true)
        break
      default:
        break
    }
  }, [batiment])

  return (
    <div id="buyCard" style={style.layer}>
      {displayBuy && (
        <div>
          {isHouse && (
            <BuyHouse
              cost={cost}
              owner={owner}
              pdp={pdp}
              address={address}
              isDiscount={isDiscount}
              handleClick={actionBuyCard}
            />
          )}
          {isBank && <BuyBank cost={cost} handleClick={actionBuyCard} />}
          {isTax && <BuyTax cost={cost} handleClick={actionBuyCard} />}
          {isPrison && <BuyPrison handleClick={actionBuyCard} />}
          {isPark && (
            <BuyPark
              bonusPopularite={bonusPopularite}
              handleClick={actionBuyCard}
            />
          )}
          {isMetro && (
            <BuyMetro
              cost={cost}
              id={id}
              handleClick={actionBuyCard}
              setZoom={setZoom}
              setNewSinglePlayerPos={setNewSinglePlayerPos}
              setPosCam={setPosCam}
            />
          )}
          {isPolice && (
            <BuyPolice playersData={playersData} handleClick={actionBuyCard} />
          )}
        </div>
      )}
    </div>
  )
})

export default BuyCard
