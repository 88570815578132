/* eslint-disable no-useless-return */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-return-await */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import "firebase/compat/functions"
import "firebase/compat/auth"
import { firebaseConfig } from "../export/const"
import "../pages/overlay"

firebase.initializeApp(firebaseConfig)

export const getHouse = async () => {
  const col = await firebase.firestore().collection("const").doc("houses").get()
  return col.data()
}

export const getGlobal = async () => {
  const col = await firebase.firestore().collection("const").doc("global").get()
  return col.data()
}

export const getPlayer = async () => {
  const col = await firebase
    .firestore()
    .collection("players")
    .doc(firebase.auth().currentUser.uid)
    .get()
  return col.data()
}

export const changePdp = async (file) => {
  if (!file || file.type.split("/")[0] !== "image") {
    return { message: "Mauvais format de fichier." }
  }
  const storage = getStorage()
  const storageRef = ref(storage, `${firebase.auth().currentUser.uid}/pdp`)
  return await uploadBytes(storageRef, file)
    .then(
      async (snapshot) =>
        await getDownloadURL(snapshot.ref)
          .then(
            async (downloadURL) =>
              await firebase
                .firestore()
                .collection("const")
                .doc("players")
                .update({
                  [`${firebase.auth().currentUser.uid}.pdp`]: downloadURL,
                })
                .then(() => true)
                .catch((e) => e)
          )
          .catch((e) => e)
    )
    .catch((e) => e)
}

export const getPlayers = async () => {
  const allPlayers = await firebase
    .firestore()
    .collection("const")
    .doc("players")
    .get()
  const players = []
  for (const id of Object.keys(allPlayers.data())) {
    players.push({
      ...allPlayers.data()[id],
      playerId: id,
    })
  }

  return players
}

export const resetHouses = async () => {
  const instance = firebase.functions().httpsCallable("resetHouses")
  try {
    const result = await instance()
    console.log(result.data)
    return result.data
  } catch (e) {
    console.error(e)
  }
}

export const auctionHouse = async (house) => {
  const instance = firebase.functions().httpsCallable("auction")
  try {
    const result = await instance({
      house,
    })
    console.log(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return e
  }
}

export const playerTurn = async () => {
  const instance = firebase.functions().httpsCallable("playerTurn")
  try {
    const result = await instance()
    console.log(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return e
  }
}

export const endTurn = async () => {
  const instance = firebase.functions().httpsCallable("endTurnTest")
  try {
    const result = await instance()
    console.log(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return e
  }
}

export const getPlayerByIdLocal = (uid, playersData) => {
  for (const player of playersData) {
    if (player.playerId === uid) {
      return player
    }
  }
  return undefined
}

export const getPlayerById = async (data) => {
  const instance = firebase.functions().httpsCallable("getPlayerById")
  try {
    const result = await instance({ data })
    console.log(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return e
  }
}

export const getOwnerName = async (data) => {
  const instance = firebase.functions().httpsCallable("getOwnerNameById")
  try {
    const result = await instance({ data })
    console.log(result.data)
    return result.data.name
  } catch (e) {
    console.error(e)
    return e
  }
}

export const upgradeHouse = async (house) => {
  const instance = firebase.functions().httpsCallable("upgradeHouse")
  try {
    const result = await instance({ house })
    console.log(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return e
  }
}

export const takeMetro = async () => {
  const instance = firebase.functions().httpsCallable("takeMetro")
  try {
    const result = await instance()
    console.log(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return e
  }
}

export const complainte = async (targetId) => {
  const instance = firebase.functions().httpsCallable("complainte")
  try {
    const result = await instance({ targetId })
    console.log(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return e
  }
}

export const selling = async (house) => {
  const instance = firebase.functions().httpsCallable("selling")
  try {
    const result = await instance({ house })
    console.log(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return e
  }
}

export const subscribe = async (mail, password, nickname) => {
  const instance = firebase.functions().httpsCallable("sub")
  try {
    const result = await instance({ mail, password, nickname })
    console.log(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return e
  }
}
