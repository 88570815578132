/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-promise-executor-return */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from "react"
import { COLORS, PRICE, STYLES } from "../export/const"
import anime from "animejs/lib/anime.es.js"
import { ReactComponent as House } from "../assets/cards/smallHouse.svg"
import { ReactComponent as EnchereIcon } from "../assets/svg/enchere.svg"
import { ReactComponent as Money } from "../assets/svg/money.svg"
import { ReactComponent as StartingFlag } from "../assets/svg/startingFlag.svg"
import Player from "./player"
import Button from "./button"
// eslint-disable-next-line import/no-cycle
import {
  auctionHouse,
  auctionHouse2,
  listenPlayer,
  resetHouses,
} from "../online/user"
import firebase from "firebase/compat/app"
import { toast } from "react-hot-toast"
import { setListenerNextOwner } from "../export/listener"

// eslint-disable-next-line no-unused-vars

function Enchere({ data, playersData, turnPlayed, openCard }) {
  const ref = useRef()
  const [auctionMoney, setAuctionMoney] = useState(data.levelRentPrice[0])
  const [nextOwner, setNextOwner] = useState(data.nextOwnerId)
  const startPrice = data.levelRentPrice[0]
  const [nextOwnerPdp, setNextOwnerPdp] = useState(undefined)
  const [nextOwnerName, setNextOwnerName] = useState("Banque")
  let listener

  const listenDataHouse = async (house) => {
    listener && listener()
    listener = firebase
      .firestore()
      .collection("houses")
      .doc(`${house.houseId}`)
      .onSnapshot((snapshot) => {
        setAuctionMoney(snapshot.data().auctionsPrice)
        setNextOwner(snapshot.data().nextOwnerId)
      })
  }

  function noAuctionBeforeRender() {
    if (auctionMoney === startPrice) {
      return auctionMoney
    }
    return auctionMoney / 1.1
  }

  useEffect(() => {
    // listenDataHouse(data)
    setListenerNextOwner(
      data,
      nextOwner,
      nextOwnerName,
      setNextOwner,
      setNextOwnerName,
      setNextOwnerPdp,
      setAuctionMoney,
      playersData
    )
    // choiceRenderNextOwnerName()
  }, [data])

  const Text = () => {
    if (!turnPlayed) {
      return {
        message: "Vous ne pouvez pas enchérir avant d'avoir lancé les dés.",
        cost: false,
      }
    }
    return data.ownerId === firebase.auth().currentUser.uid
      ? {
          message: "Enchérir",
          cost: false,
        }
      : { message: "Enchérir", cost: false }
  }

  const auctionHouseButton = () => {
    const p = new Promise((resolve, reject) =>
      auctionHouse(data).then((result) => {
        if (result.code === 1) {
          resolve(result)
        } else {
          reject(result)
        }
      })
    )
    toast.promise(
      p,
      {
        loading: "Placement de votre enchère...",
        success: (e) => <b>{e.message}</b>,
        error: (e) => <b>{e.message}</b>,
      },
      {
        style: {
          backgroundColor: COLORS.white,
          color: COLORS.black,
          fontFamily: "SFProBold",
          height: 35,
          marginTop: -7,
          fontSize: 20,
          minWidth: 200,
          padding: 20,
          whiteSpace: "nowrap",
        },
      }
    )
  }

  return (
    <div
      ref={ref}
      style={{
        ...STYLES.scndCard,
        width: "auto",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontFamily: "SFProBold",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            color: COLORS.green,
          }}
        >
          <EnchereIcon fill={COLORS.green} style={{ paddingRight: 5 }} />
          Dernière mise
        </div>
        <div
          style={{
            paddingLeft: 5,
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            color: COLORS.green,
          }}
        >
          {PRICE(noAuctionBeforeRender())}
          <Money
            fill={COLORS.green}
            width={25}
            style={{ paddingRight: 5, paddingLeft: 5 }}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          paddingTop: 5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontFamily: "SFProBold",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StartingFlag style={{ paddingRight: 5 }} />
          Mise de départ
        </div>
        <div
          style={{
            paddingLeft: 5,
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {PRICE(startPrice)}
          <Money
            fill={COLORS.black}
            width={25}
            style={{ paddingRight: 5, paddingLeft: 5 }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: 5,
          paddingTop: 10,
        }}
      >
        {data.ownerId === firebase.auth().currentUser.uid ? (
          <></>
        ) : (
          <Button
            color={COLORS.black}
            onClick={() => {
              auctionHouseButton()
            }}
            disabled={data.ownerId === firebase.auth().currentUser.uid}
            text={Text().message}
            cost={
              data.ownerId === firebase.auth().currentUser.uid
                ? ""
                : PRICE(auctionMoney)
            }
          />
        )}
        <Player
          data={data}
          playersData={playersData}
          nextOwner={nextOwner}
          nextOwnerName={nextOwnerName}
          setNextOwnerName={setNextOwnerName}
          nextOwnerPdp={nextOwnerPdp}
          openCard={openCard}
        />
      </div>
    </div>
  )
}

export default Enchere
