/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from "react"
import { COLORS, PRICE, STYLES } from "../../export/const"
import house from "../../assets/cards/smallHouse.png"
import { ReactComponent as MoneyBag } from "../../assets/svg/sack-dollar.svg"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"

import HouseCost from "../house/houseCost"
import HouseRent from "../house/houseRent"
import Enchere from "../enchere"
import HouseManager from "../house/houseManager"
import { getPlayerById } from "../../online/user"
import { ReactComponent as EnchereIcon } from "../../assets/svg/enchere.svg"

function HouseCard({
  houseName,
  data,
  playersData,
  turnPlayed,
  newReputation,
  openCard,
}) {
  const ref = useRef()
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 5,
    },
  }
  const [ownerName, setOwnerName] = useState("?")

  const [houseUp, setHouseUp] = useState(data.level !== data.nextLevel)
  const [houseSell, setHouseSell] = useState(data.state === "nextTurnSelling")

  const getObjectPlayer = (id) => {
    for (let i = 0; i < playersData.length; i++) {
      if (playersData[i].playerId === id) {
        return playersData[i]
      }
    }
    return null
  }

  const getOwner = async () => {
    const owner = data.ownerId
    const player = getObjectPlayer(owner)
    if (player === null) {
      return setOwnerName("Maison en vente")
    }
    return setOwnerName(player.name)
  }

  useEffect(() => {
    getOwner()
  }, [data])

  const information = () => {
    const owner = data.ownerId
    const { uid } = firebase.auth().currentUser
    if (owner === uid) {
      if (data.state === "selling") {
        return (
          <Enchere
            data={data}
            playersData={playersData}
            turnPlayed={turnPlayed}
            openCard={openCard}
          />
        )
      }
      return (
        <HouseManager
          data={data}
          newReputation={newReputation}
          setHouseSell={setHouseSell}
        />
      )
    }
    if (data.state === "selling" || data.state === "neutral") {
      if (turnPlayed) {
        return (
          <Enchere
            data={data}
            playersData={playersData}
            turnPlayed={turnPlayed}
            openCard={openCard}
          />
        )
      }
      return (
        <div
          ref={ref}
          style={{
            ...STYLES.scndCard,
            width: 200,
            transform: "translate(0, 180px)",
          }}
        >
          Veuillez lancer les dés avant d'enchérir sur des maisons.
        </div>
      )
    }

    return <></>
  }

  return (
    <div
      style={{
        ...styles.container,
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <div style={styles.container}>
        <img
          src={house}
          alt="house"
          style={{ zIndex: 10, transform: "translate(0, 20px)", width: 200 }}
        />
        <div ref={ref} style={STYLES.card}>
          <div
            style={{
              ...STYLES.title,
              color: data.ownerId === "" ? COLORS.yellow : COLORS.black,
            }}
          >
            {data.name || houseName || ownerName || "Maison en vente"}
            {data.ownerId === "" && (
              <MoneyBag
                width={20}
                fill={COLORS.yellow}
                style={{ paddingLeft: 5 }}
              />
            )}
          </div>
          <div style={{ ...STYLES.desc, paddingBottom: 0 }}>{data.address}</div>
          <HouseRent
            houseCost={[
              PRICE(data.levelRentPrice[0]),
              PRICE(data.levelRentPrice[1]),
              PRICE(data.levelRentPrice[2]),
              PRICE(data.levelRentPrice[3]),
            ]}
            houseUp={houseUp}
            hotelCost={PRICE(data.levelRentPrice[4])}
            level={data.level}
            selling={houseSell}
          />
          <HouseCost
            houseCost={PRICE(data.upgradePrice.house)}
            hotelCost={PRICE(data.upgradePrice.hostel)}
          />
        </div>
      </div>
      {information()}
    </div>
  )
}

export default HouseCard
