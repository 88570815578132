/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { TextField } from "@mui/material"
import firebase from "firebase/compat/app"
import { toast } from "react-hot-toast"
import { COLORS } from "../export/const"
import { ReactComponent as Mail } from "../assets/svg/mailbox.svg"
import { ReactComponent as Eye } from "../assets/svg/eye.svg"
import { ReactComponent as EyeSlash } from "../assets/svg/eye-slash.svg"

import Button from "../comps/button"
import { login, sub1 } from "../online/auth"
import "firebase/compat/auth"

function Login() {
  const styles = {
    container: {
      backgroundColor: COLORS.black,
      width: "100vw",
      height: "100vh",
      overflow: "no-scroll",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: COLORS.black,
      alignContent: "center",
      textAlign: "center",
    },
    form: {
      overflow: "hidden",
      backgroundColor: COLORS.white,
      borderRadius: 20,
      zIndex: 20,
      fontSize: 20,
      fontFamily: "SFProBold",
      width: 250,
      padding: 20,
    },
  }

  const [subscribe, setSubscribe] = useState(false)

  const [hover, setHover] = useState(false)

  const [mail, setMail] = useState("")
  const [mailError, setMailE] = useState("")
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordE] = useState("")
  const [password2, setPassword2] = useState("")
  const [passwordError2, setPasswordE2] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [nickname, setNickname] = useState("")
  const [nicknameError, setNicknameE] = useState("")

  const navigate = useNavigate()

  const subDemand = async () => {
    if (mail === "") {
      setMailE("Mail manquant.")
      return
    }
    if (nickname === "") {
      setNicknameE("Pseudonyme manquant.")
      return
    }
    if (password === "") {
      setPasswordE("Mot de passe manquant.")
      return
    }
    if (password2 !== password) {
      setPasswordE2("Les mots de passe sont différents.")
      return
    }
    const p = new Promise((resolve, reject) => {
      sub1(mail, password, nickname).then((result) => {
        if (result.code === 1) {
          setTimeout(() => {}, 5000)
          navigate("/")
          resolve(result.message)
        } else {
          reject(result)
        }
      })
    })
    toast.promise(
      p,
      {
        loading: "Chargement...",
        success: () => <b>Inscription réussie.</b>,
        error: (data) => <b>{data.message}</b>,
      },
      {
        style: {
          backgroundColor: COLORS.white,
          color: COLORS.black,
          fontFamily: "SFProBold",
          height: 35,
          marginTop: -7,
          fontSize: 20,
          minWidth: 200,
          padding: 20,
          whiteSpace: "nowrap",
          boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
        },
      }
    )
  }

  const signinDemand = async () => {
    if (mail === "") {
      setMailE("Mail manquant.")
      return
    }
    if (password === "") {
      setPasswordE("Mot de passe manquant.")
      return
    }
    const p = new Promise((resolve, reject) => {
      login(mail, password).then((result) => {
        if (result === true) {
          resolve(result)
        } else {
          reject(result)
        }
      })
    })

    toast.promise(
      p,
      {
        loading: "Chargement...",
        success: () => <b>Connected to your account.</b>,
        error: (data) => <b>{data.message}</b>,
      },
      {
        style: {
          backgroundColor: COLORS.white,
          color: COLORS.black,
          fontFamily: "SFProBold",
          height: 35,
          marginTop: -7,
          fontSize: 20,
          minWidth: 200,
          padding: 20,
          whiteSpace: "nowrap",
          boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
        },
      }
    )
  }

  let repeat = false
  const enter = (e) => {
    if (e.key === "Enter" && !repeat) {
      repeat = true
      subscribe ? subDemand() : signinDemand()
      setTimeout(() => {
        repeat = false
      }, 100)
    }
  }
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (authState) => {
      if (authState !== null) {
        navigate("/")
      } else {
        navigate("/login")
      }
    })
  }, [])
  return (
    <div style={styles.container}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          flexDirection: "row",
          color: COLORS.white,
          fontFamily: "SFProBold",
          margin: 10,
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <div
          style={{
            borderRadius: 5,
            fontFamily: "SFProBold",
            background:
              "linear-gradient(to bottom right, #93F5EC 20%, #A77BF3 84%)",
            color: COLORS.black,
            padding: 5,
          }}
        >
          BETA
        </div>
        Capitalille est Bêta, les comptes utilisateurs sont supprimés assez
        régulièrement. N'hesitez pas à recréer un compte.
      </div>
      {!subscribe ? (
        <div style={styles.form}>
          Connectez-vous
          <div style={{ fontSize: 15, color: COLORS.grey }}>
            sur Capitalille
          </div>
          <TextField
            style={{ marginTop: 20 }}
            InputProps={{
              endAdornment: <Mail width={30} fill={COLORS.grey} />,
            }}
            value={mail}
            onChange={(e) => {
              setMail(e.target.value)
            }}
            id="outlined-basic"
            label="Mail"
            variant="outlined"
          />
          <TextField
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: !showPassword ? (
                <Eye
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                  style={{ cursor: "pointer" }}
                  width={30}
                  fill={COLORS.grey}
                />
              ) : (
                <EyeSlash
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                  style={{ cursor: "pointer" }}
                  width={32.5}
                  fill={COLORS.grey}
                />
              ),
            }}
            style={{ marginTop: 20, marginBottom: 20 }}
            id="outlined-basic"
            label="Mot de passe"
            variant="outlined"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
          <Button
            text="Connexion"
            color={COLORS.black}
            onClick={() => {
              signinDemand()
            }}
          />
          <div
            style={{
              fontSize: 15,
              fontFamily: "SFProMedium",
              color: COLORS.grey,
              cursor: "pointer",
              paddingTop: 10,
              textDecoration: hover ? "underline" : "none",
            }}
            onMouseEnter={() => {
              setHover(true)
            }}
            onMouseOut={() => {
              setHover(false)
            }}
            onClick={() => {
              setSubscribe(true)
            }}
          >
            ou s'inscrire
          </div>
        </div>
      ) : (
        <div style={styles.form}>
          Inscrivez-vous
          <div style={{ fontSize: 15, color: COLORS.grey }}>
            sur Capitalille
          </div>
          <TextField
            style={{ marginTop: 20, width: "100%" }}
            id="outlined-basic"
            label="Mail"
            error={mailError !== ""}
            value={mail}
            onChange={(e) => {
              setMail(e.target.value)
            }}
            variant="outlined"
          />
          <TextField
            style={{ marginTop: 20, width: "100%" }}
            id="outlined-basic"
            label="Pseudonyme"
            error={nicknameError !== ""}
            variant="outlined"
            value={nickname}
            onChange={(e) => {
              setNickname(e.target.value)
            }}
          />
          <TextField
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: !showPassword ? (
                <Eye
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                  style={{ cursor: "pointer" }}
                  width={30}
                  fill={COLORS.grey}
                />
              ) : (
                <EyeSlash
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                  style={{ cursor: "pointer" }}
                  width={32.5}
                  fill={COLORS.grey}
                />
              ),
            }}
            style={{ marginTop: 20, width: "100%" }}
            id="outlined-basic"
            error={passwordError !== ""}
            label="Mot de passe"
            variant="outlined"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
          <TextField
            type={showPassword ? "text" : "password"}
            style={{ marginTop: 20, marginBottom: 20, width: "100%" }}
            error={passwordError2 !== ""}
            id="outlined-basic"
            label="Répétez"
            variant="outlined"
            value={password2}
            onChange={(e) => {
              setPassword2(e.target.value)
            }}
          />
          <Button
            text="S'inscrire"
            color={COLORS.black}
            onClick={() => {
              subDemand()
            }}
          />
          <div
            style={{
              fontSize: 15,
              fontFamily: "SFProMedium",
              color: COLORS.grey,
              cursor: "pointer",
              paddingTop: 10,
              textDecoration: hover ? "underline" : "none",
            }}
            onMouseEnter={() => {
              setHover(true)
            }}
            onMouseOut={() => {
              setHover(false)
            }}
            onClick={() => {
              setSubscribe(false)
            }}
          >
            retour connexion
          </div>
        </div>
      )}
    </div>
  )
}

export default Login
