/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from "react"
import { COLORS, STYLES } from "../../export/const"
import anime from "animejs/lib/anime.es.js"
import { ReactComponent as Bank } from "../../assets/cards/bank.svg"
import Button from "../button"
import HouseCost from "../house/houseCost"

// eslint-disable-next-line no-unused-vars

function BankCard({ left, visible, children }) {
  const ref = useRef()

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
    },
  }

  return (
    <div style={styles.container}>
      <Bank style={{ transform: "translate(0, 20px)" }} />
      <div ref={ref} style={STYLES.card}>
        <div style={STYLES.title}>· Banque de France ·</div>
        <div style={STYLES.desc}>34 Boulevard Vauban</div>
        <div style={STYLES.text}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse.
        </div>
        <HouseCost houseCost="75k" hotelCost="500k" />
        <Button
          color={COLORS.grey}
          text="Vous avez payé "
          cost="1.3M"
          disabled
        />
      </div>
    </div>
  )
}

export default BankCard
