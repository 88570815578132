/* eslint-disable no-promise-executor-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React, { forwardRef } from "react"
import { COLORS, STYLES } from "../../export/const"

import { ReactComponent as DiceLogo } from "../../assets/svg/dice.svg"
import { ReactComponent as Luck } from "../../assets/svg/luck.svg"
import { ReactComponent as Coin } from "../../assets/svg/coin.svg"
import { ReactComponent as Reputation } from "../../assets/svg/reputation.svg"
import { ReactComponent as Dollar } from "../../assets/svg/sack-dollar.svg"
import { ReactComponent as Plainte } from "../../assets/svg/plaintes.svg"
import { ReactComponent as Trophy } from "../../assets/svg/trophy.svg"

import { ReactComponent as Run } from "../../assets/svg/person-running.svg"
import { ReactComponent as Home } from "../../assets/svg/house-crack.svg"
import { ReactComponent as Discount } from "../../assets/svg/badge-percent.svg"
import { ReactComponent as Shield } from "../../assets/svg/shield.svg"
import { ReactComponent as Sunglasses } from "../../assets/svg/sunglasses.svg"

const IconComp = forwardRef((props, ref) => {
  const styles = {
    icon: {
      height: "180px",
      width: "76%",
      position: "absolute",
      alignItems: "center",
      alignSelf: "center",
      margin: "auto",
      padding: "20px",
      color: COLORS.green,
      marginTop: 20,
    },
  }

  switch (props.icon) {
    case "MONEY_BONUS":
      return <Coin style={styles.icon} fill={COLORS.yellow} />
    case "MONEY_MALUS":
      return <Coin style={styles.icon} fill={COLORS.yellow} />

    case "POPULARITY_BONUS":
      return <Sunglasses style={styles.icon} fill={COLORS.blue} />
    case "POPULARITY_MALUS":
      return <Sunglasses style={styles.icon} fill={COLORS.blue} />

    case "DICE_BONUS":
      return <DiceLogo style={styles.icon} fill={COLORS.blueLuck} />
    case "DICE_MALUS":
      return <DiceLogo style={styles.icon} fill={COLORS.blueLuck} />

    case "RENT_DISCOUNT":
      return <Discount style={styles.icon} fill={COLORS.greenLow} />

    case "IMMUNITY":
      return <Shield style={styles.icon} fill={COLORS.orange} />

    case "MOVE_BONUS":
      return <Run style={styles.icon} fill={COLORS.purple} />
    case "MOVE_MALUS":
      return <Run style={styles.icon} fill={COLORS.purple} />
    case "HOME_LOSS":
      return <Home style={styles.icon} fill={COLORS.red} />

    default:
      return <Luck style={styles.icon} fill={COLORS.green} />
  }
})

export default IconComp
