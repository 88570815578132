/* eslint-disable no-promise-executor-return */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */

import firebase from "firebase/compat/app"
import React, { useEffect, useRef, useState } from "react"
import { COLORS, METRO, PRICE, STYLES } from "../../export/const"
import { ReactComponent as MetroCardIcon } from "../../assets/cards/metro.svg"
import { ReactComponent as MetroIcon } from "../../assets/svg/metro.svg"
import geoCases from "../../assets/geoJson/cases"

import Button from "../button"
import { toast } from "react-hot-toast"
// eslint-disable-next-line import/no-cycle
import { takeMetro } from "../../online/user"
import { backdropClasses } from "@mui/material"
// eslint-disable-next-line no-unused-vars

const BuyMetro = (props) => {
  const {
    cost,
    id,
    playerMoney,
    setZoom,
    setPosCam,
    setNewSinglePlayerPos,
    handleClick,
  } = props
  const ref = useRef()

  const [idMetro, setIdMetro] = useState()

  useEffect(() => {
    setIdMetro(id)
  }, [idMetro])

  const take = () => {
    const p = new Promise((resolve, reject) =>
      takeMetro().then((result) => {
        if (result.code === 1) {
          const iCase = geoCases.cases[result.newPos].coordinate
          setZoom(18)
          setPosCam(iCase)
          setNewSinglePlayerPos(result.newPos, firebase.auth().currentUser.uid)
          handleClick()
          resolve(result)
        } else {
          reject(result)
          handleClick()
        }
      })
    )
    toast.promise(
      p,
      {
        loading: "Nous controlons votre ticket...",
        success: (data) => <b>{data.message}</b>,
        error: (data) => <b>{data.message}</b>,
      },
      {
        style: {
          backgroundColor: COLORS.white,
          color: COLORS.black,
          fontFamily: "SFProBold",
          height: 35,
          marginTop: -7,
          fontSize: 20,
          minWidth: 200,
          padding: 20,
          whiteSpace: "nowrap",
        },
      }
    )
  }

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      zIndex: 30,
      top: "45%",
      left: "50%",
      justifyContent: "center",
      color: COLORS.black,
    },
  }

  return (
    <div style={styles.container}>
      <MetroCardIcon style={{ zIndex: 10, transform: "translate(0, 15px)" }} />
      <div ref={ref} style={{ ...STYLES.card, height: 430 }}>
        <div style={STYLES.title}>· Métro {METRO[id].name} ·</div>
        <div style={STYLES.desc}>Case {id}</div>
        <div style={STYLES.text}>
          Vous pouvez prendre le métro pour vous déplacez une fois que vous avez
          lancé les dés. Cela vous téléportera directement à la case d'arrivée.
          Une bonne manière d'éviter des rues où les loyers sont chers.
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            fontFamily: "SFProBold",
            color: COLORS.grey,
            paddingTop: 20,
          }}
        >
          <div>{METRO[id].name || "?"}</div>
          <div style={{ color: COLORS.green }}>
            {METRO[METRO[id].next].name || "?"}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            paddingBottom: 0,
          }}
        >
          <div
            style={{
              borderRadius: 20,
              padding: 12.5,
              margin: 2.5,
              display: "flex",
              alignItems: "center",
              marginLeft: 0,
              justifyContent: "center",
              alignContent: "center",
              backgroundColor: COLORS.grey,
            }}
          >
            <MetroIcon fill={COLORS.white} width={30} height={30} />
          </div>
          <div
            style={{
              border: "3px dashed",
              borderImage: "linear-gradient(to right, #909090, #0CCE6B) 1",
              width: "100%",
              height: 0,
            }}
          />
          <div
            style={{
              borderRadius: 20,
              padding: 12.5,
              margin: 2.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              marginRight: 0,
              backgroundColor: COLORS.green,
            }}
          >
            <MetroIcon fill={COLORS.white} width={30} height={30} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            fontFamily: "SFProBold",
            color: COLORS.grey,
            paddingBottom: 5,
          }}
        >
          <div>Départ</div>
          <div style={{ color: COLORS.green }}>Arrivée</div>
        </div>
        <div>
          <div style={{ position: "relative", top: "5px" }}>
            <div style={{ marginBottom: "10px" }}>
              <Button
                onClick={() => {
                  take()
                }}
                disabled={playerMoney < METRO[id].price}
                color={COLORS.black}
                text="Prendre le métro"
                cost={PRICE(METRO[id].price)}
              />
            </div>
            <div>
              <Button
                onClick={() => {
                  handleClick()
                }}
                color={COLORS.black}
                text="Ne pas prendre le métro"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyMetro
