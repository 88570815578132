/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from "react"
import { COLORS, STYLES } from "../../export/const"
import anime from "animejs/lib/anime.es.js"
import { ReactComponent as Prison } from "../../assets/cards/prison.svg"
import Button from "../button"
import HouseCost from "../house/houseCost"

// eslint-disable-next-line no-unused-vars

function PrisonCard({ left, visible, children }) {
  const ref = useRef()

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }

  return (
    <div style={styles.container}>
      <Prison style={{ transform: "translate(0, 20px)" }} />
      <div ref={ref} style={STYLES.card}>
        <div style={STYLES.title}>· Prison ·</div>
        <div style={STYLES.desc}>45 Boulevard Vauban</div>
        <div style={STYLES.text}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse.
        </div>
        <Button color={COLORS.black} text="Sortir de prison" cost="100k" />
        <div style={{ ...STYLES.text, paddingTop: 10 }}>
          ou attendre le prochain tour.
        </div>
      </div>
    </div>
  )
}

export default PrisonCard
