export default {
  cases: [
    {
      coordinate: [50.626208655845836, 3.0402708135661385],
      house: -1,
      id: 0,
      type: "start",
      previous: [118, 145],
      next: [1, 119],
    },
    {
      coordinate: [50.627057104024246, 3.041154448145903],
      house: 0,
      id: 1,
      type: "house",
      previous: [0],
      next: [2],
    },
    {
      coordinate: [50.6284591198982, 3.042635027522124],
      house: 1,
      id: 2,
      type: "house",
      previous: [1],
      next: [3],
    },
    {
      coordinate: [50.62911247223788, 3.0433216730299364],
      house: -1,
      id: 3,
      type: "intersection",
      previous: [2],
      next: [4, 146],
    },
    {
      coordinate: [50.62984748276728, 3.044029776209868],
      house: 3,
      id: 4,
      type: "house",
      previous: [3],
      next: [5],
    },
    {
      coordinate: [50.63055525982396, 3.0447378793897997],
      house: -1,
      id: 5,
      type: "intersection",
      previous: [4, 139],
      next: [6],
    },
    {
      coordinate: [50.631249415433246, 3.0454674402418505],
      house: 4,
      id: 6,
      type: "house",
      previous: [5],
      next: [7],
    },
    {
      coordinate: [50.63262408550911, 3.0468407312574755],
      house: -1,
      id: 7,
      type: "luck",
      previous: [6],
      next: [8],
    },
    {
      coordinate: [50.63348153262498, 3.0477419534864794],
      house: 6,
      id: 8,
      type: "house",
      previous: [7],
      next: [9],
    },
    {
      coordinate: [50.63412120520753, 3.0483642259779344],
      house: 7,
      id: 9,
      type: "house",
      previous: [8],
      next: [10],
    },
    {
      coordinate: [50.634420623425484, 3.0487075487318407],
      house: -1,
      id: 10,
      type: "intersection",
      previous: [9, 133],
      next: [11],
    },
    {
      coordinate: [50.634774478860535, 3.0491152445021044],
      house: 8,
      id: 11,
      type: "house",
      previous: [10],
      next: [12],
    },
    {
      coordinate: [50.63564549627495, 3.04997355138687],
      house: -1,
      id: 12,
      type: "luck",
      previous: [11],
      next: [13],
    },
    {
      coordinate: [50.636809095917506, 3.0510816868826964],
      house: 104,
      id: 13,
      type: "park",
      previous: [12],
      next: [14],
    },
    {
      coordinate: [50.63735346012868, 3.0516825017020324],
      house: -1,
      id: 14,
      type: "luck",
      previous: [13],
      next: [15],
    },
    {
      coordinate: [50.63699962410858, 3.052218943505011],
      house: 14,
      id: 15,
      type: "house",
      previous: [14],
      next: [16],
    },
    {
      coordinate: [50.6364960836412, 3.0532059964224914],
      house: -1,
      id: 16,
      type: "luck",
      previous: [15],
      next: [17],
    },
    {
      coordinate: [50.63596531893033, 3.0541715916678527],
      house: 13,
      id: 17,
      type: "house",
      previous: [16],
      next: [18],
    },
    {
      coordinate: [50.63551620564749, 3.054965525536261],
      house: 15,
      id: 18,
      type: "house",
      previous: [17],
      next: [19],
    },
    {
      coordinate: [50.63493099099163, 3.056038409142218],
      house: 12,
      id: 19,
      type: "house",
      previous: [18],
      next: [20],
    },
    {
      coordinate: [50.634549917066266, 3.0567250546500304],
      house: -1,
      id: 20,
      type: "intersection",
      previous: [19, 153],
      next: [22],
    },
    {
      coordinate: [50.64240141538315, 3.0561786037081333],
      house: 36,
      id: 21,
      type: "house",
      previous: [211],
      next: [55],
    },
    {
      coordinate: [50.63477447886022, 3.057256132035036],
      house: 16,
      id: 22,
      type: "house",
      previous: [20],
      next: [23],
    },
    {
      coordinate: [50.63507389291697, 3.057958870796938],
      house: 106,
      id: 23,
      type: "park",
      previous: [22],
      next: [24],
    },
    {
      coordinate: [50.63545490492672, 3.058888097106518],
      house: 17,
      id: 24,
      type: "house",
      previous: [23],
      next: [25],
    },
    {
      coordinate: [50.63630631828394, 3.0608747002670498],
      house: 18,
      id: 25,
      type: "house",
      previous: [24],
      next: [26],
    },
    {
      coordinate: [50.63695777392707, 3.062411833338416],
      house: 40,
      id: 26,
      type: "house",
      previous: [25],
      next: [27],
    },
    {
      coordinate: [50.63727758765367, 3.063270140223466],
      house: -1,
      id: 27,
      type: "luck",
      previous: [26],
      next: [28],
    },
    {
      coordinate: [50.63681487772916, 3.063838768534623],
      house: 108,
      id: 28,
      type: "house",
      previous: [27],
      next: [29],
    },
    {
      coordinate: [50.637134692427864, 3.0652227883863077],
      house: -1,
      id: 29,
      type: "intersection",
      previous: [28],
      next: [30, 154],
    },
    {
      coordinate: [50.636981589918015, 3.066461968951387],
      house: 19,
      id: 30,
      type: "house",
      previous: [29],
      next: [31],
    },
    {
      coordinate: [50.63677064786543, 3.0681678538848587],
      house: -1,
      id: 31,
      type: "luck",
      previous: [30],
      next: [32],
    },
    {
      coordinate: [50.636651567256415, 3.0690905337859817],
      house: 20,
      id: 32,
      type: "house",
      previous: [31],
      next: [33],
    },
    {
      coordinate: [50.636831889204046, 3.0696484332610794],
      house: 20,
      id: 33,
      type: "house",
      previous: [32],
      next: [34],
    },
    {
      coordinate: [50.63727418539138, 3.070753503375215],
      house: -1,
      id: 34,
      type: "metro",
      previous: [33],
      next: [35],
    },
    {
      coordinate: [50.63754296334897, 3.071300674014253],
      house: -1,
      id: 35,
      type: "luck",
      previous: [34],
      next: [36],
    },
    {
      coordinate: [50.638019796565246, 3.0708764125026846],
      house: 21,
      id: 36,
      type: "house",
      previous: [35],
      next: [37],
    },
    {
      coordinate: [50.638771678603625, 3.0702863265194082],
      house: -1,
      id: 37,
      type: "luck",
      previous: [36],
      next: [38],
    },
    {
      coordinate: [50.639057458667004, 3.070066385380187],
      house: -1,
      id: 38,
      type: "intersection",
      previous: [37, 158],
      next: [39],
    },
    {
      coordinate: [50.63946576080293, 3.0697320536931443],
      house: 22,
      id: 39,
      type: "house",
      previous: [38],
      next: [40],
    },
    {
      coordinate: [50.640459164587455, 3.068911297734587],
      house: 23,
      id: 40,
      type: "house",
      previous: [39],
      next: [41],
    },
    {
      coordinate: [50.64096266259651, 3.068165643628447],
      house: -1,
      id: 41,
      type: "luck",
      previous: [40],
      next: [42],
    },
    {
      coordinate: [50.641626047007556, 3.0680090026219773],
      house: 24,
      id: 42,
      type: "house",
      previous: [41],
      next: [43],
    },
    {
      coordinate: [50.64239147892238, 3.067821247990935],
      house: 25,
      id: 43,
      type: "house",
      previous: [42],
      next: [44],
    },
    {
      coordinate: [50.642956189587295, 3.0674779252370286],
      house: 24,
      id: 44,
      type: "house",
      previous: [43],
      next: [45],
    },
    {
      coordinate: [50.64301061917232, 3.0670863227208542],
      house: -1,
      id: 45,
      type: "tax",
      previous: [44],
      next: [46],
    },
    {
      coordinate: [50.64332424811828, 3.0659355349545194],
      house: 26,
      id: 46,
      type: "house",
      previous: [45],
      next: [47],
    },
    {
      coordinate: [50.644147482409366, 3.0642618365292265],
      house: 27,
      id: 47,
      type: "house",
      previous: [46],
      next: [48],
    },
    {
      coordinate: [50.64436179310259, 3.0631835885052396],
      house: 27,
      id: 48,
      type: "house",
      previous: [47],
      next: [49],
    },
    {
      coordinate: [50.643781672599175, 3.0618291272921],
      house: -1,
      id: 49,
      type: "intersection",
      previous: [48],
      next: [50, 201],
    },
    {
      coordinate: [50.64349774084189, 3.0611772961621],
      house: 28,
      id: 50,
      type: "house",
      previous: [49],
      next: [51],
    },
    {
      coordinate: [50.64309292349038, 3.06017414999053],
      house: -1,
      id: 51,
      type: "luck",
      previous: [50],
      next: [52],
    },
    {
      coordinate: [50.64280376610413, 3.059283656597586],
      house: 29,
      id: 52,
      type: "house",
      previous: [51],
      next: [53],
    },
    {
      coordinate: [50.642528214468776, 3.058360976696264],
      house: -1,
      id: 53,
      type: "luck",
      previous: [52],
      next: [54],
    },
    {
      coordinate: [50.6421914269422, 3.05720762681986],
      house: 30,
      id: 54,
      type: "house",
      previous: [53],
      next: [55],
    },
    {
      coordinate: [50.641973704428345, 3.056488794803869],
      house: -1,
      id: 55,
      type: "intersection",
      previous: [54, 21],
      next: [56],
    },
    {
      coordinate: [50.641055176468626, 3.057153982639562],
      house: 37,
      id: 56,
      type: "house",
      previous: [55],
      next: [57],
    },
    {
      coordinate: [50.639925179267664, 3.0579772144833726],
      house: -1,
      id: 57,
      type: "luck",
      previous: [56],
      next: [58],
    },
    {
      coordinate: [50.63937744273265, 3.0584010035077256],
      house: 38,
      id: 58,
      type: "house",
      previous: [57],
      next: [59],
    },
    {
      coordinate: [50.63879908045616, 3.059087649015538],
      house: -1,
      id: 59,
      type: "luck",
      previous: [58],
      next: [60],
    },
    {
      coordinate: [50.63840783135088, 3.0603321939984482],
      house: 39,
      id: 60,
      type: "house",
      previous: [59],
      next: [61],
    },
    {
      coordinate: [50.63795193698042, 3.061582103399388],
      house: -1,
      id: 61,
      type: "luck",
      previous: [60],
      next: [62],
    },
    {
      coordinate: [50.637485831447776, 3.062510147718541],
      house: -1,
      id: 62,
      type: "police",
      previous: [61],
      next: [63],
    },
    {
      coordinate: [50.636939635104056, 3.0630059857933816],
      house: -1,
      id: 63,
      type: "luck",
      previous: [62],
      next: [64],
    },
    {
      coordinate: [50.6366892794363, 3.0632243795623992],
      house: 40,
      id: 64,
      type: "house",
      previous: [63],
      next: [65],
    },
    {
      coordinate: [50.63600208606752, 3.0638331237676653],
      house: 41,
      id: 65,
      type: "house",
      previous: [64],
      next: [66],
    },
    {
      coordinate: [50.636131375358374, 3.0648738208654436],
      house: 42,
      id: 66,
      type: "house",
      previous: [65],
      next: [67],
    },
    {
      coordinate: [50.63631510163361, 3.065732127750209],
      house: -1,
      id: 67,
      type: "luck",
      previous: [66],
      next: [68],
    },
    {
      coordinate: [50.63599868371294, 3.0662256542089494],
      house: 43,
      id: 68,
      type: "house",
      previous: [67],
      next: [69],
    },
    {
      coordinate: [50.635559777909386, 3.066740638339809],
      house: -1,
      id: 69,
      type: "luck",
      previous: [68],
      next: [70],
    },
    {
      coordinate: [50.63485207619904, 3.0674487415197405],
      house: 44,
      id: 70,
      type: "house",
      previous: [69],
      next: [214],
    },
    {
      coordinate: [50.63404569155164, 3.067035681331447],
      house: 45,
      id: 71,
      type: "house",
      previous: [214],
      next: [72],
    },
    {
      coordinate: [50.63356253462917, 3.066037899577907],
      house: 46,
      id: 72,
      type: "house",
      previous: [71],
      next: [73],
    },
    {
      coordinate: [50.633235890515905, 3.0652010503652605],
      house: 46,
      id: 73,
      type: "house",
      previous: [72],
      next: [74],
    },
    {
      coordinate: [50.63284799768336, 3.0642569127920183],
      house: -1,
      id: 74,
      type: "luck",
      previous: [73],
      next: [75],
    },
    {
      coordinate: [50.632432880755594, 3.0631733003500017],
      house: 47,
      id: 75,
      type: "house",
      previous: [74],
      next: [76],
    },
    {
      coordinate: [50.631922486066095, 3.0622077051046688],
      house: -1,
      id: 76,
      type: "luck",
      previous: [75],
      next: [240],
    },
    {
      coordinate: [50.63200074694487, 3.061408406818231],
      house: -1,
      id: 77,
      type: "metro",
      previous: [76],
      next: [78],
    },
    {
      coordinate: [50.63189186220851, 3.0602979722860653],
      house: 107,
      id: 78,
      type: "park",
      previous: [77],
      next: [79],
    },
    {
      coordinate: [50.631330925386386, 3.059296018207391],
      house: 48,
      id: 79,
      type: "house",
      previous: [78],
      next: [80],
    },
    {
      coordinate: [50.6309736413106, 3.0584269824865657],
      house: 49,
      id: 80,
      type: "house",
      previous: [79],
      next: [81],
    },
    {
      coordinate: [50.63053468858593, 3.0573380056265194],
      house: 48,
      id: 81,
      type: "house",
      previous: [80],
      next: [82],
    },
    {
      coordinate: [50.63024885670142, 3.056645995700677],
      house: -1,
      id: 82,
      type: "intersection",
      previous: [81],
      next: [83, 159],
    },
    {
      coordinate: [50.63000045377137, 3.057101971233209],
      house: 50,
      id: 83,
      type: "house",
      previous: [82],
      next: [84],
    },
    {
      coordinate: [50.629561491960004, 3.057933456027712],
      house: -1,
      id: 84,
      type: "luck",
      previous: [83],
      next: [85],
    },
    {
      coordinate: [50.629214402975, 3.058679110133852],
      house: 51,
      id: 85,
      type: "house",
      previous: [84],
      next: [86],
    },
    {
      coordinate: [50.628458965149946, 3.060047036731447],
      house: 52,
      id: 86,
      type: "house",
      previous: [85],
      next: [87],
    },
    {
      coordinate: [50.62775332950282, 3.0613680408291657],
      house: -1,
      id: 87,
      type: "luck",
      previous: [86],
      next: [88],
    },
    {
      coordinate: [50.62686032323889, 3.0630752980556224],
      house: 53,
      id: 88,
      type: "house",
      previous: [87],
      next: [89],
    },
    {
      coordinate: [50.626104847588785, 3.0644754111613963],
      house: -1,
      id: 89,
      type: "intersection",
      previous: [88, 173],
      next: [90],
    },
    {
      coordinate: [50.62519281576708, 3.0662403046931956],
      house: 55,
      id: 90,
      type: "house",
      previous: [89],
      next: [91],
    },
    {
      coordinate: [50.62433378392245, 3.067867133701185],
      house: -1,
      id: 91,
      type: "intersection",
      previous: [90],
      next: [92, 189],
    },
    {
      coordinate: [50.624098962605935, 3.067266318881849],
      house: 62,
      id: 92,
      type: "house",
      previous: [91],
      next: [93],
    },
    {
      coordinate: [50.62375183329437, 3.0662256217840707],
      house: -1,
      id: 93,
      type: "police",
      previous: [92],
      next: [94],
    },
    {
      coordinate: [50.623357055668926, 3.0650615430716073],
      house: 64,
      id: 94,
      type: "house",
      previous: [93],
      next: [95],
    },
    {
      coordinate: [50.622918031831176, 3.06374189623628],
      house: 65,
      id: 95,
      type: "house",
      previous: [94],
      next: [96],
    },
    {
      coordinate: [50.62262534699559, 3.062872860515455],
      house: -1,
      id: 96,
      type: "luck",
      previous: [95],
      next: [97],
    },
    {
      coordinate: [50.6223258536502, 3.061977002704481],
      house: -1,
      id: 97,
      type: "tax",
      previous: [96],
      next: [98],
    },
    {
      coordinate: [50.62208081222162, 3.06124744185243],
      house: -1,
      id: 98,
      type: "intersection",
      previous: [97, 177],
      next: [99],
    },
    {
      coordinate: [50.62185278641199, 3.060571525180677],
      house: 67,
      id: 99,
      type: "house",
      previous: [98],
      next: [100],
    },
    {
      coordinate: [50.621447782621274, 3.059391353214096],
      house: 68,
      id: 100,
      type: "house",
      previous: [99],
      next: [101],
    },
    {
      coordinate: [50.620852182475105, 3.0576318241003264],
      house: 69,
      id: 101,
      type: "house",
      previous: [100],
      next: [102],
    },
    {
      coordinate: [50.62003875066129, 3.0552339292410124],
      house: -1,
      id: 102,
      type: "luck",
      previous: [101],
      next: [103],
    },
    {
      coordinate: [50.61960309938572, 3.053925011241745],
      house: 70,
      id: 103,
      type: "house",
      previous: [102],
      next: [104],
    },
    {
      coordinate: [50.61933081528918, 3.053098890865158],
      house: 73,
      id: 104,
      type: "house",
      previous: [103],
      next: [105],
    },
    {
      coordinate: [50.61882027835932, 3.051575396144699],
      house: 72,
      id: 105,
      type: "house",
      previous: [104],
      next: [106],
    },
    {
      coordinate: [50.618323350425285, 3.0500411725881804],
      house: -1,
      id: 106,
      type: "intersection",
      previous: [105, 200],
      next: [107, 178],
    },
    {
      coordinate: [50.619044915291234, 3.0491614080312957],
      house: -1,
      id: 107,
      type: "metro",
      previous: [106],
      next: [108],
    },
    {
      coordinate: [50.620120434826184, 3.0478417611959685],
      house: -1,
      id: 108,
      type: "tax",
      previous: [107],
      next: [111],
    },
    {
      coordinate: [50.617619351247725, 3.0611936590293283],
      house: -1,
      id: 109,
      type: "luck",
      previous: [195],
      next: [196],
    },
    {
      coordinate: [50.62180173570626, 3.045771095836187],
      house: -1,
      id: 110,
      type: "intersection",
      previous: [112, 188],
      next: [113],
    },
    {
      coordinate: [50.620841972121234, 3.04695126780274],
      house: -1,
      id: 111,
      type: "metro",
      previous: [108],
      next: [112],
    },
    {
      coordinate: [50.6214069417094, 3.0462324357867487],
      house: -1,
      id: 112,
      type: "luck",
      previous: [111],
      next: [110],
    },
    {
      coordinate: [50.622182912972185, 3.0452453828692683],
      house: 85,
      id: 113,
      type: "house",
      previous: [112],
      next: [114],
    },
    {
      coordinate: [50.623612300202154, 3.043507311427618],
      house: 86,
      id: 114,
      type: "house",
      previous: [113],
      next: [212],
    },
    {
      coordinate: [50.624170430087425, 3.0428206659198054],
      house: 87,
      id: 115,
      type: "house",
      previous: [212],
      next: [116],
    },
    {
      coordinate: [50.624728553348135, 3.042134020411993],
      house: 87,
      id: 116,
      type: "house",
      previous: [115],
      next: [117],
    },
    {
      coordinate: [50.625266251199726, 3.04145810374024],
      house: -1,
      id: 117,
      type: "luck",
      previous: [116],
      next: [118],
    },
    {
      coordinate: [50.62572907480617, 3.0408465600848444],
      house: 0,
      id: 118,
      type: "house",
      previous: [117],
      next: [0],
    },
    {
      coordinate: [50.62662067802997, 3.039757583224798],
      house: 90,
      id: 119,
      type: "house",
      previous: [0],
      next: [120],
    },
    {
      coordinate: [50.627403369214505, 3.0387919879794367],
      house: 91,
      id: 120,
      type: "house",
      previous: [119],
      next: [121],
    },
    {
      coordinate: [50.627947842354075, 3.038116071307684],
      house: 92,
      id: 121,
      type: "house",
      previous: [120],
      next: [122],
    },
    {
      coordinate: [50.6286566319764, 3.0372277753872936],
      id: 122,
      type: "house",
      house: 93,
      previous: [121],
      next: [123],
    },
    {
      coordinate: [50.6296434585939, 3.0360046880765026],
      house: 94,
      id: 123,
      type: "house",
      previous: [122],
      next: [124],
    },
    {
      coordinate: [50.63012665579713, 3.0353824155850475],
      house: -1,
      id: 124,
      type: "metro",
      previous: [123],
      next: [125],
    },
    {
      coordinate: [50.631147478629664, 3.0359081285519665],
      house: 94,
      id: 125,
      type: "house",
      previous: [124],
      next: [126],
    },
    {
      coordinate: [50.63207300550507, 3.0369059103055065],
      house: 95,
      id: 126,
      type: "house",
      previous: [125],
      next: [127],
    },
    {
      coordinate: [50.633236693585125, 3.038161184124476],
      house: 96,
      id: 127,
      type: "house",
      previous: [126],
      next: [128],
    },
    {
      coordinate: [50.634454792353026, 3.0395022886319225],
      house: 97,
      id: 128,
      type: "house",
      previous: [127],
      next: [129],
    },
    {
      coordinate: [50.634985574120826, 3.0401245611233776],
      house: -1,
      id: 129,
      type: "intersection",
      previous: [128],
      next: [130, 134],
    },
    {
      coordinate: [50.635863392352995, 3.041433479122645],
      house: 104,
      id: 130,
      type: "park",
      previous: [129],
      next: [131],
    },
    {
      coordinate: [50.63624445562987, 3.043214465908534],
      house: 102,
      id: 131,
      type: "house",
      previous: [130],
      next: [132],
    },
    {
      coordinate: [50.635924634873696, 3.0458966749234264],
      house: 103,
      id: 132,
      type: "house",
      previous: [131],
      next: [133],
    },
    {
      coordinate: [50.63510806136992, 3.0473987119717663],
      house: -1,
      id: 133,
      type: "luck",
      previous: [132],
      next: [10],
    },
    {
      coordinate: [50.634658247872025, 3.0404951605791553],
      house: 97,
      id: 134,
      type: "house",
      previous: [129],
      next: [135],
    },
    {
      coordinate: [50.634134267387836, 3.0410423312181933],
      house: 98,
      id: 135,
      type: "house",
      previous: [134],
      next: [136],
    },
    {
      coordinate: [50.63343334929617, 3.041696790217827],
      house: 99,
      id: 136,
      type: "house",
      previous: [135],
      next: [137],
    },
    {
      coordinate: [50.63285491387348, 3.042319062709282],
      house: 100,
      id: 137,
      type: "house",
      previous: [136],
      next: [138],
    },
    {
      coordinate: [50.631902181190554, 3.0433168444628222],
      house: -1,
      id: 138,
      type: "luck",
      previous: [137],
      next: [139],
    },
    {
      coordinate: [50.63092220742932, 3.044325355052422],
      house: 101,
      id: 139,
      type: "house",
      previous: [138],
      next: [5],
    },
    {
      coordinate: [50.62345357100689, 3.042414342565536],
      house: 87,
      id: 140,
      type: "house",
      previous: [212],
      next: [141],
    },
    {
      coordinate: [50.622391741059175, 3.039653563235049],
      house: -1,
      id: 141,
      type: "luck",
      previous: [140],
      next: [142],
    },
    {
      coordinate: [50.62270484725443, 3.0379208562114286],
      house: 88,
      id: 142,
      type: "house",
      previous: [141],
      next: [143],
    },
    {
      coordinate: [50.62405410376073, 3.0380656954982044],
      house: 89,
      id: 143,
      type: "house",
      previous: [142],
      next: [144],
    },
    {
      coordinate: [50.62492191665644, 3.038950824473119],
      house: -1,
      id: 144,
      type: "luck",
      previous: [143],
      next: [145],
    },
    {
      coordinate: [50.625622961653534, 3.03966965648911],
      house: -1,
      id: 145,
      type: "police",
      previous: [144],
      next: [0],
    },
    {
      coordinate: [50.62942650801486, 3.0446718953553784],
      house: 2,
      id: 146,
      type: "house",
      previous: [3],
      next: [147],
    },
    {
      coordinate: [50.63025679129965, 3.046603085846101],
      house: 4,
      id: 147,
      type: "house",
      previous: [146],
      next: [148],
    },
    {
      coordinate: [50.63107344907842, 3.04854500517294],
      house: 5,
      id: 148,
      type: "house",
      previous: [147],
      next: [149],
    },
    {
      coordinate: [50.63180570005371, 3.050250890106355],
      house: -1,
      id: 149,
      type: "luck",
      previous: [148],
      next: [150],
    },
    {
      coordinate: [50.63237734315377, 3.0516080878678906],
      house: 7,
      id: 150,
      type: "house",
      previous: [149],
      next: [151],
    },
    {
      coordinate: [50.63286391483972, 3.052750708908235],
      house: 9,
      id: 151,
      type: "house",
      previous: [150],
      next: [152],
    },
    {
      coordinate: [50.63360907430246, 3.0545316956941235],
      house: 10,
      id: 152,
      type: "house",
      previous: [151],
      next: [153],
    },
    {
      coordinate: [50.63430215727188, 3.0561599089150775],
      house: 11,
      id: 153,
      type: "house",
      previous: [152],
      next: [20],
    },
    {
      coordinate: [50.63732331515086, 3.065391507668778],
      house: 19,
      id: 154,
      type: "house",
      previous: [29],
      next: [155],
    },
    {
      coordinate: [50.637500232275464, 3.0664858489468543],
      house: 19,
      id: 155,
      type: "house",
      previous: [154],
      next: [156],
    },
    {
      coordinate: [50.63777581339298, 3.067671385331437],
      house: -1,
      id: 156,
      type: "luck",
      previous: [155],
      next: [157],
    },
    {
      coordinate: [50.63821774722294, 3.068384992264896],
      house: -1,
      id: 157,
      type: "police",
      previous: [156],
      next: [158],
    },
    {
      coordinate: [50.638768899427966, 3.0694525114528233],
      house: 22,
      id: 158,
      type: "house",
      previous: [157],
      next: [38],
    },
    {
      coordinate: [50.629940647645135, 3.055881673259762],
      house: 50,
      id: 159,
      type: "house",
      previous: [82],
      next: [160],
    },
    {
      coordinate: [50.62937918341741, 3.054460102481869],
      house: -1,
      id: 160,
      type: "luck",
      previous: [159],
      next: [161],
    },
    {
      coordinate: [50.62900146734604, 3.0534945072365076],
      house: 61,
      id: 161,
      type: "house",
      previous: [160],
      next: [162],
    },
    {
      coordinate: [50.628630554016574, 3.0525718273353846],
      house: 60,
      id: 162,
      type: "house",
      previous: [161],
      next: [163],
    },
    {
      coordinate: [50.62776280956756, 3.052582556171444],
      house: 60,
      id: 163,
      type: "house",
      previous: [162],
      next: [164],
    },
    {
      coordinate: [50.62671128600869, 3.05243771688464],
      house: -1,
      id: 164,
      type: "metro",
      previous: [163],
      next: [165],
    },
    {
      coordinate: [50.626057900300786, 3.0516223253441126],
      house: 59,
      id: 165,
      type: "house",
      previous: [164],
      next: [166],
    },
    {
      coordinate: [50.625908164797295, 3.0525503696632654],
      house: -1,
      id: 166,
      type: "luck",
      previous: [165],
      next: [167],
    },
    {
      coordinate: [50.625945598717855, 3.054663950367001],
      house: 58,
      id: 167,
      type: "house",
      previous: [166],
      next: [168],
    },
    {
      coordinate: [50.625982586303095, 3.0564561056953323],
      house: -1,
      id: 168,
      type: "luck",
      previous: [167],
      next: [169],
    },
    {
      coordinate: [50.6259996016983, 3.0570944714408768],
      house: 57,
      id: 169,
      type: "house",
      previous: [168],
      next: [170],
    },
    {
      coordinate: [50.62601321401002, 3.0582800078254593],
      house: -1,
      id: 170,
      type: "luck",
      previous: [169],
      next: [171],
    },
    {
      coordinate: [50.62604043862167, 3.0603238510948074],
      house: 56,
      id: 171,
      type: "house",
      previous: [170],
      next: [172],
    },
    {
      coordinate: [50.62605485164499, 3.0612894463401688],
      house: -1,
      id: 172,
      type: "intersection",
      previous: [171],
      next: [173, 174],
    },
    {
      coordinate: [50.626085479304855, 3.0629416870933426],
      house: 54,
      id: 173,
      type: "house",
      previous: [172],
      next: [89],
    },
    {
      coordinate: [50.62549674412677, 3.0612787175041944],
      house: 54,
      id: 174,
      type: "house",
      previous: [172],
      next: [175],
    },
    {
      coordinate: [50.62462894183986, 3.0612733530861647],
      house: -1,
      id: 175,
      type: "luck",
      previous: [174],
      next: [176],
    },
    {
      coordinate: [50.62351608958753, 3.061262624250105],
      house: 66,
      id: 176,
      type: "house",
      previous: [175],
      next: [177],
    },
    {
      coordinate: [50.62248489105629, 3.0612518954140455],
      house: 67,
      id: 177,
      type: "house",
      previous: [176],
      next: [98],
    },
    {
      coordinate: [50.619166524505275, 3.0507240110247835],
      house: 72,
      id: 178,
      type: "house",
      previous: [106],
      next: [179],
    },
    {
      coordinate: [50.62011270826704, 3.051491122803043],
      house: -1,
      id: 179,
      type: "luck",
      previous: [178],
      next: [180],
    },
    {
      coordinate: [50.620926138801686, 3.0521455818026766],
      house: 71,
      id: 180,
      type: "house",
      previous: [179],
      next: [181],
    },
    {
      coordinate: [50.62203807995626, 3.053049103067991],
      house: 81,
      id: 181,
      type: "house",
      previous: [180],
      next: [182],
    },
    {
      coordinate: [50.6230658849948, 3.0538591301904887],
      house: -1,
      id: 182,
      type: "luck",
      previous: [181],
      next: [183],
    },
    {
      coordinate: [50.62316798360647, 3.052780882166502],
      house: 81,
      id: 183,
      type: "house",
      previous: [182],
      next: [184],
    },
    {
      coordinate: [50.62311693432833, 3.051252023028013],
      house: -1,
      id: 184,
      type: "metro",
      previous: [183],
      next: [185],
    },
    {
      coordinate: [50.62297399605474, 3.0502971566187114],
      house: 82,
      id: 185,
      type: "house",
      previous: [184],
      next: [186],
    },
    {
      coordinate: [50.622783411014126, 3.0493959343897075],
      house: 83,
      id: 186,
      type: "house",
      previous: [185],
      next: [187],
    },
    {
      coordinate: [50.62251903009839, 3.0481283854579146],
      house: -1,
      id: 187,
      type: "luck",
      previous: [186],
      next: [188],
    },
    {
      coordinate: [50.622064520103486, 3.0465252879490663],
      house: 84,
      id: 188,
      type: "house",
      previous: [187],
      next: [110],
    },
    {
      coordinate: [50.62333077257831, 3.067766179804579],
      house: 63,
      id: 189,
      type: "house",
      previous: [91],
      next: [213],
    },
    {
      coordinate: [50.62200688129829, 3.0669239661739027],
      house: -1,
      id: 190,
      type: "luck",
      previous: [213],
      next: [191],
    },
    {
      coordinate: [50.62107434938696, 3.0661246678874647],
      house: 77,
      id: 191,
      type: "house",
      previous: [190],
      next: [192],
    },
    {
      coordinate: [50.62009414992649, 3.0652502677486098],
      house: 78,
      id: 192,
      type: "house",
      previous: [191],
      next: [193],
    },
    {
      coordinate: [50.61892673294207, 3.064273943667189],
      house: 80,
      id: 193,
      type: "house",
      previous: [192],
      next: [194],
    },
    {
      coordinate: [50.61807923132108, 3.06374286628224],
      house: 79,
      id: 194,
      type: "house",
      previous: [193],
      next: [261],
    },
    {
      coordinate: [50.61748358851353, 3.0624661347911513],
      house: -1,
      id: 195,
      type: "metro",
      previous: [261],
      next: [109],
    },
    {
      coordinate: [50.61776949800257, 3.0599609515712416],
      house: 76,
      id: 196,
      type: "house",
      previous: [109],
      next: [197],
    },
    {
      coordinate: [50.61784778244052, 3.058678855662123],
      house: 75,
      id: 197,
      type: "house",
      previous: [196],
      next: [198],
    },
    {
      coordinate: [50.61790224110314, 3.057557692293898],
      house: -1,
      id: 198,
      type: "luck",
      previous: [197],
      next: [199],
    },
    {
      coordinate: [50.618011158239156, 3.0555996797130263],
      house: 74,
      id: 199,
      type: "house",
      previous: [198],
      next: [200],
    },
    {
      coordinate: [50.61816228034761, 3.0523241660640394],
      house: 73,
      id: 200,
      type: "house",
      previous: [199],
      next: [106],
    },
    {
      coordinate: [50.64430360543329, 3.0613155787204676],
      house: 105,
      id: 201,
      type: "park",
      previous: [49],
      next: [202],
    },
    {
      coordinate: [50.64513702764736, 3.060650390884774],
      house: 31,
      id: 202,
      type: "house",
      previous: [201],
      next: [203],
    },
    {
      coordinate: [50.645929615467665, 3.0602212374423914],
      house: -1,
      id: 203,
      type: "luck",
      previous: [202],
      next: [204],
    },
    {
      coordinate: [50.64686505599385, 3.0598671858524256],
      house: 32,
      id: 204,
      type: "house",
      previous: [203],
      next: [205],
    },
    {
      coordinate: [50.647215416196914, 3.0592449133609705],
      house: 32,
      id: 205,
      type: "house",
      previous: [204],
      next: [206],
    },
    {
      coordinate: [50.64697730760549, 3.058032554886239],
      house: 33,
      id: 206,
      type: "house",
      previous: [205],
      next: [207],
    },
    {
      coordinate: [50.64650108680254, 3.056412500641244],
      house: -1,
      id: 207,
      type: "luck",
      previous: [206],
      next: [208],
    },
    {
      coordinate: [50.645967033446276, 3.054561776420968],
      house: 34,
      id: 208,
      type: "house",
      previous: [207],
      next: [209],
    },
    {
      coordinate: [50.645232274959135, 3.054111165306466],
      house: 34,
      id: 209,
      type: "house",
      previous: [208],
      next: [210],
    },
    {
      coordinate: [50.644310408939795, 3.0547817175601892],
      house: 35,
      id: 210,
      type: "house",
      previous: [209],
      next: [211],
    },
    {
      coordinate: [50.64340008169991, 3.0554462440292696],
      house: -1,
      id: 211,
      type: "luck",
      previous: [210],
      next: [21],
    },
    {
      coordinate: [50.623853877493616, 3.04314224488661],
      house: -1,
      id: 212,
      type: "intersection",
      previous: [114],
      next: [115, 140],
    },
    {
      coordinate: [50.62247830844001, 3.0672987144791364],
      house: -1,
      id: 213,
      type: "intersection",
      previous: [189],
      next: [190, 246],
    },
    {
      coordinate: [50.63437206593462, 3.0677709485100593],
      house: -1,
      id: 214,
      type: "intersection",
      previous: [190],
      next: [215, 71],
    },
    {
      coordinate: [50.63394675495705, 3.0679157877968635],
      house: 45,
      id: 215,
      type: "house",
      previous: [214],
      next: [216],
    },
    {
      coordinate: [50.632768656054175, 3.068083106137408],
      house: 109,
      id: 216,
      type: "house",
      previous: [215],
      next: [217],
    },
    {
      coordinate: [50.63199966476616, 3.0682654963504206],
      house: -1,
      id: 217,
      type: "intersection",
      previous: [216],
      next: [241, 218],
    },
    {
      coordinate: [50.63226507026062, 3.0695207701693903],
      house: -1,
      id: 218,
      type: "luck",
      previous: [217],
      next: [219],
    },
    {
      coordinate: [50.63272101979122, 3.0715914355288874],
      house: 115,
      id: 219,
      type: "house",
      previous: [218],
      next: [220],
    },
    {
      coordinate: [50.63307488802063, 3.073243676282061],
      house: 116,
      id: 220,
      type: "house",
      previous: [219],
      next: [221],
    },
    {
      coordinate: [50.633313067059774, 3.074616967297686],
      house: -1,
      id: 221,
      type: "luck",
      previous: [220],
      next: [222],
    },
    {
      coordinate: [50.63263935751381, 3.0751104937564264],
      house: 117,
      id: 222,
      type: "house",
      previous: [221],
      next: [223],
    },
    {
      coordinate: [50.63195883301867, 3.0753787146579157],
      house: -1,
      id: 223,
      type: "police",
      previous: [222],
      next: [224],
    },
    {
      coordinate: [50.63144843318222, 3.0755825625430475],
      house: 118,
      id: 224,
      type: "house",
      previous: [223],
      next: [225],
    },
    {
      coordinate: [50.63089038968621, 3.0758293257724176],
      house: -1,
      id: 225,
      type: "luck",
      previous: [224],
      next: [226],
    },
    {
      coordinate: [50.63010775655062, 3.075754223],
      house: 119,
      id: 226,
      type: "house",
      previous: [225],
      next: [227],
    },
    {
      coordinate: [50.62929108199654, 3.0753357993136774],
      house: -1,
      id: 227,
      type: "luck",
      previous: [226],
      next: [228],
    },
    {
      coordinate: [50.62897121392986, 3.074466763592852],
      house: 120,
      id: 228,
      type: "house",
      previous: [227],
      next: [229],
    },
    {
      coordinate: [50.62873301289202, 3.072900353528155],
      house: 121,
      id: 229,
      type: "house",
      previous: [228],
      next: [230],
    },
    {
      coordinate: [50.628474393255935, 3.0709691630374323],
      house: 122,
      id: 230,
      type: "house",
      previous: [229],
      next: [231],
    },
    {
      coordinate: [50.6282838305085, 3.0698640929232965],
      house: -1,
      id: 231,
      type: "luck",
      previous: [230],
      next: [232],
    },
    {
      coordinate: [50.62822938386738, 3.068930684186114],
      house: 123,
      id: 232,
      type: "park",
      previous: [231],
      next: [233],
    },
    {
      coordinate: [50.62855606276841, 3.0675681220065485],
      house: -1,
      id: 233,
      type: "luck",
      previous: [232],
      next: [234],
    },
    {
      coordinate: [50.62900524255106, 3.0667741881381403],
      house: -1,
      id: 234,
      type: "intersection",
      previous: [233, 245],
      next: [235],
    },
    {
      coordinate: [50.62930469335537, 3.0662484751712213],
      house: 112,
      id: 235,
      type: "house",
      previous: [234],
      next: [236],
    },
    {
      coordinate: [50.62988997805668, 3.065207778073443],
      house: -1,
      id: 236,
      type: "luck",
      previous: [235],
      next: [237],
    },
    {
      coordinate: [50.63044803342788, 3.0641992674838434],
      house: 113,
      id: 237,
      type: "house",
      previous: [236],
      next: [238],
    },
    {
      coordinate: [50.63102649846676, 3.0631478415500055],
      house: -1,
      id: 238,
      type: "park",
      previous: [237],
      next: [239],
    },
    {
      coordinate: [50.63152329217167, 3.062257348157061],
      house: 114,
      id: 239,
      type: "house",
      previous: [238],
      next: [240],
    },
    {
      coordinate: [50.63172064711792, 3.061914025403155],
      house: -1,
      id: 240,
      type: "intersection",
      previous: [76, 239],
      next: [77],
    },
    {
      coordinate: [50.63144843318222, 3.068351327038897],
      house: 110,
      id: 241,
      type: "house",
      previous: [217],
      next: [242],
    },
    {
      coordinate: [50.63051608850777, 3.068608819104327],
      house: 111,
      id: 242,
      type: "house",
      previous: [241],
      next: [243],
    },
    {
      coordinate: [50.62976747721049, 3.0684478865634333],
      house: -1,
      id: 243,
      type: "bank",
      previous: [242],
      next: [244],
    },
    {
      coordinate: [50.62957011406469, 3.067932902432574],
      house: -1,
      id: 244,
      type: "luck",
      previous: [243],
      next: [245],
    },
    {
      coordinate: [50.62926385926709, 3.0671175108920465],
      house: 112,
      id: 245,
      type: "house",
      previous: [244],
      next: [234],
    },
    {
      coordinate: [50.62183827132943, 3.06814569145115],
      house: 124,
      id: 246,
      type: "house",
      previous: [214],
      next: [247],
    },
    {
      coordinate: [50.62145869287327, 3.069196801831424],
      house: -1,
      id: 247,
      type: "tax",
      previous: [246],
      next: [248],
    },
    {
      coordinate: [50.62215298278943, 3.070645194699466],
      house: 125,
      id: 248,
      type: "house",
      previous: [247],
      next: [249],
    },
    {
      coordinate: [50.622217317167824, 3.0718565148995367],
      house: -1,
      id: 249,
      type: "intersection",
      previous: [248],
      next: [250, 262],
    },
    {
      coordinate: [50.62158428940497, 3.07227493950586],
      house: 126,
      id: 250,
      type: "house",
      previous: [249],
      next: [252],
    },
    {
      coordinate: [50.61876072479339, 3.0756525472221767],
      house: -1,
      id: 251,
      type: "luck",
      previous: [272],
      next: [264],
    },
    {
      coordinate: [50.62059048669548, 3.072747008292481],
      house: 127,
      id: 252,
      type: "house",
      previous: [250],
      next: [253],
    },
    {
      coordinate: [50.61963069839061, 3.073122517554566],
      house: -1,
      id: 253,
      type: "luck",
      previous: [252],
      next: [254],
    },
    {
      coordinate: [50.618636854397664, 3.0734336538002935],
      house: 128,
      id: 254,
      type: "house",
      previous: [253],
      next: [255],
    },
    {
      coordinate: [50.61830329930945, 3.0735302133248297],
      house: -1,
      id: 255,
      type: "intersection",
      previous: [264, 254],
      next: [256],
    },
    {
      coordinate: [50.61826245566669, 3.072972313849732],
      house: -1,
      id: 256,
      type: "police",
      previous: [255],
      next: [257],
    },
    {
      coordinate: [50.61816034640462, 3.072124735801026],
      house: 129,
      id: 257,
      type: "house",
      previous: [256],
      next: [258],
    },
    {
      coordinate: [50.61790166861524, 3.0703330201790777],
      house: 130,
      id: 258,
      type: "house",
      previous: [257],
      next: [259],
    },
    {
      coordinate: [50.6177587144897, 3.068004862754151],
      house: -1,
      id: 259,
      type: "luck",
      previous: [258],
      next: [260],
    },
    {
      coordinate: [50.61770425566091, 3.0653226537392584],
      house: 79,
      id: 260,
      type: "house",
      previous: [259],
      next: [261],
    },
    {
      coordinate: [50.61767702622287, 3.0635523957894293],
      house: -1,
      id: 261,
      type: "intersection",
      previous: [260, 194],
      next: [195],
    },
    {
      coordinate: [50.622411908430095, 3.072514129439213],
      house: 126,
      id: 262,
      type: "house",
      previous: [249],
      next: [263],
    },
    {
      coordinate: [50.622827113842824, 3.0739088781269572],
      house: 131,
      id: 263,
      type: "house",
      previous: [262],
      next: [265],
    },
    {
      coordinate: [50.61846120683591, 3.0743650868950283],
      house: 128,
      id: 264,
      type: "house",
      previous: [251],
      next: [255],
    },
    {
      coordinate: [50.62316744341435, 3.0750783212574504],
      house: -1,
      id: 265,
      type: "luck",
      previous: [263],
      next: [266],
    },
    {
      coordinate: [50.623507770522856, 3.0762263067158244],
      house: 132,
      id: 266,
      type: "house",
      previous: [265],
      next: [267],
    },
    {
      coordinate: [50.623922966259755, 3.0774493940266154],
      house: -1,
      id: 267,
      type: "luck",
      previous: [266],
      next: [268],
    },
    {
      coordinate: [50.6239297727167, 3.0790158040913127],
      house: 133,
      id: 268,
      type: "house",
      previous: [267],
      next: [269],
    },
    {
      coordinate: [50.62339205958231, 3.080260349074223],
      house: -1,
      id: 269,
      type: "luck",
      previous: [268],
      next: [270],
    },
    {
      coordinate: [50.62239829507579, 3.0794020421894572],
      house: 134,
      id: 270,
      type: "house",
      previous: [269],
      next: [271],
    },
    {
      coordinate: [50.62130240712785, 3.0787904985340617],
      house: -1,
      id: 271,
      type: "luck",
      previous: [270],
      next: [272],
    },
    {
      coordinate: [50.61965926722124, 3.0778090432701504],
      house: 135,
      id: 272,
      type: "house",
      previous: [271],
      next: [251],
    },
  ],
}
