/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from "react"
import { COLORS, STYLES } from "../../export/const"
import anime from "animejs/lib/anime.es.js"
import { ReactComponent as Coin } from "../../assets/svg/coin.svg"
import { ReactComponent as MetroIcon } from "../../assets/svg/metro.svg"

import Button from "../button"
import HouseCost from "../house/houseCost"

// eslint-disable-next-line no-unused-vars

function InterCard() {
  const ref = useRef()

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }

  return (
    <div style={styles.container}>
      <div
        style={{
          backgroundColor: COLORS.yellow,
          borderRadius: 1000,
          padding: 30,
          zIndex: 10,
        }}
      >
        <Coin width={80} fill={COLORS.white} style={{ zIndex: 10 }} />
      </div>
      <div
        ref={ref}
        style={{
          ...STYLES.card,
          width: 275,
          transform: "translate(0, -50px)",
          paddingTop: 60,
        }}
      >
        <div
          style={{
            ...STYLES.title,
            fontFamily: "SFProBold",
            color: COLORS.yellow,
          }}
        >
          Intersection
        </div>
        <div style={STYLES.desc}>Effet de case</div>
        <div
          style={{
            ...STYLES.text,
            padding: 20,
            backgroundColor: COLORS.white,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: 20,
          }}
        >
          Cette action est placée sur chaque cases où plusieurs routes se
          croisent. Elle vous permet d’obtenir un valeur fixe d’argent à chaque
          fois que vous traversez celle-ci. D’autre part, vous ne pouvez pas
          choisir quel chemin vous voulez prendre votre chance détermine au pile
          ou face la direction que vous allez emprunter lors de votre tour.
        </div>
      </div>
    </div>
  )
}

export default InterCard
