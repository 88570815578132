/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React, { forwardRef, useImperativeHandle } from "react"
import { COLORS, STYLES } from "../../export/const"
import anime from "animejs/lib/anime.es.js"
import "./diceStyle.css"
// eslint-disable-next-line no-unused-vars

const Dice = forwardRef((props, ref) => {
  const dice = document.querySelector(`#${props.id}`)

  const rollDice = (random) => {
    setTimeout(() => {
      if (dice != null) {
        switch (random) {
          case 1:
            dice.style.animation = "rolling 1s"
            dice.style.transform = "rotateX(360deg) rotateY(360deg)"
            break

          case 6:
            dice.style.transform = "rotateX(180deg) rotateY(-360deg)"
            break

          case 2:
            dice.style.transform = "rotateX(-90deg) rotateY(360deg)"
            break

          case 5:
            dice.style.transform = "rotateX(90deg) rotateY(-360deg)"
            break

          case 3:
            dice.style.transform = "rotateX(360deg) rotateY(90deg)"
            break

          case 4:
            dice.style.transform = "rotateX(360deg) rotateY(-90deg)"
            break

          default:
            break
        }
      }
    }, 0)
  }

  // #region random
  // utilisé pour tester animation avec valeurs aléatoires
  const randomDice = () => {
    const tirage = Math.floor(Math.random() * 6) + 1 // tirage aléatoire entre 1 et 6
    rollDice(tirage)
  }
  // #endregion

  useImperativeHandle(ref, () => ({
    runDice() {
      // randomDice() // pour un tirage aléatoire coté client
      rollDice(props.value) // valeur données à l'appel du composant
    },
  }))
  return (
    <div className="dice" id={props.id}>
      <div className="face front" />
      <div className="face back" />
      <div className="face top" />
      <div className="face bottom" />
      <div className="face right" />
      <div className="face left" />
    </div>
  )
})
export default Dice
