/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useRef, useState } from "react"
import { COLORS } from "../export/const"
import { ReactComponent as Money } from "../assets/svg/money.svg"
import anime from "animejs/lib/anime.es.js"
import ReputationComponent from "./reputation"

function Button({
  color,
  hoverColor,
  text,
  cost,
  disabled,
  onClick,
  reputationShow,
  newReputation,
}) {
  const [hover, setHover] = useState(false)
  const ref = useRef()
  useEffect(() => {
    if (!disabled) {
      if (hover === true) {
        anime({
          targets: ref.current,
          scale: [1, 0.975],
          duration: 300,
        })
      } else {
        anime({
          targets: ref.current,
          scale: [0.975, 1],
          duration: 300,
        })
      }
    }
  }, [hover])

  const click = () => {
    anime({
      targets: ref.current,
      scale: [0.975, 0.95, 0.975],
      duration: 300,
    })
  }

  const styles = {
    border: {
      backgroundColor: !hover ? color : hoverColor || color,
      cursor: disabled ? "not-allowed" : "pointer",
      fontFamily: "SFProMedium",
      borderRadius: 30,
      color: COLORS.white,
      display: "flex",
      flexDirection: "column",
      lineHeight: 1,
      opacity: disabled ? 0.5 : 1,
      padding: 20,
      justifyContent: "center",
      alignItems: "center",
      width: "auto",
      maxWidth: 225,
    },
  }
  return (
    <>
      <div
        style={styles.border}
        onMouseOver={() => {
          setHover(true)
        }}
        onMouseOut={() => {
          setHover(false)
        }}
        onClick={
          disabled
            ? () => {}
            : () => {
                onClick()
                click()
              }
        }
        ref={ref}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          {text}
        </div>
        {cost && (
          <div
            style={{
              display: "flex",
              fontFamily: "SFProBold",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            {cost}
            <Money width={25} style={{ paddingLeft: 5 }} fill={COLORS.white} />
          </div>
        )}
      </div>
      {reputationShow && <ReputationComponent newReputation={newReputation} />}
    </>
  )
}
export default Button
