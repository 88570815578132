/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useRef } from "react"
import { COLORS } from "../../export/const"
import { ReactComponent as House } from "../../assets/svg/house.svg"
import { ReactComponent as Hotel } from "../../assets/svg/hotel.svg"
import { ReactComponent as Money } from "../../assets/svg/money.svg"
import { ReactComponent as Arrow } from "../../assets/svg/arrow-down.svg"
import { ReactComponent as EnchereIcon } from "../../assets/svg/enchere.svg"

// eslint-disable-next-line no-unused-vars

function HouseRent({ houseCost, hotelCost, level, houseUp, selling }) {
  const ref = useRef()

  const styles = {
    container: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: COLORS.white,
      boxShadow: "3px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: 30,
      color: COLORS.grey,
      margin: 10,
      marginBottom: 0,
      paddingTop: 20,
      paddingBottom: 20,
    },
    row: {
      display: "flex",
      fontFamily: "SFProBold",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "90%",
    },
  }

  let house = []

  // eslint-disable-next-line array-callback-return
  houseCost.map((e, index) => {
    const number = []
    for (let i = 0; i < index; i++) {
      number.push(i)
    }
    number.push(1)

    house.push(
      <div style={{ ...styles.row }} key={`R${index}`}>
        <div style={{ ...styles.row, justifyContent: "flex-start" }}>
          {number.map((e, index2) => (
            <House
              key={`R${index}S${index2}`}
              fill={level === index ? COLORS.green : COLORS.grey}
              width={25}
              style={{ paddingRight: 0 }}
            />
          ))}
        </div>
        <div
          style={{
            ...styles.row,
            paddingLeft: 5,
            justifyContent: "flex-end",
            color: level === index ? COLORS.green : COLORS.grey,
          }}
        >
          {e || "-"}
          <Money
            fill={level === index ? COLORS.green : COLORS.grey}
            width={25}
            style={{ paddingRight: 5, paddingLeft: 5 }}
          />
        </div>
      </div>
    )
    if (level === index && houseUp) {
      house.push(
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "SFProBold",
            alignSelf: "center",
            padding: 10,
            margin: 10,
            backgroundColor: COLORS.yellow,
            color: COLORS.white,
            borderRadius: 15,
          }}
        >
          <Arrow fill={COLORS.white} width={25} style={{ paddingRight: 10 }} />
          Amélioration effective au prochain tour.
        </div>
      )
    }
  })
  house.push(
    <div style={{ ...styles.row }}>
      <div style={{ ...styles.row, justifyContent: "flex-start" }}>
        <Hotel
          fill={level === 4 ? COLORS.green : COLORS.grey}
          width={25}
          style={{ paddingRight: 0 }}
        />
      </div>
      <div
        style={{
          ...styles.row,
          paddingLeft: 5,
          justifyContent: "flex-end",
          color: level === 4 ? COLORS.green : COLORS.grey,
        }}
      >
        {hotelCost || "-"}
        <Money
          fill={level === 4 ? COLORS.green : COLORS.grey}
          width={25}
          style={{ paddingRight: 5, paddingLeft: 5 }}
        />
      </div>
    </div>
  )
  if (selling) {
    house.push(
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "SFProBold",
          alignSelf: "center",
          padding: 10,
          margin: 10,
          backgroundColor: COLORS.red,
          color: COLORS.white,
          borderRadius: 15,
        }}
      >
        <EnchereIcon
          fill={COLORS.white}
          width={35}
          height={35}
          style={{ paddingRight: 10 }}
        />
        Le propriétaire a mis en vente la maison pour le prochain tour.
      </div>
    )
  }
  return (
    <div
      style={{
        ...styles.container,
        paddingBottom: selling ? 0 : 20,
      }}
    >
      {house.map((e) => e)}
    </div>
  )
}

export default HouseRent
