/* eslint-disable no-unused-vars */
import firebase from "firebase/compat/app"
// eslint-disable-next-line import/no-cycle
import { getPlayerById } from "../online/user"
import geoCases from "../assets/geoJson/cases"

// #region tools

export const getObjectPlayer = (id, playersData) => {
  for (let i = 0; i < playersData.length; i++) {
    if (playersData[i].playerId === id) {
      return playersData[i]
    }
  }
  return null
}
// #endregion

// #region set/removeListenerLocalUser

let oldListener

export const setListenUser = async (
  setNewMoney,
  setNewReputation,
  setPlayerName,
  setTurnPlayed
) => {
  const localUser = firebase.auth().currentUser
  if (localUser !== null) {
    oldListener && oldListener()
    oldListener = firebase
      .firestore()
      .collection("players")
      .doc(localUser.uid)
      .onSnapshot((snapshot) => {
        setNewMoney(snapshot.data().money)
        setNewReputation(snapshot.data().reputation)
        setPlayerName(snapshot.data().name)
        setTurnPlayed(snapshot.data().turnPlayed)
      })
  }
}

export const removeListenUser = async () => {
  if (oldListener !== undefined) {
    oldListener()
  }
}

// #endregion

// #region Listener Get Next Owner Pdp Name Auction
let listenerNextOwner

export const setListenerNextOwner = async (
  data,
  nextOwner,
  nextOwnerName,
  setNextOwner,
  setNextOwnerName,
  setNextOwnerPdp,
  setAuctionMoney,
  playersData
) => {
  listenerNextOwner && listenerNextOwner()

  listenerNextOwner = firebase
    .firestore()
    .collection("houses")
    .doc(`${data.houseId}`)
    .onSnapshot(async (snapshot) => {
      setAuctionMoney(snapshot.data().auctionsPrice)
      setNextOwner(snapshot.data().nextOwnerId)
      if (snapshot.data().nextOwnerId !== "") {
        const player = getObjectPlayer(snapshot.data().nextOwnerId, playersData)
        setNextOwnerName(player.name)
        setNextOwnerPdp(player.pdp)
      } else {
        setNextOwnerName("Banque")
      }
    })
}

export const setNewSinglePlayerPos = (
  newCaseId,
  playerId,
  setNewPlayerPos,
  newPlayerPos,
  casesData
) => {
  // eslint-disable-next-line prefer-const
  let playerPosLocal = newPlayerPos
  for (const player of playerPosLocal) {
    if (player.id === playerId) {
      player.coordinate = casesData.cases[newCaseId].coordinate
    }
  }
  setNewPlayerPos([...playerPosLocal])
}

export const listenPlayer = async (setNewPlayerPos, newPlayerPos) => {
  const doc = firebase.firestore().collection("const").doc("players")

  doc.onSnapshot((snapshot) => {
    const P = snapshot.data()
    for (const player of Object.keys(P)) {
      if (player !== firebase.auth().currentUser.uid) {
        setNewSinglePlayerPos(
          P[player].actualCaseId,
          player,
          setNewPlayerPos,
          newPlayerPos,
          geoCases
        )
      }
    }
  })
}

// #endregion
