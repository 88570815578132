/* eslint-disable no-promise-executor-return */
/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { COLORS, STYLES } from "../../export/const"
import "./diceStyle.css"
import anime from "animejs/lib/anime.es.js"

import Dice from "./dice"
import "./multiDice.css"

// eslint-disable-next-line no-unused-vars

const MultiDice = forwardRef((props, ref) => {
  const styles = {
    container: {
      margin: 0,
      padding: 0,
      border: 0,
      outline: 0,
      boxSizing: "border-box",
      width: "35vw",
      height: "35vh",
      borderRadius: 40,
      backgroundColor: COLORS.white,
      flexDirection: "row",
      gap: 40,
      alignItems: "center",
      justifyContent: "center",
      placeItems: "center",
      color: COLORS.black,
      left: "50%",
      top: "0%",
      zIndex: 10,
      position: "absolute",
      bottom: 0,
      display: "block",
      transform: "translate(-50%, 0%)",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
    },
    dices: {
      margin: 0,
      padding: 0,
      border: 0,
      outline: 0,
      height: "20vh",
      borderRadius: 40,
      flexDirection: "row",
      gap: 40,
      alignItems: "center",
      justifyContent: "center",
      placeItems: "center",
      color: COLORS.black,
      left: "50%",
      top: "10%",
      zIndex: 10,
      position: "absolute",
      bottom: 0,
      display: "flex",
      width: "75%",
      transform: "translate(-50%, 0%)",
    },
  }

  const diceRefs = useRef([])
  const [showDice, setShowDice] = useState(false)
  const [showSteps, setShowSteps] = useState(false)

  async function handleRollAllDice() {
    diceRefs.current.map((diceRef) => {
      diceRef?.runDice()
      return 1
    })
    await new Promise((resolve) => setTimeout(resolve, 1000))
    setShowSteps(true)
    await new Promise((resolve) => setTimeout(resolve, 2000))
    setShowSteps(false)
  }

  const randomValues = props.randArray // valeurs du retour serveur, passé en props

  const diceGen = () => {
    diceRefs.current = []
    const dices = []

    for (let i = 0; i < randomValues.length; i++) {
      dices.push(
        <Dice
          id={`dice${i}`}
          ref={(refDice) => (diceRefs.current[i] = refDice)}
          value={randomValues[i]}
        />
      )
    }
    return dices
  }

  useEffect(() => {
    if (showDice === true) {
      anime({
        targets: "#diceContainer",
        translateY: [1100, 350],
        // opacity: [0, 1],
        duration: 1000,
      })
    } else {
      anime({
        targets: "#diceContainer",
        translateY: [350, 1100],
        // opacity: [1, 0],
        duration: 1000,
      })
    }
  }, [showDice])

  const [varDicex2, setVarDicex2] = useState(false)
  const [varDicePlus2, setVarDicePlus2] = useState(false)
  const [varDiceMinus2, setVarDiceMinus2] = useState(false)

  const numberOfStep = () => {
    let somme = 0
    for (let i = 0; i < randomValues.length; i++) {
      somme += randomValues[i]
    }
    if (varDicex2 === true) {
      somme *= 2
    }
    if (varDicePlus2 === true) {
      somme += 2
    }
    if (varDiceMinus2 === true) {
      somme -= 2
    }
    return somme
  }

  useImperativeHandle(ref, () => ({
    launchAnim(dicex2, dicePlus2, diceMinus2) {
      setVarDicePlus2(dicePlus2)
      setVarDiceMinus2(diceMinus2)
      setVarDicex2(dicex2)
      setShowDice(!showDice)
    },
    rollDice() {
      handleRollAllDice()
    },
  }))
  return (
    <div className="frame">
      <div style={styles.container} id="diceContainer">
        <div style={styles.dices}>{diceGen().map((e) => e)}</div>
        <h1 className="bonus">
          {varDicePlus2 ? `Bonus dés + 2` : null}
          {varDicex2 ? `Bonus dés x 2` : null}
          {varDiceMinus2 ? `Malus dés - 2` : null}
        </h1>
        {showSteps && (
          <h1 className="titre-avec-fondu">
            Avancez de {numberOfStep()} cases
          </h1>
        )}
      </div>
    </div>
  )
})

export default MultiDice
