/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from "react"
import { COLORS, STYLES } from "../export/const"
import anime from "animejs/lib/anime.es.js"
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import { borderRadius } from "@mui/system"
// eslint-disable-next-line import/no-unresolved
import { ReactComponent as BankSvg } from "../assets/svg/bank.svg"

// eslint-disable-next-line no-unused-vars

function Player({ nextOwner, nextOwnerName, nextOwnerPdp, openCard }) {
  const [hover, setHover] = useState(false)
  const ref = useRef()

  function renderPdp() {
    const styles = {
      width: 45,
      height: 45,
      borderRadius: 1000,
      aspectRatio: 1,
      objectFit: "cover",
    }
    if (nextOwnerName === "Banque") {
      return (
        <BankSvg
          width={30}
          height={30}
          fill={COLORS.white}
          style={{ styles }}
        />
      )
    }
    return <img src={nextOwnerPdp} alt="" style={styles} />
  }

  useEffect(() => {
    if (hover === true) {
      anime({
        targets: ref.current,
        scale: [1, 0.975],
        duration: 400,
      })
    } else {
      anime({
        targets: ref.current,
        scale: [0.975, 1],
        duration: 400,
      })
    }
  }, [hover])

  const styles = {
    container: {
      borderRadius: "29px 29px 20px 20px",
      backgroundColor: COLORS.white,
      cursor: "pointer",
      width: 60,
      height: 75,
      borderWidth: 5,
      border: "solid #E3B505",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      color: COLORS.black,
      borderColor: COLORS.yellow,
      textAlign: "center",
      paddingBottom: 10,
      fontFamily: "SFProBold",
      fontSize: 12.5,
    },
  }

  return (
    <div
      style={styles.container}
      ref={ref}
      onMouseOver={() => {
        setHover(true)
      }}
      onMouseOut={() => {
        setHover(false)
      }}
      onClick={() => {
        nextOwner && openCard("player", nextOwner)
      }}
    >
      <div
        style={{
          margin: 7.5,
          borderRadius: 1000,
          backgroundColor: COLORS.yellow,
          width: 45,
          height: 45,
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {/* <img
          src={nextOwnerPdp}
          alt=""
          style={{
            width: 45,
            height: 45,
            borderRadius: 1000,
            aspectRatio: 1,
            objectFit: "cover",
          }}
        /> */}
        {renderPdp()}
      </div>
      {nextOwnerName}
    </div>
  )
}

export default Player
