/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from "react"
import { COLORS } from "../../export/const"
import anime from "animejs/lib/anime.es.js"

// eslint-disable-next-line no-unused-vars

function CardsController({ left, visible, children }) {
  const ref = useRef()

  const styles = {
    overlay: {
      zIndex: 10,
      color: COLORS.white,
      fontFamily: "SFProMedium",
      position: "absolute",
      top: "50%",
      transform: "translate(0, -50%)",
      opacity: 0,
    },
    left: {
      left: "5%",
    },
    right: {
      right: "5%",
    },
  }

  useEffect(() => {
    if (visible === true) {
      anime({
        targets: ref.current,
        translateX: [-100, 0],
        opacity: [0, 1],
        duration: 800,
      })
    } else {
      anime({
        targets: ref.current,
        translateX: [0, -100, -1000],
        opacity: [1, 0],
        duration: 800,
      })
    }
  }, [visible])

  return visible ? (
    <div
      ref={ref}
      style={
        left
          ? { ...styles.overlay, ...styles.left }
          : { ...styles.overlay, ...styles.right }
      }
    >
      {children}
    </div>
  ) : (
    <></>
  )
}

export default CardsController
