/* eslint-disable import/no-unresolved */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react"

import Media from "react-media"

import anime from "animejs/lib/anime.es.js"

import ReglesTablet from "./ReglesTablet"
import ReglesDesktop from "./ReglesDesktop"

const Regles = forwardRef((props, ref) => {
  const { displayHelp, setDisplayHelp } = props

  const style = {
    position: "absolute",
    backgroundColor: "transparent",
    height: "100%",
    width: "100%",
    zIndex: 20,
    transform: "translate(0%, 120%)",
  }

  const showHelp = async () => {
    setDisplayHelp(true)
    anime({
      targets: "#regleLayer",
      translateY: "-100%",
      duration: 1000,
    })
  }

  useEffect(() => {
    if (!displayHelp) {
      anime({
        targets: "#regleLayer",
        translateY: "0%",
        duration: 1000,
      })
    } else {
      anime({
        targets: "#regleLayer",
        translateY: "-120%",
        duration: 1000,
      })
    }
  }, [displayHelp])

  const hideHelp = () => {
    anime({
      targets: "#regleLayer",
      translateY: "0%",
      duration: 1000,
    })
    setTimeout(() => {
      setDisplayHelp(false)
    }, 1000)
  }

  useImperativeHandle(ref, () => ({
    setDisplayHelp,
  }))

  return (
    <div style={style} id="regleLayer">
      {displayHelp && (
        <Media
          queries={{
            tablet: "(min-width: 880px) and (max-width: 1199px)",
            desktop: "(min-width: 1200px)",
          }}
        >
          {(matches) => (
            <>
              {matches.tablet && (
                <ReglesTablet
                  setDisplayHelp={setDisplayHelp}
                  onHideRules={hideHelp}
                />
              )}
              {matches.desktop && (
                <ReglesDesktop
                  setDisplayHelp={setDisplayHelp}
                  onHideRules={hideHelp}
                />
              )}
            </>
          )}
        </Media>
      )}
    </div>
  )
})

export default Regles
