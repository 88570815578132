/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useRef } from "react"
import { COLORS } from "../../export/const"
import { ReactComponent as House } from "../../assets/svg/house.svg"
import { ReactComponent as Hotel } from "../../assets/svg/hotel.svg"
import { ReactComponent as Money } from "../../assets/svg/money.svg"
import { ReactComponent as Enchere } from "../../assets/svg/enchere.svg"

// eslint-disable-next-line no-unused-vars

function HouseCost({ houseCost, hotelCost }) {
  const ref = useRef()

  const styles = {
    container: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: COLORS.white,
      boxShadow: "3px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: 30,
      color: COLORS.grey,
      margin: 10,
      marginBottom: 0,
      paddingTop: 20,
      paddingBottom: 20,
    },
    row: {
      display: "flex",
      fontFamily: "SFProBold",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "90%",
    },
  }

  return (
    <div style={{ ...styles.container, position: "relative" }}>
      <div style={{ ...styles.row }}>
        <div style={{ ...styles.row, justifyContent: "flex-start" }}>
          <House fill={COLORS.grey} width={25} style={{ paddingRight: 5 }} />
          Prix/Maison
        </div>
        <div
          style={{
            ...styles.row,
            paddingLeft: 5,
            justifyContent: "flex-end",
          }}
        >
          {houseCost || "-"}
          <Money
            fill={COLORS.grey}
            width={25}
            style={{ paddingRight: 5, paddingLeft: 5 }}
          />
        </div>
      </div>
      <div
        style={{
          ...styles.row,
          alignContent: "center",
        }}
      >
        <div
          style={{
            ...styles.row,
            color: COLORS.yellow,
            justifyContent: "flex-start",
          }}
        >
          <Hotel fill={COLORS.yellow} width={25} style={{ paddingRight: 5 }} />
          Prix/Hôtel
        </div>
        <div
          style={{
            ...styles.row,
            paddingLeft: 5,
            justifyContent: "flex-end",
            color: COLORS.yellow,
          }}
        >
          {hotelCost || "-"}
          <Money
            fill={COLORS.yellow}
            width={25}
            style={{ paddingRight: 5, paddingLeft: 5 }}
          />
        </div>
      </div>
    </div>
  )
}

export default HouseCost
