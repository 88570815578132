/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from "react"
import { COLORS, STYLES } from "../../export/const"
import { ReactComponent as Metro } from "../../assets/svg/metro.svg"

// eslint-disable-next-line no-unused-vars

function MetroCardDesc() {
  const ref = useRef()

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }

  return (
    <div style={styles.container}>
      <div
        style={{
          backgroundColor: COLORS.yellow,
          borderRadius: 1000,
          padding: 30,
          zIndex: 10,
        }}
      >
        <Metro
          width={80}
          height={80}
          fill={COLORS.white}
          style={{ zIndex: 10 }}
        />
      </div>
      <div
        ref={ref}
        style={{
          ...STYLES.card,
          width: 275,
          transform: "translate(0, -50px)",
          paddingTop: 60,
        }}
      >
        <div
          style={{
            ...STYLES.title,
            fontFamily: "SFProBold",
            color: COLORS.yellow,
          }}
        >
          Métro
        </div>
        <div style={STYLES.desc}>Effet de case</div>
        <div
          style={{
            ...STYLES.text,
            padding: 20,
            backgroundColor: COLORS.white,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: 20,
          }}
        >
          Cette case vous permet de prendre le métro jusqu'à la prochaine
          station contre le prix d'un ticket, ceci vous permettra peut-être
          d'éviter des propriétés où vous ne voulez vraiment pas tomber.
        </div>
      </div>
    </div>
  )
}

export default MetroCardDesc
