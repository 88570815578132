/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from "react"
import { COLORS, STYLES } from "../export/const"
import anime from "animejs/lib/anime.es.js"
import Lottie from "lottie-react"
import flip from "../assets/lottie/coin-flip.json"
import { hexToRgb } from "@mui/material"
import { height } from "@mui/system"
import { reload } from "firebase/auth"

// eslint-disable-next-line no-unused-vars

const PileFace = forwardRef((props, ref) => {
  const styles = {
    container: {
      margin: 0,
      padding: 0,
      border: 0,
      boxSizing: "border-box",
      width: "45vh",
      height: "45vh",
      borderRadius: 10000,
      backgroundColor: COLORS.white,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      placeItems: "center",
      color: COLORS.black,
      left: "50%",
      top: "-10%",
      zIndex: 10,
      position: "absolute",
      bottom: 0,
      display: "flex",
      transform: "translate(-50%, 0%)",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
    },
    lottie: {
      position: "absolute",
      top: "-30%",
      left: "-20%",
      width: "65vh",
      height: "65vh",

      textAlign: "center",
      lineHeight: "100px",
    },
  }

  const [showFlip, setShowFlip] = useState(false)
  const flipRef = useRef()

  const [flipBool, setFlipBool] = useState(false)

  useEffect(() => {
    if (showFlip === true) {
      anime({
        targets: "#flipContainer",
        translateY: [1300, 350],
        duration: 1000,
      })
      setTimeout(() => {
        setFlipBool(true)
      }, 1500)

      setTimeout(() => {
        setFlipBool(false)
      }, 1050)
    } else {
      setFlipBool(false)

      anime({
        targets: "#flipContainer",
        translateY: [350, 1300],
        duration: 1200,
      })
    }
  }, [showFlip])
  const launchFlip = () => {
    setShowFlip(!showFlip)
  }

  useImperativeHandle(ref, () => ({
    launchFlip,
  }))
  return (
    <div className="flip" style={styles.container} id="flipContainer">
      <Lottie
        ref={flipRef}
        loop={flipBool}
        animationData={flip}
        style={styles.lottie}
      />
    </div>
  )
})
export default PileFace
