/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useRef, useState } from "react"
// eslint-disable-next-line import/no-unresolved
import { COLORS } from "../export/const"
// eslint-disable-next-line import/no-unresolved

function ReputationComponent({ newReputation }) {
  const ref = useRef()
  let styles = {}
  let affichage
  const [displaySentence, setDisplaySentence] = useState(
    "Vous n'avez ni bonus ni de malus"
  )
  const displayText = () => {
    if (newReputation < 0) {
      styles = {
        color: COLORS.red,
      }
      affichage = `Vous avez un malus de ${newReputation}%`
      setDisplaySentence(affichage)
    } else if (newReputation > 0) {
      styles = {
        color: COLORS.green,
      }
      affichage = `Vous avez un bonus de ${newReputation}%`
      setDisplaySentence(affichage)
    } else {
      styles = {
        color: COLORS.black,
      }
      setDisplaySentence("Vous n'avez ni bonus ni de malus")
    }
  }

  useEffect(() => {
    displayText()
  }, [newReputation])

  return (
    <div style={styles} ref={ref}>
      <div
        style={{
          display: "flex",
          fontFamily: "SFProBold",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {displaySentence}
      </div>
    </div>
  )
}
export default ReputationComponent
