/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-return-await */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/auth"
import "firebase/compat/functions"
import { toast } from "react-hot-toast"
import { COLORS } from "../export/const"
// eslint-disable-next-line import/no-cycle

export const sub = async (mail, password, nickname, uid) => {
  const instance = firebase.functions().httpsCallable("sub")
  try {
    const result = await instance({ mail, password, nickname, uid })
    return result
  } catch (e) {
    console.error(e)
    return e
  }
}

export const sub1 = async (mail, password, nickname, setRegister) => {
  if (mail !== undefined && password !== undefined && nickname !== undefined) {
    return await firebase
      .auth()
      .createUserWithEmailAndPassword(mail, password)
      .then(async () => {
        const { uid } = firebase.auth().currentUser
        const result = await sub(mail, password, nickname, uid)
        return result
        // eslint-disable-next-line no-new, no-promise-executor-return
        // new Promise((resolve) => setTimeout(resolve, 3000))
      })
      .catch((e) => e)
  }
  setRegister(false)
  return { message: "Informations manquantes..." }
}

export const login = async (email, password) => {
  if (email !== undefined && password !== undefined) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((e) => true)
      .catch((e) => e)
  }
  return { message: "Informations manquantes..." }
}

export const logout = async () => {
  await firebase
    .auth()
    .signOut()
    .then(() => {
      toast.success("Vous êtes déconnecté!", {
        style: {
          backgroundColor: COLORS.white,
          color: COLORS.black,
          fontFamily: "SFProBold",
          height: 35,
          marginTop: -7,
          fontSize: 20,
          minWidth: 200,
          padding: 20,
          whiteSpace: "nowrap",
          boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
        },
      })
    })
    .catch((e) => {
      toast.error(e.message)
    })
}
