/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from "react"
import { COLORS, STYLES } from "../../export/const"
import anime from "animejs/lib/anime.es.js"
import { ReactComponent as Park } from "../../assets/svg/bench-tree.svg"

import Button from "../button"
import HouseCost from "../house/houseCost"

// eslint-disable-next-line no-unused-vars

function ParkCardDesc() {
  const ref = useRef()

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }

  return (
    <div style={styles.container}>
      <div
        style={{
          backgroundColor: COLORS.green,
          borderRadius: 1000,
          padding: 30,
          zIndex: 10,
        }}
      >
        <Park
          width={80}
          height={80}
          fill={COLORS.white}
          style={{ zIndex: 10 }}
        />
      </div>
      <div
        ref={ref}
        style={{
          ...STYLES.card,
          width: 275,
          transform: "translate(0, -50px)",
          paddingTop: 60,
        }}
      >
        <div
          style={{
            ...STYLES.title,
            fontFamily: "SFProBold",
            color: COLORS.green,
          }}
        >
          Parc
        </div>
        <div style={STYLES.desc}>Effet de case</div>
        <div
          style={{
            ...STYLES.text,
            padding: 20,
            backgroundColor: COLORS.white,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: 20,
          }}
        >
          Cette case à pour effet de remonter votre réputation de façon
          significative. Vous pouvez vous y balader et observer les merveilles
          de la nature.
        </div>
      </div>
    </div>
  )
}

export default ParkCardDesc
