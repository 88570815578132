/* eslint-disable no-unused-vars */
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"

/* eslint-disable consistent-return */
/* eslint-disable default-case */
export const COLORS = {
  red: "#FF0022",
  redLow: "#542128",
  green: "#0CCE6B",
  greenLow: "#22523A",
  yellow: "#E3B505",
  yellowLow: "#584C20",
  blue: "#1B98E0",
  blueLow: "#264557",
  white: "#FFFFFF",
  black: "#000000",
  black2: "#3F3F3F",
  grey: "#909090",
  lightGrey: "#D9D9D9",
  purple: "#A11CB7",
  orange: "#FF7A00",
  blueLuck: "#5A80E3",
}

export const METRO = {
  111: { name: "Montebello", next: 124, price: 100000 },
  124: { name: "Avenue de Dunkerque", next: 34, price: 100000 },
  34: { name: "Lille Flandres", next: 77, price: 100000 },
  77: { name: "République", next: 164, price: 100000 },
  164: { name: "Rue de Flandres", next: 195, price: 100000 },
  195: { name: "Porte de Douai", next: 107, price: 100000 },
  107: { name: "Porte d'Arras", next: 111, price: 100000 },
  184: { name: "Rue d'Iéna", next: 124, price: 100000 },
}

export const HOUSE_COLOR = (type, owner) => {
  if (firebase.auth().currentUser.uid !== owner && owner !== "") {
    return COLORS.red
  }
  if (firebase.auth().currentUser.uid === owner) {
    return COLORS.green
  }
  switch (type) {
    case "neutral": {
      return COLORS.blue
    }
    case "owned": {
      return COLORS.grey
    }
    case "": {
      return COLORS.green
    }
    case "selling": {
      return COLORS.red
    }
    case "park": {
      return COLORS.yellow
    }
  }
  return COLORS.red
}

export const HOUSE_COLOR_STROKE = (type, owner) => {
  if (firebase.auth().currentUser.uid !== owner && owner !== "") {
    return COLORS.redLow
  }
  if (firebase.auth().currentUser.uid === owner) {
    return COLORS.greenLow
  }
  switch (type) {
    case "neutral": {
      return COLORS.blueLow
    }
    case "owned": {
      return COLORS.grey
    }
    case "": {
      return COLORS.greenLow
    }
    case "selling": {
      return COLORS.redLow
    }
    case "park": {
      return COLORS.yellowLow
    }
  }
  return COLORS.red
}

export const STYLES = {
  title: {
    fontSize: "22px",
    color: COLORS.black,
    fontFamily: "SFProBold",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  desc: {
    fontSize: "15px",
    color: COLORS.grey,
    fontFamily: "SFProMedium",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingBottom: 10,
  },
  text: {
    fontSize: "14px",
    textAlign: "justify",
    lineHeight: "16px",
  },
  card: {
    backgroundColor: COLORS.white,
    borderRadius: 30,
    padding: 30,
    width: 250,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    color: COLORS.black,
    boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
  },
  scndCard: {
    backgroundColor: COLORS.white,
    borderRadius: 30,
    padding: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: COLORS.black,
    transform: "translate(0, 177.5px)",
    width: "auto",
    boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
  },
}

export const SIZE = {
  title: 25,
  desc: 15,
}

export const RESIZE = (zoom, type) => {
  const M = (zoom - 14) / 4
  switch (type) {
    case "linear":
      return M
    case "small":
      if (M < 0.1) {
        return 0.1
      }
      return M
    case "medium":
      if (M < 0.4) {
        return 0.4
      }
      return M
    case "big":
      if (M < 0.5) {
        return 0.5
      }
      return M
    case "":
      return 1
    case "special":
      if (zoom <= 15) {
        return 0.75
      }
      if (zoom <= 16) {
        return 0.75
      }
      if (zoom <= 17) {
        return 1
      }
      if (zoom <= 18) {
        return 1
      }
      break
    case "houseStat":
      if (zoom <= 15) {
        return 0
      }
      if (zoom <= 16) {
        return 0
      }
      if (zoom <= 17) {
        return 1
      }
      if (zoom <= 18) {
        return 1
      }
  }
}

export function radiansToDegrees(radians) {
  const pi = Math.PI
  const deg = radians * (180 / pi)
  // if (Math.abs(deg) > 360) {
  //   const div = Math.floor(deg / 360)
  //   deg > 0 ? (deg += div * 360) : (deg -= div * 360)
  // }
  return deg
}
export function CENTROID(polygon) {
  const centroid = [0, 0]
  let signedArea = 0

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < polygon.length; i++) {
    const [x1, y1] = polygon[i]
    const [x2, y2] = polygon[(i + 1) % polygon.length]
    const a = x1 * y2 - x2 * y1
    signedArea += a
    centroid[0] += (x1 + x2) * a
    centroid[1] += (y1 + y2) * a
  }

  signedArea *= 0.5
  centroid[0] /= 6 * signedArea
  centroid[1] /= 6 * signedArea

  return centroid
}

export const firebaseConfig = {
  apiKey: "AIzaSyCLMnpFgyuMEQZahrvrzWFctDFjIasTtiA",
  authDomain: "capitalille.firebaseapp.com",
  projectId: "capitalille",
  storageBucket: "capitalille.appspot.com",
  messagingSenderId: "239775587324",
  appId: "1:239775587324:web:1595c33850b9fd09e6b079",
}

export function PRICE(value) {
  const M = value / 1000000
  const K = value / 1000
  if (M >= 1 || M <= -1) {
    return `${M.toFixed(2)}M`
  }
  return `${Math.round(K)}k`
}
