export default {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        id: [1, 118],
        name: "",
        address: "1 Boulevard Vauban",
      },
      geometry: {
        coordinates: [
          [
            [3.0406039892170895, 50.626390628535034],
            [3.0406269568040045, 50.626113796738025],
            [3.0413159843988353, 50.62553529553645],
            [3.0425777834879284, 50.62620234726779],
            [3.043135886931452, 50.626782161469436],
            [3.0419667889558752, 50.62767566621875],
            [3.0418839454334545, 50.62760559632173],
            [3.0406039892170895, 50.626390628535034],
          ],
        ],
        type: "Polygon",
      },
      id: 0,
    },
    {
      type: "Feature",
      properties: {
        id: [2],
        name: "",
        address: "1 Rue du Maréchal Leclerc",
      },
      geometry: {
        coordinates: [
          [
            [3.043634465763688, 50.62886760105846],
            [3.042318174246816, 50.62759468058883],
            [3.043247862660877, 50.626858940197565],
            [3.04495996211611, 50.62792751174541],
            [3.043634465763688, 50.62886760105846],
          ],
        ],
        type: "Polygon",
      },
      id: 1,
    },
    {
      type: "Feature",
      properties: {
        id: [146],
        name: "",
        address: "1 rue Nationale",
      },
      geometry: {
        coordinates: [
          [
            [3.0437738387464606, 50.628947696089966],
            [3.0450901302633326, 50.62798425192281],
            [3.0465444943166915, 50.628725813728494],
            [3.0455227575446884, 50.62970675940741],
            [3.0437738387464606, 50.628947696089966],
          ],
        ],
        type: "Polygon",
      },
      id: 2,
    },
    {
      type: "Feature",
      properties: {
        id: [4],
        name: "",
        address: "2 Boulevard Vauban",
      },
      geometry: {
        coordinates: [
          [
            [3.0447475053740334, 50.63044873127194],
            [3.043458828364578, 50.629152497735845],
            [3.043569286394444, 50.629082430040285],
            [3.043974299168383, 50.62919920947539],
            [3.0453826390431686, 50.629823974524896],
            [3.0447475053740334, 50.63044873127194],
          ],
        ],
        type: "Polygon",
      },
      id: 3,
    },
    {
      type: "Feature",
      properties: {
        id: [6, 147],
        name: "",
        address: "3 Boulevard Vauban",
      },
      geometry: {
        coordinates: [
          [
            [3.0448595715244835, 50.630542380201746],
            [3.0455499342077417, 50.62988259143438],
            [3.0474277207069917, 50.63070002747489],
            [3.0461942727124836, 50.63183857541668],
            [3.0448595715244835, 50.630542380201746],
          ],
        ],
        type: "Polygon",
      },
      id: 4,
    },
    {
      type: "Feature",
      properties: {
        id: [148],
        name: "",
        address: "2 rue Nationale",
      },
      geometry: {
        coordinates: [
          [
            [3.0462615915599542, 50.631914393102306],
            [3.04757102281917, 50.630741509793495],
            [3.0493724827958033, 50.63150162205608],
            [3.0474916633494615, 50.63311241537002],
            [3.0462615915599542, 50.631914393102306],
          ],
        ],
        type: "Polygon",
      },
      id: 5,
    },
    {
      type: "Feature",
      properties: {
        id: [8],
        name: "",
        address: "4 Boulevard Vauban",
      },
      geometry: {
        coordinates: [
          [
            [3.0463837118370805, 50.63508173699819],
            [3.045471077929193, 50.63419584462079],
            [3.047383641162895, 50.63330490192544],
            [3.048542289429463, 50.63446262078676],
            [3.0463837118370805, 50.63508173699819],
          ],
        ],
        type: "Polygon",
      },
      id: 6,
    },
    {
      type: "Feature",
      properties: {
        id: [9, 150],
        name: "ISEN",
        address: "5 Boulevard Vauban",
      },
      geometry: {
        coordinates: [
          [
            [3.048764854222327, 50.63436238661251],
            [3.0481458503541887, 50.63374829450166],
            [3.0510186631781266, 50.63219792790497],
            [3.0520265284507673, 50.6326207602296],
            [3.048764854222327, 50.63436238661251],
          ],
        ],
        type: "Polygon",
      },
      id: 7,
    },
    {
      type: "Feature",
      properties: {
        id: [11],
        name: "Palais Rameau",
        address: "1 Façade de l'Esplanade",
      },
      geometry: {
        coordinates: [
          [
            [3.0488521495690577, 50.6344492409178],
            [3.050328235715881, 50.6336489076495],
            [3.0509948552663957, 50.634172397618045],
            [3.0494235377548478, 50.634992855743235],
            [3.0488521495690577, 50.6344492409178],
          ],
        ],
        type: "Polygon",
      },
      id: 8,
    },
    {
      type: "Feature",
      properties: {
        id: [151],
        name: "",
        address: "3 rue Nationale",
      },
      geometry: {
        coordinates: [
          [
            [3.052318727861433, 50.63261552734832],
            [3.0531837460869724, 50.632157458773975],
            [3.0537630702209526, 50.63260545995493],
            [3.053604351280171, 50.63269606641626],
            [3.0532789774517255, 50.63302828861393],
            [3.052318727861433, 50.63261552734832],
          ],
        ],
        type: "Polygon",
      },
      id: 9,
    },
    {
      type: "Feature",
      properties: {
        id: [152],
        name: "",
        address: "4 rue Nationale",
      },
      geometry: {
        coordinates: [
          [
            [3.0533889625734503, 50.6330786252264],
            [3.0537460801892564, 50.63272626865199],
            [3.0548412408798242, 50.63216249263934],
            [3.055063447396634, 50.63370279324627],
            [3.054547610838739, 50.63355681920996],
            [3.0533889625734503, 50.6330786252264],
          ],
        ],
        type: "Polygon",
      },
      id: 10,
    },
    {
      type: "Feature",
      properties: {
        id: [153],
        name: "",
        address: "5 rue Nationale",
      },
      geometry: {
        coordinates: [
          [
            [3.0553525137663655, 50.633818257996666],
            [3.0568920874892456, 50.633038048593164],
            [3.0582015187498257, 50.6336169148739],
            [3.056661945025553, 50.634462550193064],
            [3.0558921581634024, 50.63409006984389],
            [3.0553525137663655, 50.633818257996666],
          ],
        ],
        type: "Polygon",
      },
      id: 11,
    },
    {
      type: "Feature",
      properties: {
        id: [19],
        name: "",
        address: "4 Boulevard de la Liberté",
      },
      geometry: {
        coordinates: [
          [
            [3.0553922890840397, 50.6351939377752],
            [3.0552494420383027, 50.63406140463846],
            [3.055804958330384, 50.63421744415817],
            [3.0565588732975186, 50.63453455513431],
            [3.0553922890840397, 50.6351939377752],
          ],
        ],
        type: "Polygon",
      },
      id: 12,
    },
    {
      type: "Feature",
      properties: {
        id: [17],
        name: "",
        address: "2 Boulevard de la Liberté",
      },
      geometry: {
        coordinates: [
          [
            [3.05371385908623, 50.636125621491516],
            [3.05257901866014, 50.635259882444075],
            [3.053531332303521, 50.63477163885605],
            [3.0544519021596273, 50.63571288742264],
            [3.05371385908623, 50.636125621491516],
          ],
        ],
        type: "Polygon",
      },
      id: 13,
    },
    {
      type: "Feature",
      properties: {
        id: [15],
        name: "",
        address: "1 Boulevard de la Liberté",
      },
      geometry: {
        coordinates: [
          [
            [3.0516913171351803, 50.637210653038835],
            [3.0506755159157706, 50.636219101390196],
            [3.051540534141253, 50.63576610129036],
            [3.0526356948317925, 50.636722429714524],
            [3.051881779863379, 50.63713012170595],
            [3.0516913171351803, 50.637210653038835],
          ],
        ],
        type: "Polygon",
      },
      id: 14,
    },
    {
      type: "Feature",
      properties: {
        id: [18],
        name: "",
        address: "3 Boulevard de la Liberté",
      },
      geometry: {
        coordinates: [
          [
            [3.05460695286763, 50.63682110663544],
            [3.053900653581479, 50.636252346427426],
            [3.055337059994173, 50.63544197707415],
            [3.0560354233320197, 50.63589498029819],
            [3.05460695286763, 50.63682110663544],
          ],
        ],
        type: "Polygon",
      },
      id: 15,
    },
    {
      type: "Feature",
      properties: {
        id: [22],
        name: "",
        address: "6 rue Nationale",
      },
      geometry: {
        coordinates: [
          [
            [3.0554551516630397, 50.63538579981005],
            [3.056780454815936, 50.634640850792124],
            [3.0575423057312037, 50.634968025801044],
            [3.056137643107263, 50.63583880357507],
            [3.0554551516630397, 50.63538579981005],
          ],
        ],
        type: "Polygon",
      },
      id: 16,
    },
    {
      type: "Feature",
      properties: {
        id: [24],
        name: "",
        address: "7 rue Nationale",
      },
      geometry: {
        coordinates: [
          [
            [3.0579326041894035, 50.635493948752554],
            [3.0582659139646466, 50.63527751257709],
            [3.0594404341249515, 50.635765750910565],
            [3.0591547400319996, 50.63605265148291],
            [3.058146874759416, 50.63560971653061],
            [3.0579326041894035, 50.635493948752554],
          ],
        ],
        type: "Polygon",
      },
      id: 17,
    },
    {
      type: "Feature",
      properties: {
        id: [25],
        name: "Printemps",
        address: "8 rue Nationale",
      },
      geometry: {
        coordinates: [
          [
            [3.059645156443935, 50.63573275510666],
            [3.0607321387221873, 50.6347045474395],
            [3.0619148372933864, 50.63519921567786],
            [3.061525975888884, 50.63557168723804],
            [3.061764054299431, 50.63571262165334],
            [3.0621053000228926, 50.63571765501737],
            [3.062692560102306, 50.63596428920084],
            [3.0619307091870382, 50.63669915072765],
            [3.059645156443935, 50.63573275510666],
          ],
        ],
        type: "Polygon",
      },
      id: 18,
    },
    {
      type: "Feature",
      properties: {
        id: [30, 154, 155],
        name: "Apple Store",
        address: "1 rue Faidherbe",
      },
      geometry: {
        coordinates: [
          [
            [3.0655395034839046, 50.63741550504753],
            [3.0653411048075725, 50.63718397810203],
            [3.0674044510352587, 50.63691721738118],
            [3.0674282588771007, 50.63698264939637],
            [3.0677774405460525, 50.637269542540935],
            [3.0672854118299995, 50.637551400767165],
            [3.065666478636018, 50.637375239573856],
            [3.0655395034839046, 50.63741550504753],
          ],
        ],
        type: "Polygon",
      },
      id: 19,
    },
    {
      type: "Feature",
      properties: {
        id: [32, 33],
        name: "",
        address: "1 place de la Gare",
      },
      geometry: {
        coordinates: [
          [
            [3.0692815387278642, 50.6374283864549],
            [3.0687974459585803, 50.63706096282854],
            [3.0688450616409284, 50.63684453386867],
            [3.068837125694131, 50.63673883564229],
            [3.069384706038022, 50.63666837002597],
            [3.0694799374027752, 50.63682440089113],
            [3.069702143919642, 50.63695526508886],
            [3.0699084785428, 50.63702573027504],
            [3.0692815387278642, 50.6374283864549],
          ],
        ],
        type: "Polygon",
      },
      id: 20,
    },
    {
      type: "Feature",
      properties: {
        id: [36],
        name: "",
        address: "2 place de la gare",
      },
      geometry: {
        coordinates: [
          [
            [3.069387906071995, 50.63749669263706],
            [3.0700386537288296, 50.6370688709529],
            [3.071125878471207, 50.637592322834195],
            [3.071054454948353, 50.637667820220486],
            [3.070506874603069, 50.63839258895979],
            [3.0699831020996555, 50.637944642932865],
            [3.069387906071995, 50.63749669263706],
          ],
        ],
        type: "Polygon",
      },
      id: 21,
    },
    {
      type: "Feature",
      properties: {
        id: [39, 158],
        name: "",
        address: "1 rue des Canonniers",
      },
      geometry: {
        coordinates: [
          [
            [3.068070737891304, 50.639499837541535],
            [3.0687135496013127, 50.63848316885512],
            [3.069967429231326, 50.639067001086374],
            [3.06937223320503, 50.63981187995523],
            [3.068070737891304, 50.639499837541535],
          ],
        ],
        type: "Polygon",
      },
      id: 22,
    },
    {
      type: "Feature",
      properties: {
        id: [40],
        name: "",
        address: "2 rue des Canonniers",
      },
      geometry: {
        coordinates: [
          [
            [3.0686813971918525, 50.64097313895823],
            [3.0694273762121043, 50.64004709443509],
            [3.0698559173521573, 50.64019304831575],
            [3.070141611445024, 50.64039436326945],
            [3.0707209355790326, 50.641229811112396],
            [3.0703320741745586, 50.64137072856181],
            [3.0703162022794572, 50.64145125262908],
            [3.0701019317094733, 50.64134556476273],
            [3.0696337108352907, 50.64118954890648],
            [3.0691099383319056, 50.641058696498845],
            [3.0686813971918525, 50.64097313895823],
          ],
        ],
        type: "Polygon",
      },
      id: 23,
    },
    {
      type: "Feature",
      properties: {
        id: [42, 44],
        name: "",
        address: "1 Place aux Bleuets",
      },
      geometry: {
        coordinates: [
          [
            [3.0672529248484466, 50.6430818359768],
            [3.0663244190455714, 50.64233700893641],
            [3.066792639921175, 50.64192432941229],
            [3.06680851181477, 50.6418136098992],
            [3.066371920216284, 50.641325047529875],
            [3.0659592509713036, 50.641038179140764],
            [3.0659116352889555, 50.640947588758905],
            [3.065983058813174, 50.640570126956675],
            [3.066554446998964, 50.640615422533216],
            [3.067260746283665, 50.64075130900008],
            [3.0675543763247504, 50.64090229350296],
            [3.067713095264196, 50.64106837589543],
            [3.067848006364528, 50.641350211339926],
            [3.0679670455697305, 50.641737732316244],
            [3.067967045416225, 50.641843225921946],
            [3.0678718140515002, 50.64215022010157],
            [3.0676496075346336, 50.642598126044135],
            [3.067475016700172, 50.6428799523143],
            [3.0672529248484466, 50.6430818359768],
          ],
        ],
        type: "Polygon",
      },
      id: 24,
    },
    {
      type: "Feature",
      properties: {
        id: [43],
        name: "",
        address: "2 Place aux Bleuets",
      },
      geometry: {
        coordinates: [
          [
            [3.067726568781012, 50.6426784801848],
            [3.0679884550320082, 50.64220037899972],
            [3.068821729470187, 50.64242684858857],
            [3.069004256251503, 50.64241175064987],
            [3.069091551669402, 50.642457044450936],
            [3.069043935987054, 50.64296030597299],
            [3.0685598432178267, 50.64315657650559],
            [3.0680598785562267, 50.64300056666178],
            [3.067726568781012, 50.6426784801848],
          ],
        ],
        type: "Polygon",
      },
      id: 25,
    },
    {
      type: "Feature",
      properties: {
        id: [46],
        name: "",
        address: "1 rue de Thionville",
      },
      geometry: {
        coordinates: [
          [
            [3.065085127378893, 50.64371144758337],
            [3.064220109151961, 50.64298676086898],
            [3.066021569128594, 50.64220167098247],
            [3.0667992919375706, 50.64285088086564],
            [3.065085127378893, 50.64371144758337],
          ],
        ],
        type: "Polygon",
      },
      id: 26,
    },
    {
      type: "Feature",
      properties: {
        id: [47, 48],
        name: "",
        address: "2 rue de Thionville",
      },
      geometry: {
        coordinates: [
          [
            [3.0629024315094, 50.64418973184644],
            [3.0643467738689196, 50.64321845768427],
            [3.064989585578928, 50.643746873578635],
            [3.0635452432194654, 50.64447154857109],
            [3.0629024315094, 50.64418973184644],
          ],
        ],
        type: "Polygon",
      },
      id: 27,
    },
    {
      type: "Feature",
      properties: {
        id: [50],
        name: "",
        address: "1 rue du Pont Neuf",
      },
      geometry: {
        coordinates: [
          [
            [3.05997495079248, 50.6429645853992],
            [3.061510973226632, 50.641902099529545],
            [3.063030290399098, 50.6424713892055],
            [3.061816090503271, 50.64370940249242],
            [3.05997495079248, 50.6429645853992],
          ],
        ],
        type: "Polygon",
      },
      id: 28,
    },
    {
      type: "Feature",
      properties: {
        id: [52],
        name: "",
        address: "2 rue du Pont Neuf",
      },
      geometry: {
        coordinates: [
          [
            [3.0589015641887443, 50.642629812867625],
            [3.0598926831826816, 50.64201680450492],
            [3.0607124976590967, 50.64159778152958],
            [3.061397715729015, 50.64186161124604],
            [3.0598559750716277, 50.64290915579849],
            [3.0589015641887443, 50.642629812867625],
          ],
        ],
        type: "Polygon",
      },
      id: 29,
    },
    {
      type: "Feature",
      properties: {
        id: [54],
        name: "",
        address: "3 rue du Pont Neuf",
      },
      geometry: {
        coordinates: [
          [
            [3.0565810074780018, 50.64195602079323],
            [3.057885345035089, 50.64015923162762],
            [3.0583863488643033, 50.64034548735427],
            [3.058939180676049, 50.64064678182049],
            [3.0593538045352773, 50.640920684204275],
            [3.057885345035089, 50.642339473000646],
            [3.0565810074780018, 50.64195602079323],
          ],
        ],
        type: "Polygon",
      },
      id: 30,
    },
    {
      type: "Feature",
      properties: {
        id: [202],
        name: "",
        address: "1 rue Maracci",
      },
      geometry: {
        coordinates: [
          [
            [3.0597217927830513, 50.645175216577],
            [3.059972588224497, 50.64453244706229],
            [3.060829472648635, 50.64485051967378],
            [3.0607981232192287, 50.644920097770495],
            [3.060411480246813, 50.64534087739784],
            [3.060401030437049, 50.64538063590754],
            [3.0597217927830513, 50.645175216577],
          ],
        ],
        type: "Polygon",
      },
      id: 31,
    },
    {
      type: "Feature",
      properties: {
        id: [204, 205],
        name: "",
        address: "2 rue Maracci",
      },
      geometry: {
        coordinates: [
          [
            [3.058948630078504, 50.647147477732375],
            [3.0592968872657877, 50.64626006816968],
            [3.0599611556064303, 50.64646454190543],
            [3.059670941283457, 50.64725789157475],
            [3.058948630078504, 50.647147477732375],
          ],
        ],
        type: "Polygon",
      },
      id: 32,
    },
    {
      type: "Feature",
      properties: {
        id: [206],
        name: "",
        address: "1 rue Saint-Sébastien",
      },
      geometry: {
        coordinates: [
          [
            [3.057375023524571, 50.646763071961004],
            [3.058077987108504, 50.645883834171485],
            [3.059193699951095, 50.64623144177571],
            [3.0588454427626743, 50.64712294128768],
            [3.0583359553951937, 50.64703297421448],
            [3.057375023524571, 50.646763071961004],
          ],
        ],
        type: "Polygon",
      },
      id: 33,
    },
    {
      type: "Feature",
      properties: {
        id: [208, 209],
        name: "",
        address: "2 rue Saint-Sébastien",
      },
      geometry: {
        coordinates: [
          [
            [3.0538192616773188, 50.645715448655494],
            [3.0544964284320315, 50.64479120555757],
            [3.05645698741651, 50.64539646415406],
            [3.0553799698160446, 50.64616938440457],
            [3.0538192616773188, 50.645715448655494],
          ],
        ],
        type: "Polygon",
      },
      id: 34,
    },
    {
      type: "Feature",
      properties: {
        id: [210],
        name: "",
        address: "1 rue Royale",
      },
      geometry: {
        coordinates: [
          [
            [3.0545425507607717, 50.64472851949478],
            [3.055116530199683, 50.643926947744035],
            [3.0575156352732336, 50.64462218933468],
            [3.056535355780426, 50.64533786846266],
            [3.0545425507607717, 50.64472851949478],
          ],
        ],
        type: "Polygon",
      },
      id: 35,
    },
    {
      type: "Feature",
      properties: {
        id: [21],
        name: "",
        address: "2 rue Royale",
      },
      geometry: {
        coordinates: [
          [
            [3.0541923292407205, 50.64224332540468],
            [3.054753410266642, 50.64151941935219],
            [3.0564044073059904, 50.64201020433376],
            [3.055791732622737, 50.64285270660031],
            [3.0541923292407205, 50.64224332540468],
          ],
        ],
        type: "Polygon",
      },
      id: 36,
    },
    {
      type: "Feature",
      properties: {
        id: [56],
        name: "",
        address: "3 rue Royale",
      },
      geometry: {
        coordinates: [
          [
            [3.0548247813617593, 50.64144012489501],
            [3.055985815932644, 50.639972589911],
            [3.057555214388401, 50.64003352653188],
            [3.057771407033016, 50.64010461915609],
            [3.0564822583015427, 50.641927600424964],
            [3.0548247813617593, 50.64144012489501],
          ],
        ],
        type: "Polygon",
      },
      id: 37,
    },
    {
      type: "Feature",
      properties: {
        id: [58],
        name: "",
        address: "4 rue Royale",
      },
      geometry: {
        coordinates: [
          [
            [3.0577375251976378, 50.63959792253897],
            [3.057633432442742, 50.639430345089096],
            [3.0572090542882506, 50.639293235821754],
            [3.0575853896326066, 50.638785420236985],
            [3.0583060317808872, 50.638886983792645],
            [3.058522224425502, 50.638947921821085],
            [3.058674359989112, 50.638947921821085],
            [3.0580818320015055, 50.63971471859037],
            [3.0577375251976378, 50.63959792253897],
          ],
        ],
        type: "Polygon",
      },
      id: 38,
    },
    {
      type: "Feature",
      properties: {
        id: [60],
        name: "",
        address: "5 rue Esquermoise",
      },
      geometry: {
        coordinates: [
          [
            [3.05955581296476, 50.638624363590765],
            [3.0590833920016394, 50.63800989729066],
            [3.0603565264621864, 50.637649338945096],
            [3.061021118665593, 50.638126697288556],
            [3.05955581296476, 50.638624363590765],
          ],
        ],
        type: "Polygon",
      },
      id: 39,
    },
    {
      type: "Feature",
      properties: {
        id: [26, 64],
        name: "",
        address: "1 Place du Général de Gaulle",
      },
      geometry: {
        coordinates: [
          [
            [3.062014329444395, 50.636753489339156],
            [3.0628404249368373, 50.63595378704568],
            [3.06349804042722, 50.636305381971084],
            [3.0629002081636827, 50.63701545782098],
            [3.0628784688082646, 50.63712575990331],
            [3.062014329444395, 50.636753489339156],
          ],
        ],
        type: "Polygon",
      },
      id: 40,
    },
    {
      type: "Feature",
      properties: {
        id: [65],
        name: "",
        address: "1 rue Neuve",
      },
      geometry: {
        coordinates: [
          [
            [3.0635510477961247, 50.63626903185474],
            [3.0629640852098987, 50.6359691419984],
            [3.0628716929513473, 50.63588296639807],
            [3.063915181993764, 50.63507635512144],
            [3.064360838772757, 50.63504533133454],
            [3.064426056837249, 50.63512116722228],
            [3.0646217110326575, 50.63519355591015],
            [3.0643010555459114, 50.635462427203265],
            [3.0635510477961247, 50.63626903185474],
          ],
        ],
        type: "Polygon",
      },
      id: 41,
    },
    {
      type: "Feature",
      properties: {
        id: [66],
        name: "",
        address: "1 rue Saint-Nicolas",
      },
      geometry: {
        coordinates: [
          [
            [3.0655127632709878, 50.63642398086921],
            [3.064164923257664, 50.63573457831589],
            [3.064404056163056, 50.63546570857952],
            [3.0646809660903216, 50.63521909986039],
            [3.0664038099785103, 50.635808546134086],
            [3.065990762232275, 50.63612567206516],
            [3.065800542875479, 50.636218741225946],
            [3.0655127632709878, 50.63642398086921],
          ],
        ],
        type: "Polygon",
      },
      id: 42,
    },
    {
      type: "Feature",
      properties: {
        id: [68],
        name: "",
        address: "1 rue Pierre Mauroy",
      },
      geometry: {
        coordinates: [
          [
            [3.066621197838117, 50.63644279440004],
            [3.0661048881550244, 50.6361980577494],
            [3.066083148800516, 50.63616358769585],
            [3.0664961965458417, 50.63584646202057],
            [3.066979897196177, 50.63601191916172],
            [3.066621197838117, 50.63644279440004],
          ],
        ],
        type: "Polygon",
      },
      id: 43,
    },
    {
      type: "Feature",
      properties: {
        id: [70],
        name: "",
        address: "2 rue Pierre Mauroy",
      },
      geometry: {
        coordinates: [
          [
            [3.068149444927741, 50.63576462836252],
            [3.067024433303942, 50.635354429307625],
            [3.0674863945988307, 50.63488907310463],
            [3.0677744410528476, 50.63447541927866],
            [3.0685298836413324, 50.63470292933323],
            [3.068236402347736, 50.63504763943911],
            [3.0681331404118453, 50.63523378184547],
            [3.0681005313791445, 50.63554057049902],
            [3.068149444927741, 50.63576462836252],
          ],
        ],
        type: "Polygon",
      },
      id: 44,
    },
    {
      type: "Feature",
      properties: {
        id: [71, 215],
        name: "",
        address: "1 rue du Molinel",
      },
      geometry: {
        coordinates: [
          [
            [3.0665062704910326, 50.63365830916652],
            [3.0667345337182326, 50.63347216052182],
            [3.0667399685577834, 50.63340666360082],
            [3.067930198247012, 50.63356178773884],
            [3.0678541105042996, 50.633965108102814],
            [3.067745413728886, 50.63425122216023],
            [3.0675986730821023, 50.634209856379954],
            [3.0665062704910326, 50.63365830916652],
          ],
        ],
        type: "Polygon",
      },
      id: 45,
    },
    {
      type: "Feature",
      properties: {
        id: [72, 73],
        name: "",
        address: "2 rue du Molinel",
      },
      geometry: {
        coordinates: [
          [
            [3.0648791356177867, 50.63299034817831],
            [3.0649896424489214, 50.63284374579024],
            [3.065077608180019, 50.631735792335974],
            [3.066082930821466, 50.631707893890365],
            [3.066638450240049, 50.63342873844937],
            [3.06642481917865, 50.63360807874565],
            [3.0648791356177867, 50.63299034817831],
          ],
        ],
        type: "Polygon",
      },
      id: 46,
    },
    {
      type: "Feature",
      properties: {
        id: [75],
        name: "",
        address: "3 rue du Molinel",
      },
      geometry: {
        coordinates: [
          [
            [3.062699686980949, 50.63212123843354],
            [3.063202348301161, 50.63181037165299],
            [3.06427050360719, 50.631738632872924],
            [3.064352186072, 50.631762545811796],
            [3.0637301426878594, 50.63257159307187],
            [3.0632337646340204, 50.632400219691505],
            [3.0628756184432007, 50.632220874787095],
            [3.062699686980949, 50.63212123843354],
          ],
        ],
        type: "Polygon",
      },
      id: 47,
    },
    {
      type: "Feature",
      properties: {
        id: [79, 81],
        name: "",
        address: "1 rue Léon Gambetta",
      },
      geometry: {
        coordinates: [
          [
            [3.05973350572242, 50.63144383847154],
            [3.0568228691978447, 50.63024807507222],
            [3.0583008089876103, 50.62946363776206],
            [3.060472475617331, 50.63103249929412],
            [3.05973350572242, 50.63144383847154],
          ],
        ],
        type: "Polygon",
      },
      id: 48,
    },
    {
      type: "Feature",
      properties: {
        id: [80],
        name: "",
        address: "2 rue Léon Gambetta",
      },
      geometry: {
        coordinates: [
          [
            [3.057164902862297, 50.63168885420342],
            [3.0579813780218217, 50.63086021862361],
            [3.0587978531802946, 50.63118945504456],
            [3.0575848043726523, 50.631947799826435],
            [3.057164902862297, 50.63168885420342],
          ],
        ],
        type: "Polygon",
      },
      id: 49,
    },
    {
      type: "Feature",
      properties: {
        id: [83, 159],
        name: "",
        address: "2 rue Solférino",
      },
      geometry: {
        coordinates: [
          [
            [3.055088583076582, 50.629581998582665],
            [3.055931975443343, 50.628525493234065],
            [3.057505362882978, 50.629739348411874],
            [3.056633621193697, 50.63020240342553],
            [3.055088583076582, 50.629581998582665],
          ],
        ],
        type: "Polygon",
      },
      id: 50,
    },
    {
      type: "Feature",
      properties: {
        id: [85],
        name: "",
        address: "3 rue Solférino",
      },
      geometry: {
        coordinates: [
          [
            [3.0575897191439196, 50.629691886541764],
            [3.056951859370514, 50.62918386897158],
            [3.058213404254758, 50.62850950088546],
            [3.058879613350655, 50.62901752574254],
            [3.0587591287273597, 50.62904450035998],
            [3.0575897191439196, 50.629691886541764],
          ],
        ],
        type: "Polygon",
      },
      id: 51,
    },
    {
      type: "Feature",
      properties: {
        id: [86],
        name: "",
        address: "4 rue Solférino",
      },
      geometry: {
        coordinates: [
          [
            [3.0589971681730503, 50.62896732252992],
            [3.058345133738385, 50.62846828877787],
            [3.0576930993038047, 50.62767701719639],
            [3.0607193895610294, 50.62773096795473],
            [3.0607264768916593, 50.628041183612396],
            [3.0589971681730503, 50.62896732252992],
          ],
        ],
        type: "Polygon",
      },
      id: 52,
    },
    {
      type: "Feature",
      properties: {
        id: [88],
        name: "",
        address: "5 rue Solférino",
      },
      geometry: {
        coordinates: [
          [
            [3.0619680205943496, 50.627694007527566],
            [3.061953845933118, 50.62750967537036],
            [3.0638390759293657, 50.62652955566463],
            [3.0642288791230214, 50.62635421099401],
            [3.0644344117164337, 50.62640816326984],
            [3.064590332995408, 50.62773896648076],
            [3.0619680205943496, 50.627694007527566],
          ],
        ],
        type: "Polygon",
      },
      id: 53,
    },
    {
      type: "Feature",
      properties: {
        id: [173, 174],
        name: "",
        address: "3 rue Brûle Maison",
      },
      geometry: {
        coordinates: [
          [
            [3.0613527770925373, 50.6260248588581],
            [3.0613527770925373, 50.625058196651],
            [3.064315281371506, 50.62504470820048],
            [3.0644003293416517, 50.62586300052499],
            [3.0641097487781224, 50.62603385097117],
            [3.063627810283691, 50.62605633124707],
            [3.0613527770925373, 50.6260248588581],
          ],
        ],
        type: "Polygon",
      },
      id: 54,
    },
    {
      type: "Feature",
      properties: {
        id: [90],
        name: "",
        address: "6 rue Solférino",
      },
      geometry: {
        coordinates: [
          [
            [3.064501041666233, 50.62582703253074],
            [3.064366382381678, 50.62437028134474],
            [3.067314711999444, 50.62444222073438],
            [3.067314711999444, 50.62456361820435],
            [3.065124726778322, 50.62572362266948],
            [3.064756185575334, 50.62588997755245],
            [3.064501041666233, 50.62582703253074],
          ],
        ],
        type: "Polygon",
      },
      id: 55,
    },
    {
      type: "Feature",
      properties: {
        id: [171],
        name: "",
        address: "2 rue Brûle Maison",
      },
      geometry: {
        coordinates: [
          [
            [3.058976435838389, 50.62598655633414],
            [3.059486723657841, 50.625064854849825],
            [3.061216032375171, 50.62506935099839],
            [3.0612231197058293, 50.6260180287488],
            [3.058976435838389, 50.62598655633414],
          ],
        ],
        type: "Polygon",
      },
      id: 56,
    },
    {
      type: "Feature",
      properties: {
        id: [169],
        name: "",
        address: "1 rue Brûle Maison",
      },
      geometry: {
        coordinates: [
          [
            [3.056688257889249, 50.625955930978506],
            [3.0576804842030754, 50.62422491460535],
            [3.058488439915436, 50.6242294108346],
            [3.057503300933462, 50.625969419167575],
            [3.056688257889249, 50.625955930978506],
          ],
        ],
        type: "Polygon",
      },
      id: 57,
    },
    {
      type: "Feature",
      properties: {
        id: [167],
        name: "",
        address: "1 rue Littré",
      },
      geometry: {
        coordinates: [
          [
            [3.0534524090125217, 50.62587780786197],
            [3.0546834194080077, 50.62419389707665],
            [3.056068306103583, 50.62590221192289],
            [3.0534524090125217, 50.62587780786197],
          ],
        ],
        type: "Polygon",
      },
      id: 58,
    },
    {
      type: "Feature",
      properties: {
        id: [165],
        name: "",
        address: "1 rue du Marché",
      },
      geometry: {
        coordinates: [
          [
            [3.050092778628965, 50.62748031470477],
            [3.0506313456772034, 50.62664246282347],
            [3.050221008878765, 50.62652044431391],
            [3.0507339298766, 50.62573951835316],
            [3.051118620624635, 50.62486909600872],
            [3.0523624540458627, 50.62509687163646],
            [3.0516828337232766, 50.625788326605914],
            [3.051092974575397, 50.62680515367745],
            [3.0504902924017188, 50.62765927142377],
            [3.050092778628965, 50.62748031470477],
          ],
        ],
        type: "Polygon",
      },
      id: 59,
    },
    {
      type: "Feature",
      properties: {
        id: [162, 163],
        name: "",
        address: "4 rue Gambetta",
      },
      geometry: {
        coordinates: [
          [
            [3.0521863049516753, 50.6283898297616],
            [3.0531480318230138, 50.62714527235866],
            [3.0539687054211413, 50.62757639727991],
            [3.0530710936740775, 50.62877213816614],
            [3.0521863049516753, 50.6283898297616],
          ],
        ],
        type: "Polygon",
      },
      id: 60,
    },
    {
      type: "Feature",
      properties: {
        id: [161],
        name: "",
        address: "3 rue Gambetta",
      },
      geometry: {
        coordinates: [
          [
            [3.053200769440167, 50.62882146464679],
            [3.0540855581614608, 50.62763385940082],
            [3.0548164705848535, 50.627999905311924],
            [3.053931681862423, 50.62911429420649],
            [3.053200769440167, 50.62882146464679],
          ],
        ],
        type: "Polygon",
      },
      id: 61,
    },
    {
      type: "Feature",
      properties: {
        id: [92],
        name: "",
        address: "1 Boulevard Victor Hugo",
      },
      geometry: {
        coordinates: [
          [
            [3.066703147020661, 50.62387716836781],
            [3.0664574778574263, 50.62268059211061],
            [3.0673371319555827, 50.622685619805935],
            [3.068113763501543, 50.62413357360421],
            [3.0678760191511003, 50.62425926193498],
            [3.066703147020661, 50.62387716836781],
          ],
        ],
        type: "Polygon",
      },
      id: 62,
    },
    {
      type: "Feature",
      properties: {
        id: [189],
        name: "",
        address: "1 rue d'Arras",
      },
      geometry: {
        coordinates: [
          [
            [3.068233611073765, 50.62407847734457],
            [3.06739358103448, 50.62247969058765],
            [3.067639250196322, 50.62237913614487],
            [3.0681226637100565, 50.62265566034563],
            [3.0691132651719215, 50.62365113400574],
            [3.068479280235607, 50.623987981431924],
            [3.068233611073765, 50.62407847734457],
          ],
        ],
        type: "Polygon",
      },
      id: 63,
    },
    {
      type: "Feature",
      properties: {
        id: [94],
        name: "",
        address: "2 Boulevard Victor Hugo",
      },
      geometry: {
        coordinates: [
          [
            [3.064247756318707, 50.62304441446949],
            [3.0642239818828045, 50.622757837181524],
            [3.0658247938450813, 50.62269247720445],
            [3.0659119667729158, 50.62308966335556],
            [3.0658564930910757, 50.62358739822602],
            [3.064247756318707, 50.62304441446949],
          ],
        ],
        type: "Polygon",
      },
      id: 64,
    },
    {
      type: "Feature",
      properties: {
        id: [95],
        name: "",
        address: "3 Boulevard Victor Hugo",
      },
      geometry: {
        coordinates: [
          [
            [3.0627839266726085, 50.623390781887],
            [3.0627601522381553, 50.62281762978628],
            [3.0630216710244156, 50.62281260210503],
            [3.0641390694729864, 50.62318464904092],
            [3.064154919095955, 50.6233354780415],
            [3.0627839266726085, 50.623390781887],
          ],
        ],
        type: "Polygon",
      },
      id: 65,
    },
    {
      type: "Feature",
      properties: {
        id: [176],
        name: "",
        address: "1 rue d'Artois",
      },
      geometry: {
        coordinates: [
          [
            [3.061334113974169, 50.624235550369434],
            [3.061334113974169, 50.62286301490852],
            [3.0626100086567476, 50.62281776580443],
            [3.0627685048908404, 50.624260688008206],
            [3.061334113974169, 50.624235550369434],
          ],
        ],
        type: "Polygon",
      },
      id: 66,
    },
    {
      type: "Feature",
      properties: {
        id: [99, 177],
        name: "",
        address: "4 Boulevard Victor Hugo",
      },
      geometry: {
        coordinates: [
          [
            [3.0593964909165265, 50.622871656826874],
            [3.06000670141691, 50.62180577767484],
            [3.0611795735473493, 50.62219291582116],
            [3.0611795735473493, 50.622801269326004],
            [3.0593964909165265, 50.622871656826874],
          ],
        ],
        type: "Polygon",
      },
      id: 67,
    },
    {
      type: "Feature",
      properties: {
        id: [100],
        name: "",
        address: "5 Boulevard Victor Hugo",
      },
      geometry: {
        coordinates: [
          [
            [3.058882319678446, 50.621233253764586],
            [3.059859807802326, 50.619557468163066],
            [3.061128462601374, 50.61956406586168],
            [3.061138861410967, 50.61965643353864],
            [3.0600053911398675, 50.621615905094785],
            [3.058882319678446, 50.621233253764586],
          ],
        ],
        type: "Polygon",
      },
      id: 68,
    },
    {
      type: "Feature",
      properties: {
        id: [101],
        name: "",
        address: "6 Boulevard Victor Hugo",
      },
      geometry: {
        coordinates: [
          [
            [3.056468847333832, 50.620426236545654],
            [3.0579983757690457, 50.619561291747345],
            [3.059719095259794, 50.619561291747345],
            [3.0587880779511067, 50.62120150891232],
            [3.056468847333832, 50.620426236545654],
          ],
        ],
        type: "Polygon",
      },
      id: 69,
    },
    {
      type: "Feature",
      properties: {
        id: [103],
        name: "",
        address: "7 Boulevard Victor Hugo",
      },
      geometry: {
        coordinates: [
          [
            [3.052960497097388, 50.61991803157699],
            [3.053492506987851, 50.619601586784],
            [3.0541741446592425, 50.61983364650686],
            [3.05369201069621, 50.6202081041003],
            [3.052960497097388, 50.61991803157699],
          ],
        ],
        type: "Polygon",
      },
      id: 70,
    },
    {
      type: "Feature",
      properties: {
        id: [180],
        name: "",
        address: "1 rue des Postes",
      },
      geometry: {
        coordinates: [
          [
            [3.051895867718457, 50.62053020499502],
            [3.0528684482987956, 50.61997643241824],
            [3.053616587207756, 50.62027705262648],
            [3.0524694408820494, 50.62123691481253],
            [3.051895867718457, 50.62053020499502],
          ],
        ],
        type: "Polygon",
      },
      id: 71,
    },
    {
      type: "Feature",
      properties: {
        id: [105, 178],
        name: "",
        address: "8 Boulevard Victor Hugo",
      },
      geometry: {
        coordinates: [
          [
            [3.0511910618043885, 50.61966337606711],
            [3.050393046967997, 50.61866656236148],
            [3.0505260494402364, 50.6185769008342],
            [3.0506174886408246, 50.61858744925783],
            [3.0524462726393438, 50.619246721056555],
            [3.0511910618043885, 50.61966337606711],
          ],
        ],
        type: "Polygon",
      },
      id: 72,
    },
    {
      type: "Feature",
      properties: {
        id: [104, 200],
        name: "",
        address: "4 Boulevard de Strasbourg",
      },
      geometry: {
        coordinates: [
          [
            [3.053718105268814, 50.61948933008381],
            [3.0512243089076208, 50.618661287490426],
            [3.0506839863628556, 50.618413399292876],
            [3.0506424230897267, 50.61835010848],
            [3.0507089243258463, 50.61830791455699],
            [3.054075549413909, 50.61817078404707],
            [3.0542667404693873, 50.61947350775043],
            [3.053718105268814, 50.61948933008381],
          ],
        ],
        type: "Polygon",
      },
      id: 73,
    },
    {
      type: "Feature",
      properties: {
        id: [199],
        name: "",
        address: "3 Boulevard de Strasbourg",
      },
      geometry: {
        coordinates: [
          [
            [3.054383523325413, 50.61948497154327],
            [3.054192332271384, 50.61816642538432],
            [3.0548407193253695, 50.61810840850438],
            [3.0564949375785204, 50.61800819736095],
            [3.057076823396443, 50.6179976488074],
            [3.057276327104802, 50.61949024565354],
            [3.054383523325413, 50.61948497154327],
          ],
        ],
        type: "Polygon",
      },
      id: 74,
    },
    {
      type: "Feature",
      properties: {
        id: [197],
        name: "",
        address: "2 Boulevard de Strasbourg",
      },
      geometry: {
        coordinates: [
          [
            [3.059299394627857, 50.61874835751581],
            [3.0581771862651976, 50.61792557616289],
            [3.059232893391737, 50.617878107568544],
            [3.0592495187003976, 50.61792557616289],
            [3.05990621840877, 50.61840553370146],
            [3.059299394627857, 50.61874835751581],
          ],
        ],
        type: "Polygon",
      },
      id: 75,
    },
    {
      type: "Feature",
      properties: {
        id: [196],
        name: "",
        address: "1 Boulevard de Strasbourg",
      },
      geometry: {
        coordinates: [
          [
            [3.0599976576093297, 50.61836333982819],
            [3.0593658958639764, 50.61790447901592],
            [3.0593409578995647, 50.61786228469313],
            [3.060670982626391, 50.61778317023658],
            [3.0608704863362277, 50.617788444537695],
            [3.0609536128810078, 50.617835913222564],
            [3.0599976576093297, 50.61836333982819],
          ],
        ],
        type: "Polygon",
      },
      id: 76,
    },
    {
      type: "Feature",
      properties: {
        id: [191],
        name: "",
        address: "2 rue d'Arras",
      },
      geometry: {
        coordinates: [
          [
            [3.066554098144792, 50.621497756957154],
            [3.0657682302945943, 50.620613351304144],
            [3.066034864029376, 50.6205391554426],
            [3.06611906415651, 50.62053321976876],
            [3.067190276880268, 50.620577737305155],
            [3.0671341434621695, 50.62073800008761],
            [3.067017198842109, 50.62099620008797],
            [3.0668628319427285, 50.62127814099796],
            [3.066554098144792, 50.621497756957154],
          ],
        ],
        type: "Polygon",
      },
      id: 77,
    },
    {
      type: "Feature",
      properties: {
        id: [192],
        name: "",
        address: "3 rue d'Arras",
      },
      geometry: {
        coordinates: [
          [
            [3.065722896153318, 50.6205693875055],
            [3.0648294392516107, 50.619539537524474],
            [3.067037353687482, 50.61956921651364],
            [3.0671355871687638, 50.620527837798704],
            [3.066022274381851, 50.6204833202151],
            [3.065722896153318, 50.6205693875055],
          ],
        ],
        type: "Polygon",
      },
      id: 78,
    },
    {
      type: "Feature",
      properties: {
        id: [194, 260],
        name: "",
        address: "5 rue d'Arras",
      },
      geometry: {
        coordinates: [
          [
            [3.064782768844907, 50.61948053717887],
            [3.0640414943779035, 50.6184998706853],
            [3.0636471994485532, 50.61774934680054],
            [3.0668094447832743, 50.61778937504326],
            [3.067006592247253, 50.619505553914024],
            [3.064782768844907, 50.61948053717887],
          ],
        ],
        type: "Polygon",
      },
      id: 79,
    },
    {
      type: "Feature",
      properties: {
        id: [193],
        name: "",
        address: "4 rue d'Arras",
      },
      geometry: {
        coordinates: [
          [
            [3.0641285245505685, 50.61948203525935],
            [3.0633454593711917, 50.61846442148607],
            [3.063883227024718, 50.6184823795671],
            [3.0639492686663914, 50.61857815588283],
            [3.0646285541241127, 50.619476049360486],
            [3.0641285245505685, 50.61948203525935],
          ],
        ],
        type: "Polygon",
      },
      id: 80,
    },
    {
      type: "Feature",
      properties: {
        id: [181, 183],
        name: "",
        address: "2 rue des Postes",
      },
      geometry: {
        coordinates: [
          [
            [3.05071781150275, 50.623022849679984],
            [3.0517861353272053, 50.6207474374921],
            [3.0518624441718316, 50.62070593959089],
            [3.0534104235905204, 50.622607889100266],
            [3.0535848438071866, 50.622953689837544],
            [3.0535739425429824, 50.62314042117862],
            [3.0534322261179625, 50.623181916931],
            [3.0521567782860757, 50.623098925389],
            [3.051077553198297, 50.623078177480465],
            [3.05071781150275, 50.623022849679984],
          ],
        ],
        type: "Polygon",
      },
      id: 81,
    },
    {
      type: "Feature",
      properties: {
        id: [185],
        name: "",
        address: "1 rue d'Iéna",
      },
      geometry: {
        coordinates: [
          [
            [3.050063736028136, 50.622905278702774],
            [3.0504016751972642, 50.62243498859286],
            [3.049137128629553, 50.62212376461122],
            [3.049431462744735, 50.621535891469875],
            [3.0498675132849655, 50.62085809919765],
            [3.0514154927036827, 50.62125924274241],
            [3.0506087992036157, 50.62300901853476],
            [3.050063736028136, 50.622905278702774],
          ],
        ],
        type: "Polygon",
      },
      id: 82,
    },
    {
      type: "Feature",
      properties: {
        id: [186],
        name: "",
        address: "2 rue d'Iéna",
      },
      geometry: {
        coordinates: [
          [
            [3.048886399568289, 50.622649386137994],
            [3.04910442483893, 50.622206757874295],
            [3.050259958771278, 50.62246956890854],
            [3.0499438221295634, 50.622870698707516],
            [3.048886399568289, 50.622649386137994],
          ],
        ],
        type: "Polygon",
      },
      id: 83,
    },
    {
      type: "Feature",
      properties: {
        id: [188],
        name: "",
        address: "1 Boulevard Montebello",
      },
      geometry: {
        coordinates: [
          [
            [3.0459319920209964, 50.62182637083632],
            [3.0472401436426253, 50.62076127088784],
            [3.04829756620299, 50.621307656342225],
            [3.0472728474333053, 50.62229666703283],
            [3.0459319920209964, 50.62182637083632],
          ],
        ],
        type: "Polygon",
      },
      id: 84,
    },
    {
      type: "Feature",
      properties: {
        id: [113],
        name: "",
        address: "2 Boulevard Montebello",
      },
      geometry: {
        coordinates: [
          [
            [3.0439879718142606, 50.62336056012418],
            [3.04479868592702, 50.62270221372992],
            [3.0464849712826094, 50.62307939247896],
            [3.0455877809971525, 50.62434806243439],
            [3.0439879718142606, 50.62336056012418],
          ],
        ],
        type: "Polygon",
      },
      id: 85,
    },
    {
      type: "Feature",
      properties: {
        id: [114],
        name: "",
        address: "3 Boulevard Montebello",
      },
      geometry: {
        coordinates: [
          [
            [3.0408640229924515, 50.62277765039448],
            [3.041220737201826, 50.62230445898928],
            [3.0437177366701746, 50.62330569894897],
            [3.0431340225091503, 50.62379259560936],
            [3.042820546384746, 50.623586865241094],
            [3.0408640229924515, 50.62277765039448],
          ],
        ],
        type: "Polygon",
      },
      id: 86,
    },
    {
      type: "Feature",
      properties: {
        id: [140, 115, 116],
        name: "",
        address: "4 Boulevard Montebello",
      },
      geometry: {
        coordinates: [
          [
            [3.0413504539091605, 50.62486923625511],
            [3.0414801681668564, 50.62477323106023],
            [3.0414477396021766, 50.62461550781484],
            [3.040572168360171, 50.62421091098264],
            [3.0418044538125457, 50.62324397902864],
            [3.0427773107475673, 50.623676014912206],
            [3.0430583583073485, 50.62385431427822],
            [3.0416315014679753, 50.62500638619363],
            [3.0413504539091605, 50.62486923625511],
          ],
        ],
        type: "Polygon",
      },
      id: 87,
    },
    {
      type: "Feature",
      properties: {
        id: [142],
        name: "",
        address: "1 rue Fulton",
      },
      geometry: {
        coordinates: [
          [
            [3.0373191170140785, 50.62325638346945],
            [3.0356868792665637, 50.6216447864542],
            [3.0358057840030312, 50.621631070497614],
            [3.038248735864215, 50.62228257400423],
            [3.037427212229346, 50.62319466375013],
            [3.0373191170140785, 50.62325638346945],
          ],
        ],
        type: "Polygon",
      },
      id: 88,
    },
    {
      type: "Feature",
      properties: {
        id: [143],
        name: "",
        address: "1 rue d'Isly",
      },
      geometry: {
        coordinates: [
          [
            [3.0343132464786606, 50.624984602568105],
            [3.035037484419547, 50.62427827578364],
            [3.0372750553715377, 50.62337306478102],
            [3.038637055081608, 50.624717159693915],
            [3.0355995795381148, 50.62540290760299],
            [3.035480674801647, 50.62546462442458],
            [3.0343132464786606, 50.624984602568105],
          ],
        ],
        type: "Polygon",
      },
      id: 89,
    },
    {
      type: "Feature",
      properties: {
        id: [145, 119],
        name: "",
        address: "2 rue d'Isly",
      },
      geometry: {
        coordinates: [
          [
            [3.037321291212436, 50.6262600282125],
            [3.037602338771279, 50.625978877904174],
            [3.03912648130364, 50.62520399152396],
            [3.0401425763255077, 50.626164025857264],
            [3.039926385895086, 50.62634917308131],
            [3.039872338287921, 50.62645203233495],
            [3.0391481003470346, 50.62702803999801],
            [3.037321291212436, 50.6262600282125],
          ],
        ],
        type: "Polygon",
      },
      id: 90,
    },
    {
      type: "Feature",
      properties: {
        id: [120],
        name: "",
        address: "1 rue de Turenne",
      },
      geometry: {
        coordinates: [
          [
            [3.0369553862704777, 50.62694764187515],
            [3.0372580528727156, 50.626344204067976],
            [3.0390524334426345, 50.62711221447856],
            [3.0383930526301697, 50.62766078566284],
            [3.0369553862704777, 50.62694764187515],
          ],
        ],
        type: "Polygon",
      },
      id: 91,
    },
    {
      type: "Feature",
      properties: {
        id: [121],
        name: "",
        address: "2 rue de Turenne",
      },
      geometry: {
        coordinates: [
          [
            [3.036539327537213, 50.627486429819754],
            [3.0369192722409935, 50.627013148615646],
            [3.0383285216869353, 50.627723068635675],
            [3.037817323357899, 50.62813499262157],
            [3.036539327537213, 50.627486429819754],
          ],
        ],
        type: "Polygon",
      },
      id: 92,
    },
    {
      type: "Feature",
      properties: {
        id: [122],
        name: "",
        address: "3 rue de Turenne",
      },
      geometry: {
        coordinates: [
          [
            [3.035832633234662, 50.628404715792755],
            [3.035970794945257, 50.6282381947201],
            [3.0359846111159356, 50.62816808040802],
            [3.036488901358382, 50.62753266468519],
            [3.0377599890949227, 50.628185608995835],
            [3.036744500523497, 50.62898753490242],
            [3.0362402102798, 50.62871584569001],
            [3.035832633234662, 50.628404715792755],
          ],
        ],
        type: "Polygon",
      },
      id: 93,
    },
    {
      type: "Feature",
      properties: {
        id: [123, 125],
        name: "",
        address: "4 rue de Turenne",
      },
      geometry: {
        coordinates: [
          [
            [3.036465301572548, 50.62935590344273],
            [3.0384687025199923, 50.63037133746883],
            [3.0366378432808574, 50.63176372331944],
            [3.0352095813614426, 50.63040173934678],
            [3.036465301572548, 50.62935590344273],
          ],
        ],
        type: "Polygon",
      },
      id: 94,
    },
    {
      type: "Feature",
      properties: {
        id: [126],
        name: "",
        address: "1 Boulevard de Lorraine",
      },
      geometry: {
        coordinates: [
          [
            [3.0371219640183256, 50.63218034467482],
            [3.0367001953980832, 50.63182769502123],
            [3.0385598115887547, 50.63041098958129],
            [3.04030439997328, 50.6313047958385],
            [3.038626911142046, 50.63268499388718],
            [3.037390362231406, 50.63213778365014],
            [3.037246577474633, 50.63213170350022],
            [3.0371219640183256, 50.63218034467482],
          ],
        ],
        type: "Polygon",
      },
      id: 95,
    },
    {
      type: "Feature",
      properties: {
        id: [127],
        name: "",
        address: "2 Boulevard de Lorraine",
      },
      geometry: {
        coordinates: [
          [
            [3.0371794778725985, 50.632228985699186],
            [3.037313676979153, 50.63220466514363],
            [3.0406207263905287, 50.63365779625988],
            [3.0402373003721266, 50.63404083170872],
            [3.0393074922763788, 50.63354835698638],
            [3.0391828788208954, 50.633590916733965],
            [3.0389048949567155, 50.63385843426528],
            [3.0371794778725985, 50.632228985699186],
          ],
        ],
        type: "Polygon",
      },
      id: 96,
    },
    {
      type: "Feature",
      properties: {
        id: [128, 134],
        name: "",
        address: "3 Boulevard de Lorraine",
      },
      geometry: {
        coordinates: [
          [
            [3.0401224033521146, 50.63491674288224],
            [3.0398540051389773, 50.6347282690235],
            [3.0389721252960555, 50.633913566876714],
            [3.0392884517611662, 50.633633889749774],
            [3.0407358849818706, 50.63436347876325],
            [3.0401224033521146, 50.63491674288224],
          ],
        ],
        type: "Polygon",
      },
      id: 97,
    },
    {
      type: "Feature",
      properties: {
        id: [135],
        name: "",
        address: "1 rue Colbert",
      },
      geometry: {
        coordinates: [
          [
            [3.040322260350905, 50.634072101177054],
            [3.0407152720203783, 50.63369514593049],
            [3.0411849688933046, 50.633901863698156],
            [3.040782371573613, 50.63430313676895],
            [3.040322260350905, 50.634072101177054],
          ],
        ],
        type: "Polygon",
      },
      id: 98,
    },
    {
      type: "Feature",
      properties: {
        id: [136],
        name: "",
        address: "2 rue Colbert",
      },
      geometry: {
        coordinates: [
          [
            [3.0415543797165014, 50.63366357211751],
            [3.0419186344344666, 50.633323093584664],
            [3.04328938245078, 50.634010128092484],
            [3.042570458666063, 50.63441140023912],
            [3.041870706181612, 50.63382773052939],
            [3.0415543797165014, 50.63366357211751],
          ],
        ],
        type: "Polygon",
      },
      id: 99,
    },
    {
      type: "Feature",
      properties: {
        id: [137],
        name: "",
        address: "3 rue Colbert",
      },
      geometry: {
        coordinates: [
          [
            [3.0419857339877865, 50.63326837359014],
            [3.0427334147241254, 50.63253268748193],
            [3.0444588318081856, 50.633414293433475],
            [3.0433948246058833, 50.633967568724444],
            [3.0419857339877865, 50.63326837359014],
          ],
        ],
        type: "Polygon",
      },
      id: 100,
    },
    {
      type: "Feature",
      properties: {
        id: [139],
        name: "",
        address: "4 rue Colbert",
      },
      geometry: {
        coordinates: [
          [
            [3.0418925707858477, 50.63026594159106],
            [3.0430045062401234, 50.62943900111074],
            [3.043167462298271, 50.62937211558318],
            [3.0432441475017526, 50.62925658581102],
            [3.044595724217629, 50.63058820122194],
            [3.043934314335843, 50.63124487438773],
            [3.0418925707858477, 50.63026594159106],
          ],
        ],
        type: "Polygon",
      },
      id: 101,
    },
    {
      type: "Feature",
      properties: {
        id: [131],
        name: "",
        address: "1 Avenue Léon Jouhaux",
      },
      geometry: {
        coordinates: [
          [
            [3.040189355261589, 50.634992836534224],
            [3.040745378147534, 50.63451254247934],
            [3.041330102825782, 50.633941033858605],
            [3.0414355449807715, 50.633868074811346],
            [3.0416560149419354, 50.633868074811346],
            [3.042010684008858, 50.63405047221738],
            [3.043621072984223, 50.63533930942438],
            [3.043975742051913, 50.6355520996693],
            [3.044570052380408, 50.63585608406285],
            [3.0448192792930797, 50.635922960365434],
            [3.0451164344568724, 50.63602631446432],
            [3.0453081474665566, 50.63615398686079],
            [3.04480010799179, 50.63642756939933],
            [3.044665908885264, 50.6364336489936],
            [3.0410520638031926, 50.63570416539528],
            [3.040189355261589, 50.634992836534224],
          ],
        ],
        type: "Polygon",
      },
      id: 102,
    },
    {
      type: "Feature",
      properties: {
        id: [132],
        name: "",
        address: "1 rue Solférino",
      },
      geometry: {
        coordinates: [
          [
            [3.0453906578451893, 50.63611974714428],
            [3.045131845282299, 50.63596167639213],
            [3.0447675905643337, 50.635846162813465],
            [3.0454385860971627, 50.635444902915054],
            [3.045965796873247, 50.6352807501508],
            [3.046339637241374, 50.63564553329243],
            [3.0453906578451893, 50.63611974714428],
          ],
        ],
        type: "Polygon",
      },
      id: 103,
    },
    {
      type: "Feature",
      properties: {
        id: [13, 130],
        name: "Citadelle",
        address: "Vauban Esquermes",
        type: "park",
      },
      geometry: {
        coordinates: [
          [
            [3.0443154091659324, 50.644325018764704],
            [3.0428646125984926, 50.64297225852815],
            [3.0417685828114145, 50.643146698430485],
            [3.0417976471631505, 50.642432245378046],
            [3.039424605591961, 50.64183620293343],
            [3.0409727363371815, 50.640524966223126],
            [3.040415224430575, 50.63993770643293],
            [3.0414623421761178, 50.63973273480207],
            [3.041593208183201, 50.63813462678411],
            [3.0440080317783895, 50.63866305531889],
            [3.04472820117104, 50.638125730763306],
            [3.0453921994117934, 50.638691028926075],
            [3.047868044662806, 50.63827118705959],
            [3.0478071620053697, 50.6399008641439],
            [3.04882163397653, 50.64018475027484],
            [3.0481917276741513, 50.64074874078074],
            [3.0495596684732504, 50.64213113016601],
            [3.0470757504474193, 50.64260160342516],
            [3.046980266793639, 50.643286545909206],
            [3.0459553022924695, 50.64306953599183],
            [3.0443154091659324, 50.644325018764704],
          ],
        ],
        type: "Polygon",
      },
      id: 104,
    },
    {
      type: "Feature",
      properties: {
        id: [201],
        name: "Square Julien Grimonprez",
        address: "Avenue du Peuple Belge",
        type: "park",
      },
      geometry: {
        coordinates: [
          [
            [3.0609833095646763, 50.644817899552635],
            [3.061139517715219, 50.64459692878273],
            [3.061848462396057, 50.64385019228567],
            [3.0620647506049465, 50.6439797290592],
            [3.0621248306622704, 50.64497029258138],
            [3.0619926545353167, 50.6450998262674],
            [3.0618003983499875, 50.64513030472975],
            [3.0609833095646763, 50.644817899552635],
          ],
        ],
        type: "Polygon",
      },
      id: 105,
    },
    {
      type: "Feature",
      properties: {
        id: [23],
        name: "Square Foch",
        address: "Rue Nationale",
        type: "park",
      },
      geometry: {
        coordinates: [
          [
            [3.0566343965987812, 50.63619139148602],
            [3.0561873523108716, 50.635899980237696],
            [3.057671290989674, 50.63499816911727],
            [3.0581990516075166, 50.635230515121776],
            [3.0566343965987812, 50.63619139148602],
          ],
        ],
        type: "Polygon",
      },
      id: 106,
    },
    {
      type: "Feature",
      properties: {
        id: [78, 238],
        name: "Palais des Beaux-Arts",
        address: "Place de la République",
        type: "park",
      },
      geometry: {
        coordinates: [
          [
            [3.060790985180091, 50.63219561032031],
            [3.059879736557292, 50.631517702394234],
            [3.063569730980362, 50.629476783726915],
            [3.0644697296195034, 50.63015472108344],
            [3.060790985180091, 50.63219561032031],
          ],
        ],
        type: "Polygon",
      },
      id: 107,
    },
    {
      type: "Feature",
      properties: {
        id: [28],
        name: "Vieille Bourse",
        address: "Place du Générale De Gaulle",
      },
      geometry: {
        coordinates: [
          [
            [3.0639096583433343, 50.63706646613534],
            [3.064201354125373, 50.636813008655],
            [3.0646501857696364, 50.63702580174029],
            [3.0643629338651692, 50.637279258073136],
            [3.0639096583433343, 50.63706646613534],
          ],
        ],
        type: "Polygon",
      },
      id: 108,
    },
    {
      type: "Feature",
      properties: {
        id: [216],
        name: "",
        address: "3 rue Pierre Mauroy",
      },
      geometry: {
        coordinates: [
          [
            [3.0666803349283214, 50.63319376638802],
            [3.0662072801539466, 50.63177654233908],
            [3.0661861616369777, 50.6317015273992],
            [3.066405794211107, 50.63169616918469],
            [3.0680994992515878, 50.63204713094916],
            [3.068192420724955, 50.63204981003648],
            [3.0681881970211293, 50.63212750350354],
            [3.068040367404535, 50.63262581027402],
            [3.067968564448165, 50.633365223489164],
            [3.0666803349283214, 50.63319376638802],
          ],
        ],
        type: "Polygon",
      },
      id: 109,
    },
    {
      type: "Feature",
      properties: {
        id: [241],
        name: "",
        address: "4 rue Pierre Mauroy",
      },
      geometry: {
        coordinates: [
          [
            [3.068162885847329, 50.63196424196522],
            [3.066659247457295, 50.63162667514064],
            [3.065624440137867, 50.63087919711754],
            [3.0661101660226393, 50.63055501836638],
            [3.0665789970932167, 50.63081221822014],
            [3.0667183793032677, 50.63085508472537],
            [3.068399413234488, 50.63101583377309],
            [3.068162885847329, 50.63196424196522],
          ],
        ],
        type: "Polygon",
      },
      id: 110,
    },
    {
      type: "Feature",
      properties: {
        id: [242],
        name: "",
        address: "5 rue Pierre Mauroy",
      },
      geometry: {
        coordinates: [
          [
            [3.066169866840255, 50.63050928348869],
            [3.067513004503269, 50.62957156285921],
            [3.0683197318409157, 50.6296465811979],
            [3.0683408503579415, 50.62973231629533],
            [3.0684168770181373, 50.62982608887944],
            [3.068581601448926, 50.62990646523107],
            [3.0686027199659236, 50.62993861573315],
            [3.0686576281091504, 50.630021671095676],
            [3.0684168770181373, 50.63095670359789],
            [3.0667273956814256, 50.630790596030266],
            [3.066630250504204, 50.63076380443218],
            [3.066169866840255, 50.63050928348869],
          ],
        ],
        type: "Polygon",
      },
      id: 111,
    },
    {
      type: "Feature",
      properties: {
        id: [245, 235],
        name: "",
        address: "6 Boulevard de la Liberté",
      },
      geometry: {
        coordinates: [
          [
            [3.065774946904952, 50.62962826882077],
            [3.0667675171904705, 50.62906562814362],
            [3.06742641491158, 50.629547891993525],
            [3.0665309898032262, 50.63018286518397],
            [3.065774946904952, 50.62962826882077],
          ],
        ],
        type: "Polygon",
      },
      id: 112,
    },
    {
      type: "Feature",
      properties: {
        id: [237],
        name: "",
        address: "6 Boulevard de la Liberté",
      },
      geometry: {
        coordinates: [
          [
            [3.063817821745914, 50.63071257814596],
            [3.0646329964901895, 50.630257118145806],
            [3.065481960862968, 50.630881365142784],
            [3.064734365371237, 50.63139040162329],
            [3.063817821745914, 50.63071257814596],
          ],
        ],
        type: "Polygon",
      },
      id: 113,
    },
    {
      type: "Feature",
      properties: {
        id: [239],
        name: "",
        address: "5 Boulevard de la Liberté",
      },
      geometry: {
        coordinates: [
          [
            [3.0620359907865122, 50.63170526173249],
            [3.0625892959238286, 50.631391805177856],
            [3.0631088114349154, 50.63178027666646],
            [3.062601967034226, 50.63208837246137],
            [3.062492150746664, 50.632034790729136],
            [3.0620359907865122, 50.63170526173249],
          ],
        ],
        type: "Polygon",
      },
      id: 114,
    },
    {
      type: "Feature",
      properties: {
        id: [219],
        name: "",
        address: "1 avenue du Président John F. Kennedy",
      },
      geometry: {
        coordinates: [
          [
            [3.0708651851734032, 50.632553158183356],
            [3.071133256413674, 50.63212805099104],
            [3.0715330468595994, 50.63161030795493],
            [3.0725719622014935, 50.631853054894435],
            [3.0726961690017163, 50.631870288995316],
            [3.072779620445516, 50.631866595974145],
            [3.0736531962813842, 50.63178603432212],
            [3.0736376704316513, 50.631818040550115],
            [3.0735736262995204, 50.63190544206097],
            [3.0735367524060564, 50.63198545738629],
            [3.0735154043624107, 50.63208763060376],
            [3.073527569878763, 50.63233079240237],
            [3.073506221835089, 50.632366491241356],
            [3.072605998361553, 50.632377553023275],
            [3.0725516578863505, 50.63239355593845],
            [3.072499258142102, 50.63241940679012],
            [3.072262488930562, 50.632852714186214],
            [3.0708651851734032, 50.632553158183356],
          ],
        ],
        type: "Polygon",
      },
      id: 115,
    },
    {
      type: "Feature",
      properties: {
        id: [220],
        name: "",
        address: "2 avenue du Président John F. Kennedy",
      },
      geometry: {
        coordinates: [
          [
            [3.0723274768444355, 50.632866082873534],
            [3.072562305326045, 50.63244385452961],
            [3.072585594100673, 50.632427851631576],
            [3.0726205272631546, 50.63242169666921],
            [3.0735016192503224, 50.63241431071353],
            [3.0735501375316403, 50.632404462770864],
            [3.074268208093116, 50.63240323177766],
            [3.074337252627913, 50.63330222065733],
            [3.0723274768444355, 50.632866082873534],
          ],
        ],
        type: "Polygon",
      },
      id: 116,
    },
    {
      type: "Feature",
      properties: {
        id: [222],
        name: "",
        address: "1 rue Paul Duez",
      },
      geometry: {
        coordinates: [
          [
            [3.0749001681980133, 50.63330292126943],
            [3.0750881410685906, 50.63282175267534],
            [3.0754066303750847, 50.632001447404434],
            [3.07646156871985, 50.63218392811493],
            [3.0761608748297533, 50.63281933389277],
            [3.076115475804727, 50.632927740274226],
            [3.07607277971735, 50.6330656097517],
            [3.0760471403663416, 50.633205674581916],
            [3.075827837735517, 50.63322783207278],
            [3.0749001681980133, 50.63330292126943],
          ],
        ],
        type: "Polygon",
      },
      id: 117,
    },
    {
      type: "Feature",
      properties: {
        id: [224],
        name: "",
        address: "2 rue Paul Duez",
      },
      geometry: {
        coordinates: [
          [
            [3.07542877285681, 50.63195389180501],
            [3.0757923857437106, 50.63103132492057],
            [3.0758518860334334, 50.63093906723654],
            [3.076979085982657, 50.631159227319756],
            [3.076486555800102, 50.63213420958047],
            [3.07542877285681, 50.63195389180501],
          ],
        ],
        type: "Polygon",
      },
      id: 118,
    },
    {
      type: "Feature",
      properties: {
        id: [226],
        name: "",
        address: "Avenue Eugène Varlin",
      },
      geometry: {
        coordinates: [
          [
            [3.074434291685094, 50.630621387210084],
            [3.0744276805418167, 50.63056267728393],
            [3.0749334330110116, 50.6298937983035],
            [3.0750292945901947, 50.6297386337946],
            [3.0752177121773627, 50.629499595576135],
            [3.075336712758542, 50.62953943536388],
            [3.0754557133397498, 50.629606533876625],
            [3.0759416323790276, 50.63071993585024],
            [3.0758127150833445, 50.630879290661085],
            [3.074434291685094, 50.630621387210084],
          ],
        ],
        type: "Polygon",
      },
      id: 119,
    },
    {
      type: "Feature",
      properties: {
        id: [228],
        name: "",
        address: "1 Boulevard Louis XIV",
      },
      geometry: {
        coordinates: [
          [
            [3.0737473426199244, 50.62876601244045],
            [3.0738456123667106, 50.627952217584635],
            [3.073915665752054, 50.62742770422324],
            [3.0761088692581495, 50.62740261552949],
            [3.076187968400802, 50.627418581062926],
            [3.076285044621585, 50.627455073691664],
            [3.076317403361884, 50.62748472393153],
            [3.0762958308677355, 50.62754858592359],
            [3.0762383042182933, 50.627619290170315],
            [3.075824831426843, 50.62816439353571],
            [3.0757751570095877, 50.62825056580709],
            [3.075757179931429, 50.62851513204211],
            [3.075494714593333, 50.628859522065056],
            [3.0754551650219923, 50.62901233007523],
            [3.0737473426199244, 50.62876601244045],
          ],
        ],
        type: "Polygon",
      },
      id: 120,
    },
    {
      type: "Feature",
      properties: {
        id: [229],
        name: "",
        address: "2 Boulevard Louis XIV",
      },
      geometry: {
        coordinates: [
          [
            [3.070321029027525, 50.62826829037979],
            [3.070609563507759, 50.62745259893998],
            [3.073802260254922, 50.62744464090514],
            [3.0736579930144217, 50.62874576172891],
            [3.070321029027525, 50.62826829037979],
          ],
        ],
        type: "Polygon",
      },
      id: 121,
    },
    {
      type: "Feature",
      properties: {
        id: [230],
        name: "",
        address: "3 Boulevard Louis XIV",
      },
      geometry: {
        coordinates: [
          [
            [3.0698985707332156, 50.62971538924461],
            [3.069917388199002, 50.62836256906104],
            [3.0700052030412053, 50.62838246375725],
            [3.073611884042492, 50.62889972291103],
            [3.072526743498031, 50.630236612515205],
            [3.0698985707332156, 50.62971538924461],
          ],
        ],
        type: "Polygon",
      },
      id: 122,
    },
    {
      type: "Feature",
      properties: {
        id: [232],
        name: "Parc Jean-Baptiste Lebas",
        address: "Boulevard de la Liberté",
        type: "park",
      },
      geometry: {
        coordinates: [
          [
            [3.068456397240112, 50.6281209475311],
            [3.068549027751061, 50.624944745669154],
            [3.068616423889182, 50.62453143868234],
            [3.0696273659519875, 50.624449489451365],
            [3.0695291190970693, 50.62815301213459],
            [3.068456397240112, 50.6281209475311],
          ],
        ],
        type: "Polygon",
      },
      id: 123,
    },
    {
      type: "Feature",
      properties: {
        id: [246],
        name: "",
        address: "1 rue de Fontenoy",
      },
      geometry: {
        coordinates: [
          [
            [3.0677098963236062, 50.6223286342144],
            [3.068549494935695, 50.621501294669486],
            [3.068714735087582, 50.621268956974035],
            [3.0696659824515393, 50.62173929794773],
            [3.06843338023333, 50.62286413047539],
            [3.0681966848802915, 50.62261763295405],
            [3.0677098963236062, 50.6223286342144],
          ],
        ],
        type: "Polygon",
      },
      id: 124,
    },
    {
      type: "Feature",
      properties: {
        id: [248],
        name: "",
        address: "1 rue de la Plaine",
      },
      geometry: {
        coordinates: [
          [
            [3.070040088581692, 50.62184241199853],
            [3.0706495759626193, 50.62132683495204],
            [3.0719950481051796, 50.6219445539503],
            [3.0720372138987386, 50.62195428174351],
            [3.071987381597353, 50.6220466956799],
            [3.0719222162803135, 50.62214154137345],
            [3.0718302181843455, 50.622236386875386],
            [3.0717612196129096, 50.622280161657955],
            [3.071370227708627, 50.622489307278755],
            [3.070040088581692, 50.62184241199853],
          ],
        ],
        type: "Polygon",
      },
      id: 125,
    },
    {
      type: "Feature",
      properties: {
        id: [250, 262],
        name: "",
        address: "1 rue de Douai",
      },
      geometry: {
        coordinates: [
          [
            [3.0719666579051648, 50.622241247783734],
            [3.0721065515037935, 50.622073603370836],
            [3.0726272665648366, 50.621028277670035],
            [3.0740495181504173, 50.62143260451762],
            [3.0730391754941877, 50.62255188143908],
            [3.0719666579051648, 50.622241247783734],
          ],
        ],
        type: "Polygon",
      },
      id: 126,
    },
    {
      type: "Feature",
      properties: {
        id: [252],
        name: "",
        address: "2 rue de Douai",
      },
      geometry: {
        coordinates: [
          [
            [3.0695514305127745, 50.620084876281425],
            [3.07024409888524, 50.61911205863137],
            [3.0727457749490554, 50.6202384772283],
            [3.07281974924112, 50.620242743914645],
            [3.0725373019440383, 50.62092114203634],
            [3.0695514305127745, 50.620084876281425],
          ],
        ],
        type: "Polygon",
      },
      id: 127,
    },
    {
      type: "Feature",
      properties: {
        id: [254, 264],
        name: "",
        address: "3 rue de Douai",
      },
      geometry: {
        coordinates: [
          [
            [3.0733995031856693, 50.61898251309191],
            [3.073574351512889, 50.61838515730415],
            [3.073702125290339, 50.61839369101153],
            [3.0739576728451254, 50.6184363595259],
            [3.074926063579966, 50.61866250200629],
            [3.0745023926335193, 50.61928118814038],
            [3.0733995031856693, 50.61898251309191],
          ],
        ],
        type: "Polygon",
      },
      id: 128,
    },
    {
      type: "Feature",
      properties: {
        id: [257],
        name: "",
        address: "1 Boulevard d'Alsace",
      },
      geometry: {
        coordinates: [
          [
            [3.070322122449795, 50.6190669149359],
            [3.0716402098381934, 50.61845675967851],
            [3.0717478088089365, 50.61818368063621],
            [3.072467376923669, 50.61826901800731],
            [3.072413577438283, 50.618520762350016],
            [3.072783448899372, 50.6189133101621],
            [3.0732541943956733, 50.619024246993575],
            [3.072837248384758, 50.6201890679238],
            [3.070322122449795, 50.6190669149359],
          ],
        ],
        type: "Polygon",
      },
      id: 129,
    },
    {
      type: "Feature",
      properties: {
        id: [258],
        name: "",
        address: "2 Boulevard d'Alsace",
      },
      geometry: {
        coordinates: [
          [
            [3.0679972070928443, 50.619670569957776],
            [3.069281669802592, 50.61785717154558],
            [3.069933988561644, 50.61791264123988],
            [3.0713663998564016, 50.61807904992918],
            [3.071453824019528, 50.6181046512134],
            [3.0716353972823356, 50.61816012061584],
            [3.0715614229902712, 50.618407598689544],
            [3.0703105849583494, 50.61898788699722],
            [3.0701962610521036, 50.61904762215215],
            [3.0701357366311015, 50.619098823652735],
            [3.069449793194252, 50.620054574768574],
            [3.068077906320468, 50.619670569957776],
            [3.0679972070928443, 50.619670569957776],
          ],
        ],
        type: "Polygon",
      },
      id: 130,
    },
    {
      type: "Feature",
      properties: {
        id: [263],
        name: "",
        address: "1 rue de Valenciennes",
      },
      geometry: {
        coordinates: [
          [
            [3.0712806131950856, 50.62424080869437],
            [3.0721148348903284, 50.62344695221532],
            [3.072251796660794, 50.62338375908428],
            [3.0730860183550988, 50.62263728379284],
            [3.074617499974181, 50.623087540148845],
            [3.0738953379096756, 50.624090727402404],
            [3.0712806131950856, 50.62424080869437],
          ],
        ],
        type: "Polygon",
      },
      id: 131,
    },
    {
      type: "Feature",
      properties: {
        id: [266],
        name: "",
        address: "2 rue de Valenciennes",
      },
      geometry: {
        coordinates: [
          [
            [3.0750981771014096, 50.62402786830572],
            [3.0751044026363843, 50.62398047405023],
            [3.075465483668239, 50.6233327477768],
            [3.0770778972415656, 50.6238145937815],
            [3.0771027993821747, 50.6239212311645],
            [3.0750981771014096, 50.62402786830572],
          ],
        ],
        type: "Polygon",
      },
      id: 132,
    },
    {
      type: "Feature",
      properties: {
        id: [268],
        name: "",
        address: "1 rue de Cambrai",
      },
      geometry: {
        coordinates: [
          [
            [3.0777737360564004, 50.623900184571],
            [3.077779961591318, 50.623805395755966],
            [3.078726242916332, 50.62275480692443],
            [3.079859290293058, 50.623078675157046],
            [3.0799838009936593, 50.62305892715767],
            [3.080108311694204, 50.62322875967911],
            [3.0802141457899666, 50.62348153252847],
            [3.0802390479297515, 50.623603968888716],
            [3.0802328223948052, 50.62387253785283],
            [3.0794795326555686, 50.62390413410077],
            [3.0777737360564004, 50.623900184571],
          ],
        ],
        type: "Polygon",
      },
      id: 133,
    },
    {
      type: "Feature",
      properties: {
        id: [270],
        name: "",
        address: "1 Boulevard de Belfort",
      },
      geometry: {
        coordinates: [
          [
            [3.0804082949734664, 50.62314499646274],
            [3.0802153033876607, 50.622880372959116],
            [3.0798978011004863, 50.622576251571104],
            [3.0795802988140792, 50.62226817854943],
            [3.079493141323155, 50.62214968838782],
            [3.079437111507815, 50.62201934886596],
            [3.0794246604379225, 50.62190085807791],
            [3.0810432995470762, 50.6220746444647],
            [3.080918788846475, 50.62261969760402],
            [3.081055750616912, 50.62314104686919],
            [3.0804830013936453, 50.62309365172047],
            [3.0804082949734664, 50.62314499646274],
          ],
        ],
        type: "Polygon",
      },
      id: 134,
    },
    {
      type: "Feature",
      properties: {
        id: [272],
        name: "",
        address: "2 Boulevard de Belfort",
      },
      geometry: {
        coordinates: [
          [
            [3.0757886060000317, 50.61965418510752],
            [3.076373806293219, 50.619014299124416],
            [3.076479640388925, 50.6190458986367],
            [3.07684072142078, 50.61916834654559],
            [3.0771333215673735, 50.61930264388599],
            [3.077463274924469, 50.619500139277676],
            [3.0778866113065817, 50.61983192966903],
            [3.0781356327085234, 50.620088670627325],
            [3.0782912710838843, 50.62030986171365],
            [3.078552743555747, 50.62088258379964],
            [3.0775006281349704, 50.62106822325475],
            [3.0774445983196586, 50.62106032371801],
            [3.0757886060000317, 50.61965418510752],
          ],
        ],
        type: "Polygon",
      },
      id: 135,
    },
  ],
}
