/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-new */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import firebase from "firebase/compat/app"
import anime from "animejs/lib/anime.es.js"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"

import useSound from "use-sound"
import Lottie from "lottie-react"
import { toast } from "react-hot-toast"
// eslint-disable-next-line import/no-cycle
import { styled } from "@mui/material"
import { changePdp, endTurn } from "../online/user"
import { COLORS, PRICE, SIZE } from "../export/const"
import { ReactComponent as Money } from "../assets/svg/money.svg"

import { ReactComponent as Reputation } from "../assets/svg/reputation.svg"
import { ReactComponent as FLag } from "../assets/svg/flag-checkered.svg"
import { ReactComponent as House } from "../assets/svg/house.svg"
import { ReactComponent as Hotel } from "../assets/svg/hotel.svg"
import { ReactComponent as Question } from "../assets/svg/question.svg"
import { ReactComponent as Crosshairs } from "../assets/svg/crosshairs.svg"
import { ReactComponent as Map } from "../assets/svg/map.svg"

import { ReactComponent as Pen } from "../assets/svg/image.svg"
import start from "../assets/images/starting.png"
import MultiDice from "../comps/dice/multiDice"
import Clock from "../comps/clock"
import Regles from "../comps/regles/Regles"
import BuyCard from "../comps/buyCards/BuyCard"
import GameOver from "../comps/gameOver/GameOver"
import LoseCard from "../comps/loseCard/LoseCard"
import { logout } from "../online/auth"
import moneyMinus from "../assets/sounds/moneyMinus.wav"
import moneyPlus from "../assets/sounds/moneyPlus.wav"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import SmallHouseCard from "../comps/cards/smallHouseCard"
import Button from "../comps/button"
import ChanceCard from "../comps/flipCard/chanceCard"
import diceAnim from "../assets/lottie/dice2.json"
import clock from "../assets/lottie/clock.json"
import { getObjectPlayer, setListenUser } from "../export/listener"

const Stats = forwardRef((props, ref) => {
  const {
    setMapCenter,
    setZoom,
    setHouseId,
    setHouse,
    houseData,
    launchRound,
    newMoney,
    setTurnPlayed,
    turnPlayed,
    setActionPlayed,
    setNewMoney,
    playerData,
    newReputation,
    setNewReputation,
    globalData,
    dice,
    dicex2,
    dicePlus2,
    diceMinus2,
    userMoney,
    setUserMoney,
    setNewSinglePlayerPos,
    setPosCam,
    playersData,
    endGame,
    loseGame,
    playerPos,
  } = props

  const lottie = useRef()

  const styles = {
    overlay: {
      color: COLORS.white,
      fontFamily: "SFProBold",
      display: "flex",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: COLORS.blueLUck,
    },
    personalData: {
      fontSize: 17.5,
      margin: 10,
      position: "absolute",
      backgroundColor: COLORS.white,
      color: COLORS.black,
      borderRadius: 40,
      padding: 15,
      right: 0,
      top: 0,
      zIndex: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
    },
    menu: {
      position: "absolute",
      zIndex: 100,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      color: COLORS.black,
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      gap: 30,
    },
    houseData: {
      zIndex: 10,
      position: "absolute",
      bottom: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      left: "50%",
      width: "75%",
      transform: "translate(-50%, -0%)",
    },
    globalData: {
      fontSize: 17.5,
      position: "absolute",
      backgroundColor: COLORS.white,
      color: COLORS.black,
      borderRadius: 40,
      padding: 15,
      top: 0,
      margin: 10,
      left: "50%",
      transform: "translate(-50%,0)",
      zIndex: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
    },
    dot: {
      borderRadius: 1000,
      width: 5,
      height: 5,
      backgroundColor: COLORS.black,
      margin: 10,
    },
    signout: {
      position: "absolute",
      left: 0,
      top: 0,
      cursor: "pointer",
      zIndex: 10,
      margin: 10,
      padding: 5,
      backgroundColor: COLORS.white,
      borderRadius: 1000,
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
    },
    focus: {
      position: "absolute",
      left: 0,
      top: 70,
      cursor: "pointer",
      zIndex: 10,
      margin: 10,
      padding: 5,
      backgroundColor: COLORS.white,
      borderRadius: 1000,
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
      height: 50,
      width: 50,
    },
    dezoom: {
      position: "absolute",
      left: 0,
      top: 140,
      cursor: "pointer",
      zIndex: 10,
      margin: 10,
      padding: 5,
      backgroundColor: COLORS.white,
      borderRadius: 1000,
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
      height: 50,
      width: 50,
    },
    tour: {
      position: "absolute",
      left: 0,
      bottom: 0,
      cursor: turnPlayed ? "not-allowed" : "pointer",
      zIndex: 10,
      margin: 10,
      padding: 5,
      opacity: turnPlayed ? 0.5 : 1,
      backgroundColor: COLORS.white,
      borderRadius: 1000,
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
    },
    help: {
      position: "absolute",
      right: 0,
      bottom: 0,
      cursor: "pointer",
      zIndex: 10,
      margin: 10,
      padding: 5,
      backgroundColor: COLORS.white,
      borderRadius: 1000,
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
    },
  }

  const [userReputation, setReputation] = useState(0)

  const [visible, setVisible] = useState(false)
  const [profilVisible, setProfilVisible] = useState(false)
  const [houses, setHouses] = useState([])
  const [playerName, setPlayerName] = useState("?")

  const [reputationColor, setReputationColor] = useState(COLORS.black)
  const [moneyColor, setMoneyColor] = useState(COLORS.black)

  const [playPlus] = useSound(moneyPlus)
  const [playMinus] = useSound(moneyMinus)

  const refCard = useRef()
  const pdpRef = useRef()
  const multiDiceRef = useRef()

  const refBuyCard = useRef()
  const refGameOver = useRef()
  const refLoseCard = useRef()

  const housesDataFetch = async () => {
    const localUser = firebase.auth().currentUser
    const player = await firebase
      .firestore()
      .collection("players")
      .doc(localUser.uid)
      .get()

    const data = []
    for (const id of player.data().houses) {
      data.push(houseData[id])
    }
    setHouses(data)
  }

  useEffect(() => {
    if (visible === true) {
      anime({
        targets: "#houses",
        translateY: [200, 0],
        // opacity: [0, 1],
        duration: 800,
      })
    } else {
      anime({
        targets: "#houses",
        translateY: [0, 200],
        // opacity: [1, 0],
        duration: 800,
      })
    }
  }, [visible])

  useEffect(() => {
    if (profilVisible === true) {
      anime({
        targets: "#profil",
        scale: [0, 1],
        opacity: [0, 1],
        duration: 200,
        easing: "easeInOutExpo",
      })
    } else {
      anime({
        targets: "#profil",
        scale: [1, 0],
        opacity: [1, 0],
        duration: 200,
        easing: "easeInOutExpo",
      })
    }
  }, [profilVisible])

  useEffect(() => {
    firebase.auth().onAuthStateChanged((authState) => {
      if (authState !== null) {
        setListenUser(
          setNewMoney,
          setNewReputation,
          setPlayerName,
          setTurnPlayed
        )
        setTimeout(() => {
          housesDataFetch()
        }, 2000)
      }
    })
  }, [])

  // #region animation
  const pourcent = {
    cycles: 0,
  }
  let timeout = null
  useEffect(() => {
    const dif = newMoney - userMoney
    dif !== 0 &&
      (dif < 0
        ? toast.error(`Votre compte a été débité de ${PRICE(Math.abs(dif))}`, {
            style: {
              backgroundColor: COLORS.white,
              color: COLORS.black,
              fontFamily: "SFProBold",
              height: 35,
              marginTop: -7,
              fontSize: 20,
              minWidth: 200,
              padding: 20,
              whiteSpace: "nowrap",
              boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
            },
          })
        : toast.success(`Votre compte a été crédité de ${PRICE(dif)}`, {
            style: {
              backgroundColor: COLORS.white,
              color: COLORS.black,
              fontFamily: "SFProBold",
              height: 35,
              marginTop: -7,
              fontSize: 20,
              minWidth: 200,
              padding: 20,
              whiteSpace: "nowrap",
              boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
            },
          }))
    const old = userMoney
    if (newMoney !== userMoney) {
      timeout && clearTimeout(timeout)
      setMoneyColor(dif < 0 ? COLORS.red : COLORS.green)
      dif !== 0 && (dif > 0 ? playPlus() : playMinus())
      setTimeout(() => {
        anime({
          targets: pourcent,
          cycles: [0, 100],
          round: 1,
          easing: "linear",
          update() {
            const add = dif * (pourcent.cycles / 100)
            setUserMoney(old + add)
          },
          duration: 2000,
        })
        timeout = setTimeout(() => {
          setMoneyColor(COLORS.black)
        }, 2000)
      }, 100)
    }
  }, [newMoney])

  const pourcent2 = {
    cycles: 0,
  }
  let timeout2 = null
  useEffect(() => {
    const dif = newReputation - userReputation
    dif !== 0 &&
      (dif < 0
        ? toast.error(`Vous avez ${dif} de réputation en moins`, {
            style: {
              backgroundColor: COLORS.white,
              color: COLORS.black,
              fontFamily: "SFProBold",
              height: 35,
              marginTop: -7,
              fontSize: 20,
              minWidth: 200,
              padding: 20,
              whiteSpace: "nowrap",
              boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
            },
          })
        : toast.success(`Vous avez ${dif} de réputation en plus`, {
            style: {
              backgroundColor: COLORS.white,
              color: COLORS.black,
              fontFamily: "SFProBold",
              height: 35,
              marginTop: -7,
              fontSize: 20,
              minWidth: 200,
              padding: 20,
              whiteSpace: "nowrap",
              boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
            },
          }))
    const old = userReputation
    if (newReputation !== userReputation) {
      timeout2 && clearTimeout(timeout2)
      setReputationColor(dif < 0 ? COLORS.red : COLORS.green)
      dif !== 0 && (dif > 0 ? playPlus() : playMinus())
      setTimeout(() => {
        anime({
          targets: pourcent2,
          cycles: [0, 100],
          round: 1,
          easing: "linear",
          update() {
            const add = dif * (pourcent2.cycles / 100)
            setReputation(old + add)
          },
          duration: 2000,
        })
        timeout2 = setTimeout(() => {
          setReputationColor(COLORS.black)
        }, 2000)
      }, 100)
    }
  }, [newReputation])

  function launchCardAnim(titre, phrase, icon, varMoney, varReputation) {
    refCard?.current?.launchCard(titre, phrase, icon, varMoney, varReputation)
  }
  function launchDiceAnim() {
    multiDiceRef?.current?.launchAnim(dicex2, dicePlus2, diceMinus2)
  }
  function launchRollDice() {
    multiDiceRef?.current?.rollDice()
  }
  function launchBuyAnim(
    batiment,
    varMoney,
    owner,
    photo,
    id,
    varPopularite,
    address,
    discount
  ) {
    refBuyCard?.current?.launchBuyCard(
      batiment,
      varMoney,
      owner,
      photo,
      id,
      varPopularite,
      address,
      discount
    )
  }
  function launchGameOverAnim(user, photo, nbrHouse, nbrMoney, nbrPopularity) {
    refGameOver?.current?.launchGameOver(
      user,
      photo,
      nbrHouse,
      nbrMoney,
      nbrPopularity
    )
  }
  function launchLoseAnim() {
    refLoseCard?.current?.launchLose()
  }
  // #endregion

  // #region help
  const [displayHelp, setDisplayHelp] = useState(false)
  const regleRef = useRef(null)
  async function launchHelp() {
    setDisplayHelp(!displayHelp)
  }
  // #endregion
  useImperativeHandle(ref, () => ({
    setNewMoney,
    setNewReputation,
    setPlayerName,
    launchCardAnim,
    launchDiceAnim,
    launchRollDice,
    launchBuyAnim,
    launchGameOverAnim,
    launchLoseAnim,
  }))

  const playerTurnButton = () => {
    turnPlayed &&
      toast.error("Vous avez déjà joué pour ce tour.", {
        style: {
          backgroundColor: COLORS.white,
          fontSize: 20,
          color: COLORS.black,
          fontFamily: "SFProBold",
          height: 35,
          marginTop: -7,
          padding: 20,
          whiteSpace: "nowrap",
          boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
        },
        success: {
          duration: 5000,
        },
      })
    !turnPlayed && launchRound()
    setTurnPlayed(true)
  }

  useEffect(() => {
    if (!turnPlayed) {
      lottie.current.goToAndPlay(175, true)
    } else {
      lottie.current.goToAndStop(175, true)
    }
  }, [turnPlayed])

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      margin: 10,
      backgroundColor: "transparent",
      color: COLORS.black,
      fontSize: SIZE.desc * 1.2,
      borderRadius: 20,
      padding: 10,
      fontFamily: "SFProBold",
    },
  }))

  useEffect(() => {
    if (endGame === true) {
      const target = getObjectPlayer(globalData.lastWinner.uid, playersData)
      launchGameOverAnim(
        target.name,
        target.pdp,
        globalData.lastWinner.houseCount,
        globalData.lastWinner.money,
        globalData.lastWinner.reputation
      )
    }
  }, [endGame])

  useEffect(() => {
    if (loseGame === true) {
      launchLoseAnim()
    }
  }, [loseGame])

  function focusPlayer() {
    setPosCam(playerPos)
    setZoom(17.3)
  }

  function dezoomPlayer() {
    setPosCam([50.63297, 3.05858])
    setZoom(14.5)
  }

  return (
    <div style={styles.overlay}>
      <div
        style={styles.tour}
        onClick={(e) => {
          playerTurnButton()
        }}
        id="OverlayTour"
      >
        <span
          style={{
            borderRadius: 1000,
            width: 75,
            height: 75,
            // padding: "7px",
          }}
          alt="dés"
        >
          <Lottie
            lottieRef={lottie}
            animationData={diceAnim}
            width={50}
            style={{ transform: "translate(0, -10px)" }}
          />
        </span>
      </div>
      <div
        style={styles.help}
        onClick={(e) => {
          launchHelp()
        }}
        id="OverlayHelp"
      >
        <span
          style={{
            borderRadius: 1000,
            width: 50,
            height: 50,
            padding: "7px",
            margin: "auto",
          }}
          alt="help"
        >
          <Question
            style={{
              height: 50,
              marginLeft: 9,
            }}
          />
        </span>
      </div>
      <Regles
        setDisplayHelp={setDisplayHelp}
        displayHelp={displayHelp}
        ref={regleRef}
      />
      <ChanceCard
        ref={refCard}
        setNewMoney={setNewMoney}
        setReputation={setReputation}
        setNewReputation={setNewReputation}
        setPlayerName={setPlayerName}
        newMoney={newMoney}
        newReputation={newReputation}
        setTurnPlayed={setTurnPlayed}
        setZoom={setZoom}
      />
      <MultiDice randArray={dice} ref={multiDiceRef} />

      <BuyCard
        ref={refBuyCard}
        setNewMoney={setNewMoney}
        newMoney={newMoney}
        setNewReputation={setNewReputation}
        newReputation={newReputation}
        setPlayerName={setPlayerName}
        setTurnPlayed={setTurnPlayed}
        playersData={playersData}
        setNewSinglePlayerPos={setNewSinglePlayerPos}
        setPosCam={setPosCam}
        setZoom={setZoom}
      />
      <GameOver ref={refGameOver} />
      <LoseCard ref={refLoseCard} />

      {/* focus player */}
      <div
        style={styles.focus}
        onClick={(e) => {
          focusPlayer()
        }}
        id="OverlayFocus"
      >
        <Crosshairs
          style={{
            borderRadius: 1000,
            width: 34,
            height: 34,
            aspectRatio: 1,
            padding: 3,
            objectFit: "cover",
          }}
        />
      </div>
      <div
        style={styles.dezoom}
        onClick={(e) => {
          dezoomPlayer()
        }}
        id="OverlayDezoom"
      >
        <Map
          style={{
            width: 30,
            height: 30,
            aspectRatio: 1,
            padding: 5,
            objectFit: "cover",
          }}
          fill={COLORS.black}
        />
      </div>
      <div
        style={styles.signout}
        onClick={(e) => {
          setProfilVisible(true)
        }}
        id="OverlayProfil"
      >
        <img
          style={{
            borderRadius: 1000,
            width: 50,
            height: 50,
            aspectRatio: 1,
            objectFit: "cover",
          }}
          src={playerData?.pdp}
          alt="Logo"
        />
      </div>
      <div style={styles.menu} id="profil">
        <input
          type="file"
          style={{ display: "none" }}
          ref={pdpRef}
          onChange={(e) => {
            const p = new Promise((resolve, reject) => {
              changePdp(e.target.files[0]).then((result) => {
                if (result === true) {
                  resolve(result)
                } else {
                  reject(result)
                }
              })
            })
            toast.promise(
              p,
              {
                loading: "Chargement de votre photo de profil...",
                success: () => <b>Rechargez la page.</b>,
                error: (data) => <b>{data.message}</b>,
              },
              {
                style: {
                  backgroundColor: COLORS.white,
                  color: COLORS.black,
                  fontFamily: "SFProBold",
                  height: 35,
                  marginTop: -7,
                  fontSize: 20,
                  minWidth: 200,
                  padding: 20,
                  whiteSpace: "nowrap",
                  boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
                },
              }
            )
          }}
        />
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: " center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            setProfilVisible(false)
          }}
        />
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div
            style={{
              zIndex: 200,
              position: "relative",
            }}
          >
            <img
              style={{
                backgroundColor: COLORS.white,
                width: 150,
                height: 150,
                zIndex: 100,
                opacity: 0.9,
                marginBottom: 10,
                border: "5px solid #FFFFFF",
                borderColor: COLORS.white,
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                borderRadius: 1000,
                aspectRatio: 1,
                objectFit: "cover",
                cursor: "pointer",
              }}
              src={playerData?.pdp}
              onClick={() => {
                pdpRef.current.click()
              }}
              alt="Logo"
            />
            <Pen
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                cursor: "pointer",
              }}
              width={40}
              onClick={() => {
                pdpRef.current.click()
              }}
              fill={COLORS.black}
            />
          </div>
          <div
            style={{
              backgroundColor: COLORS.white,
              borderRadius: 10,
              opacity: 1,
              padding: 20,
              paddingTop: 100,
              transform: "translateY(-100px)",
              boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                fontSize: SIZE.title,
                fontFamily: "SFProBold",
              }}
            >
              {playerName}
            </div>
            <div
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                backgroundColor: COLORS.white,
                borderRadius: 20,
                display: "flex",
                flexDirection: "row",
                padding: 20,
                paddingBottom: 10,
                marginBottom: 20,
                gap: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLORS.black,
                  gap: 5,
                  fontFamily: "SFProBold",
                }}
              >
                <div
                  style={{
                    backgroundColor: COLORS.black,
                    borderRadius: 20,
                    height: 30,
                    padding: 10,
                    paddingLeft: 15,
                    paddingRight: 15,
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      borderRadius: 1000,
                      width: 30,
                      height: 30,
                      aspectRatio: 1,
                      objectFit: "cover",
                    }}
                    src={start}
                    alt="Logo"
                  />
                </div>
                ?
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLORS.green,
                  gap: 5,
                  fontFamily: "SFProBold",
                }}
              >
                <div
                  style={{
                    backgroundColor: COLORS.green,
                    borderRadius: 20,
                    padding: 10,
                    paddingLeft: 15,
                    height: 30,

                    paddingRight: 15,
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Money style={{}} width={30} fill={COLORS.white} />
                </div>
                {PRICE(userMoney)}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLORS.red,
                  gap: 5,
                  fontFamily: "SFProBold",
                }}
              >
                <div
                  style={{
                    backgroundColor: COLORS.red,
                    borderRadius: 20,
                    padding: 10,
                    paddingLeft: 15,
                    paddingRight: 15,
                    height: 30,

                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <House style={{}} width={30} fill={COLORS.white} />
                </div>
                {houses?.length}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLORS.yellow,
                  gap: 5,
                  fontFamily: "SFProBold",
                }}
              >
                <div
                  style={{
                    backgroundColor: COLORS.yellow,
                    borderRadius: 20,
                    padding: 10,
                    paddingLeft: 15,
                    paddingRight: 15,
                    display: "flex",
                    height: 30,

                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Hotel style={{}} width={30} fill={COLORS.white} />
                </div>
                ?
              </div>
            </div>
            <div
              style={{
                margin: "auto",
                width: "fit-content",
              }}
            >
              <Button
                width="150px"
                text="Déconnexion"
                color={COLORS.black}
                onClick={() => {
                  logout()
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={styles.personalData}>
        <div style={{ color: moneyColor }}>{PRICE(userMoney)}</div>
        <Money fill={moneyColor} width={30} style={{ paddingLeft: 5 }} />
        <div style={styles.dot} />
        <HtmlTooltip
          title={
            <div
              style={{
                borderRadius: 20,
                padding: 10,
                backgroundColor: COLORS.white,
                boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
              }}
            >
              Votre réputation influe sur le prix de chaque action en jeu.
              <div
                style={{
                  color:
                    Math.round(userReputation) >= 0 ? COLORS.green : COLORS.red,
                }}
              >
                Actuellement vous payez&nbsp;
                {Math.round(userReputation) === 0
                  ? "le prix de base "
                  : Math.round(userReputation) > 0
                  ? `${Math.round(userReputation)}% de moins `
                  : `${Math.round(userReputation)}% de plus `}
                sur chaque action.
              </div>
            </div>
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <div style={{ color: reputationColor }}>
              {Math.round(userReputation)}
            </div>
            <Reputation
              fill={reputationColor}
              style={{ paddingLeft: 5 }}
              width={30}
            />
          </div>
        </HtmlTooltip>
      </div>
      <div style={styles.globalData}>
        <HtmlTooltip
          title={
            <div
              style={{
                borderRadius: 20,
                padding: 10,
                backgroundColor: COLORS.white,
              }}
            >
              Actuellement il reste {globalData.turnLeft} tours avant la fin de
              la partie.
            </div>
          }
        >
          <div
            style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
          >
            <div>{globalData.turnLeft}</div>
            <div
              style={{
                position: "relative",
                width: 60,
                height: 20,
              }}
            >
              <FLag
                width={20}
                style={{
                  position: "absolute",
                  top: "10%",
                  left: "55%",
                  transform: "rotate(25deg)",
                }}
              />
              <FLag
                width={20}
                style={{
                  position: "absolute",
                  top: "10%",
                  left: "10%",
                  transform: "rotate(-25deg) scale(-1, 1)",
                }}
              />
            </div>
          </div>
        </HtmlTooltip>
        <div style={styles.dot} />
        {/* <HtmlTooltip
          title={
            <div
              style={{
                borderRadius: 20,
                padding: 10,
                backgroundColor: COLORS.white,
              }}
            >
              Le temps restant avant la fin du tour. Tous les enchères seront
              adjugées à la fin de ce timer.
            </div>
          }
        > */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Clock style={{ paddingLeft: 5 }} />
          <Lottie
            animationData={clock}
            style={{ paddingLeft: 5, height: 30 }}
          />
        </div>
        {/* </HtmlTooltip> */}
      </div>
      <div
        style={styles.houseData}
        id="houses"
        onClick={() => {
          if (!visible) {
            setHouse(false)
          }
          setVisible(!visible)
        }}
      >
        <div
          style={{
            width: 50,
            height: 50,
            backgroundColor: COLORS.white,
            borderRadius: "15px 15px 0px 0px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
          }}
        >
          <House width={30} fill={COLORS.black} />
        </div>
        <div
          style={{
            width: "100%",
            height: 200,
            backgroundColor: COLORS.white,
            borderRadius: 40,
            display: "flex",
            flexDirection: "row",
            overflowX: "scroll",
            color: COLORS.black,
            boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
          }}
        >
          {houses.length === 0 ? (
            <div style={{ margin: "auto", fontSize: SIZE.title }}>
              Vous ne possedez pas encore de maison.
            </div>
          ) : (
            ""
          )}
          {houses?.map((e) => (
            <SmallHouseCard
              setZoom={setZoom}
              setMapCenter={setMapCenter}
              setHouseId={setHouseId}
              setHouse={setHouse}
              data={e}
              key={`H${e.houseId}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
})
export default Stats
