/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from "react"
import { COLORS, PRICE, SIZE, STYLES } from "../../export/const"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import { ReactComponent as Money } from "../../assets/svg/money.svg"
import { ReactComponent as House } from "../../assets/svg/house.svg"
import { ReactComponent as Hotel } from "../../assets/svg/hotel.svg"
import { ReactComponent as Trophy } from "../../assets/svg/trophy.svg"
import { ReactComponent as Reputation } from "../../assets/svg/reputation.svg"

import { getPlayerById, getPlayerByIdLocal } from "../../online/user"

function PlayerCard({ playerId, playersData }) {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 5,
    },
  }

  const [player, setPlayer] = useState(undefined)
  const [win, setWin] = useState("?")
  const [pdp, setPdp] = useState(undefined)

  const fetch = async () => {
    const result = await getPlayerById(playerId)
    const result2 = getPlayerByIdLocal(result?.playerId, playersData)
    setPdp(result2.pdp)
    setWin(result2.winCount)
    setPlayer(result)
  }

  useEffect(() => {
    fetch()
  }, [playerId])

  return (
    <div style={styles.container}>
      <div style={{ ...STYLES.card }}>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div
            style={{
              zIndex: 200,
              position: "relative",
            }}
          >
            <img
              style={{
                backgroundColor: COLORS.white,
                width: 150,
                height: 150,
                zIndex: 100,
                opacity: 0.9,
                marginBottom: 10,
                border: "5px solid #FFFFFF",
                borderColor: COLORS.white,
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                borderRadius: 1000,
                aspectRatio: 1,
                objectFit: "cover",
                cursor: "pointer",
              }}
              src={pdp || "https://wallpapercave.com/wp/wp4782096.jpg"}
              alt="Logo"
            />
          </div>
          <div
            style={{
              backgroundColor: COLORS.white,
              borderRadius: 30,
              opacity: 1,
              padding: 20,
              paddingTop: 100,
              transform: "translateY(-100px)",
              boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                fontSize: SIZE.title,
                fontFamily: "SFProBold",
              }}
            >
              {player?.name ? player.name : `Chargement`}
            </div>
            <div
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                backgroundColor: COLORS.white,
                borderRadius: 20,
                display: "flex",
                flexDirection: "row",
                padding: 20,
                paddingBottom: 10,
                marginBottom: 20,
                gap: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLORS.yellow,
                  gap: 5,
                  fontFamily: "SFProBold",
                }}
              >
                <div
                  style={{
                    backgroundColor: COLORS.yellow,
                    borderRadius: 20,
                    height: 30,
                    padding: 10,
                    paddingLeft: 15,
                    paddingRight: 15,
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Trophy style={{}} width={30} fill={COLORS.white} />
                </div>
                {win !== undefined ? win : "?"}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLORS.green,
                  gap: 5,
                  fontFamily: "SFProBold",
                }}
              >
                <div
                  style={{
                    backgroundColor: COLORS.green,
                    borderRadius: 20,
                    padding: 10,
                    paddingLeft: 15,
                    height: 30,

                    paddingRight: 15,
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Money style={{}} width={30} fill={COLORS.white} />
                </div>
                {PRICE(player?.money)}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLORS.red,
                  gap: 5,
                  fontFamily: "SFProBold",
                }}
              >
                <div
                  style={{
                    backgroundColor: COLORS.red,
                    borderRadius: 20,
                    padding: 10,
                    paddingLeft: 15,
                    paddingRight: 15,
                    height: 30,

                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <House style={{}} width={30} fill={COLORS.white} />
                </div>
                {player ? player.houses?.length : "?"}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLORS.blue,
                  gap: 5,
                  fontFamily: "SFProBold",
                }}
              >
                <div
                  style={{
                    backgroundColor: COLORS.blue,
                    borderRadius: 20,
                    padding: 10,
                    paddingLeft: 15,
                    paddingRight: 15,
                    display: "flex",
                    height: 30,

                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Reputation width={30} fill={COLORS.white} />
                </div>
                {player ? player?.reputation : "?"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlayerCard
