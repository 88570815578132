/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from "react"
import anime from "animejs/lib/anime.es.js"
import { COLORS, RESIZE } from "../../export/const"
import startingFlag from "../../assets/images/startingFont.png"
import { ReactComponent as Tax } from "../../assets/svg/bank.svg"
import { ReactComponent as Metro } from "../../assets/svg/metro.svg"
import { ReactComponent as Luck } from "../../assets/svg/luck.svg"
import { ReactComponent as Hands } from "../../assets/svg/handcuffs-solid.svg"
import { ReactComponent as Police } from "../../assets/svg/user-police.svg"
import { ReactComponent as Park } from "../../assets/svg/bench-tree.svg"
import { ReactComponent as Inter } from "../../assets/svg/coin.svg"
import { ReactComponent as Arrow } from "../../assets/svg/angle-right.svg"

function Case({ zoom, e, reference, angle, X, Y, index, openCard, id }) {
  const [hover, setHover] = useState(false)
  const ref = useRef()

  const [lastZoom, setLastZoom] = useState(zoom)
  useEffect(() => {
    if (!reference.current.includes(ref)) {
      reference.current[e.id] = ref
    }
  }, [])

  const SIZE_TYPE = "special"

  useEffect(() => {
    // if (e.type === "luck" || e.type === "intersection" || e.type === "house")
    if (lastZoom > 15.5 && zoom < 15.5) {
      anime({
        targets: ref.current,
        scale: e.type === "house" ? [1, 0.75] : [1, 0.2],
        duration: 800,
      })
    } else if (lastZoom < 15.5 && zoom > 15.5) {
      anime({
        targets: ref.current,
        scale: e.type === "house" ? [0.75, 1] : [0.2, 1],
        duration: 800,
      })
    }
    setLastZoom(zoom)
  }, [zoom])

  useEffect(() => {
    if (hover) {
      anime({
        targets: ref.current,
        scale: [1, 1.3],
        duration: 400,
        easing: "easeOutExpo",
      })
    } else {
      anime({
        targets: ref.current,
        scale: zoom < 15.5 ? [1, 0.2] : [1.3, 1],
        duration: 400,
        easing: "easeOutExpo",
      })
    }
  }, [hover])

  const styles = {
    border: {
      backgroundColor: hover ? COLORS.red : COLORS.black,
      borderRadius: 15,
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      transform: "translate(-50%, -50%)",
    },
    border2: {
      backgroundColor: COLORS.white,
      zIndex: 1000,
      width: 40 * RESIZE(zoom, SIZE_TYPE),
      height: 40 * RESIZE(zoom, SIZE_TYPE),
    },
    icon: {
      zIndex: 10,
      margin: 10 * RESIZE(zoom, SIZE_TYPE),
    },
  }

  const child = () => {
    switch (e.type) {
      case "start": {
        return (
          <div
            ref={ref}
            style={{
              ...styles.border,
              backgroundColor: COLORS.white,
              zIndex: 10,
              padding: 5 * RESIZE(zoom, "medium"),
              width: RESIZE(zoom, "medium") * 30,
              height: RESIZE(zoom, "medium") * 30,
            }}
          >
            <img
              style={{
                width: "100%",
                objectFit: "fill",
                borderRadius: 10,
              }}
              src={startingFlag}
              alt="start"
            />
          </div>
        )
      }
      case "goJail": {
        return (
          <div
            ref={ref}
            style={{
              ...styles.border,
              ...styles.border2,
              borderRadius: 10,
              padding: 0,
            }}
          >
            <Hands fill={COLORS.white} style={styles.icon} />
            <div
              ref={ref}
              style={{
                backgroundColor: COLORS.red,
                position: "absolute",
                transform: "rotate(45deg)",
                width: "100%",
                height: "100%",
                borderRadius: 10,
              }}
            />
          </div>
        )
      }
      case "police": {
        return (
          <div
            ref={ref}
            style={{
              ...styles.border,
              ...styles.border2,
              borderRadius: 10,
              padding: 0,
            }}
            onClick={() => {
              openCard("police")
            }}
          >
            <Police fill={COLORS.white} style={styles.icon} />
            <div
              ref={ref}
              style={{
                backgroundColor: COLORS.blue,
                position: "absolute",
                transform: `rotate(${angle})`,
                width: "100%",
                height: "100%",
                borderRadius: 10,
              }}
            />
          </div>
        )
      }
      case "luck": {
        return zoom > 15.5 ? (
          <div
            ref={ref}
            style={{
              ...styles.border,
              ...styles.border2,
              borderRadius: 1000,
              backgroundColor: COLORS.blue,
              padding: 0,
            }}
            onClick={() => {
              openCard("luckDesc")
            }}
          >
            <Luck fill={COLORS.white} style={styles.icon} />
          </div>
        ) : (
          <div
            ref={ref}
            style={{
              ...styles.border,
              ...styles.border2,
              borderRadius: 10,
              width: 30,
              height: 30,
              backgroundColor: COLORS.blue,
              padding: 0,
            }}
          />
        )
      }
      case "intersection": {
        return zoom > 15.5 ? (
          <div
            ref={ref}
            style={{
              ...styles.border,
              ...styles.border2,
              borderRadius: 10,
              backgroundColor: COLORS.yellow,
              padding: 0,
            }}
            onClick={() => {
              openCard("inter")
            }}
          >
            <Inter fill={COLORS.white} style={styles.icon} />
          </div>
        ) : (
          <div
            ref={ref}
            style={{
              ...styles.border,
              ...styles.border2,
              borderRadius: 10,
              width: 30,
              height: 30,
              backgroundColor: COLORS.yellow,
              padding: 0,
            }}
          />
        )
      }
      case "tax": {
        return (
          <div
            ref={ref}
            style={{
              ...styles.border,
              ...styles.border2,
              borderRadius: 10,
              padding: 0,
              backgroundColor: COLORS.red,
            }}
            onClick={() => {
              openCard("bankDesc")
            }}
          >
            <Tax
              fill={COLORS.white}
              style={{ ...styles.icon, transform: "translate(0, -1px)" }}
            />
          </div>
        )
      }
      case "metro": {
        return (
          <div
            ref={ref}
            style={{
              ...styles.border,
              ...styles.border2,
              borderRadius: 10,
              backgroundColor: COLORS.yellow,
              padding: 0,
            }}
            onClick={() => {
              openCard("metroDesc")
            }}
          >
            <Metro fill={COLORS.white} style={styles.icon} />
          </div>
        )
      }
      case "house":
        return (
          <div
            ref={ref}
            style={{
              ...styles.border,
              zIndex: 320,
              backgroundColor: COLORS.grey,
              padding: 6.5,
              borderRadius: 1000,
            }}
            onClick={() => {
              openCard("house", id)
            }}
          >
            {zoom > 15.5 && (
              <Arrow
                style={{
                  height: 17.5,
                  width: 17.5,
                  transform: `rotate(${angle}deg)`,
                }}
                fill={COLORS.white}
              />
            )}
          </div>
        )
      case "park":
        return (
          <div
            ref={ref}
            style={{
              ...styles.border,
              ...styles.border2,
              borderRadius: 1000,
              backgroundColor: COLORS.green,
              padding: 0,
            }}
            onClick={() => {
              openCard("parkDesc")
            }}
          >
            <Park fill={COLORS.white} style={styles.icon} />
          </div>
        )
    }
  }

  return (
    <div
      onMouseOver={() => {
        setHover(true)
      }}
      onMouseOut={() => {
        setHover(false)
      }}
      onClick={() => {
        console.log({ next: e.next[0], id: e.id, coo: e.coordinate })
      }}
    >
      {child()}
    </div>
  )
}
export default Case
