/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useEffect, useRef, useState } from "react"
import firebase from "firebase/compat/app"
import { GeoJson, Map, Overlay } from "pigeon-maps"
import { useNavigate } from "react-router-dom"
import { toast } from "react-hot-toast"
import geoHouse from "../assets/geoJson/houses"
import geoCases from "../assets/geoJson/cases"
import {
  playerTurn,
  getHouse,
  getPlayers,
  resetHouses,
  getGlobal,
  getPlayer,
} from "../online/user"
import {
  CENTROID,
  COLORS,
  HOUSE_COLOR,
  HOUSE_COLOR_STROKE,
  radiansToDegrees,
} from "../export/const"
import UI from "./overlay"
import CardsController from "../comps/cards/cardsController"
import BankCard from "../comps/cards/bankCard"
import PrisonCard from "../comps/cards/prisonCard"
import MetroCard from "../comps/cards/metroCard"
import HouseCard from "../comps/cards/houseCard"
import Case from "../comps/case/case"
import Avatar from "../comps/player/avatar"
import HouseOverlay from "../comps/house/houseOverlay"

import "firebase/compat/auth"
import PileFace from "../comps/pileFace"
import InterCard from "../comps/cards/interCard"
import TaxCard from "../comps/cards/taxCard"
import {
  removeListenUser,
  setListenUser,
  listenPlayer,
} from "../export/listener"
import PoliceCard from "../comps/cards/police/policeCard"
import ParkCardDesc from "../comps/cards/parkCardDesc"
import MetroCardDesc from "../comps/cards/metroCardDesc"
import LuckCardDesc from "../comps/cards/luckCardDesc"
import PlayerCard from "../comps/cards/playerCard"

export function Main({ casesData, housesData, maptilerProvider, data }) {
  const navigate = useNavigate()
  const { playersData, angle, centroid, housesData2, globalData, playerData } =
    data

  // #region cards

  const [police, setPolice] = useState(false)
  const [parkDesc, setParkDesc] = useState(false)
  const [turnPlayed, setTurnPlayed] = useState(false)
  const [actionPlayed, setActionPlayed] = useState(false)
  const [house, setHouse] = useState(false)
  const [houseId, setHouseId] = useState(0)
  const [inter, setInter] = useState(false)
  const [prison, setPrison] = useState(false)
  const [bankDesc, setBankDesc] = useState(false)
  const [metroDesc, setMetroDesc] = useState(false)
  const [luckDesc, setLuckDesc] = useState(false)
  const [endGame, setEndGame] = useState(!playerData?.everPlayed)
  const [loseGame, setLoseGame] = useState(playerData?.lose)
  const [playerCard, setPlayerCard] = useState(false)
  const [playerCardId, setPlayerCardId] = useState(
    "ym72VaAR4hXNrsGoFxiNdwWwDc33"
  )

  // #endregion

  const [posCam, setPosCam] = useState([50.63297, 3.05858])
  const [zoom, setZoom] = useState(14)
  const [localPlayer, setLocal] = useState(undefined)
  const [dice, setDice] = useState([1, 1, 1])

  const [localPlayerPos, setLocalPos] = useState()
  const UIref = useRef()
  const casesRef = useRef([])

  // #region players

  const players = playersData.map((item) => ({
    id: item.playerId,
    coordinate: casesData.cases[item.actualCaseId].coordinate,
  }))

  const setLocalPlayer = () => {
    for (const player of playersData) {
      if (player.playerId === firebase.auth().currentUser.uid) {
        setLocal(player)
      }
    }
    for (const player of players) {
      if (player.id === firebase.auth().currentUser.uid) {
        setLocalPos(player.coordinate)
      }
    }
  }

  const [playersPos, setPlayerPos] = useState(
    playersData.map((item) => ({
      id: item.playerId,
      coordinate: casesData.cases[item.actualCaseId].coordinate,
    }))
  )
  const [newPlayerPos, setNewPlayerPos] = useState(
    playersData.map((item) => ({
      id: item.playerId,
      coordinate: casesData.cases[item.actualCaseId].coordinate,
    }))
  )

  const setNewSinglePlayerPos = (newCaseId, playerId) => {
    // eslint-disable-next-line prefer-const
    let playerPosLocal = newPlayerPos
    for (const player of playerPosLocal) {
      if (player.id === playerId) {
        player.coordinate = casesData.cases[newCaseId].coordinate
      }
    }
    setNewPlayerPos([...playerPosLocal])
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((authState) => {
      if (authState !== null) {
        navigate("/")
      } else {
        navigate("/login")
      }
    })
    setLocalPlayer()
    listenPlayer(setNewPlayerPos, newPlayerPos)
  }, [])

  // #region game round

  let roundData = {
    dicesValues: [1, 1, 1],
    cases: [
      {
        coordinate: [50.63946576080293, 3.0697320536931443],
        id: 39,
        type: "house",
      },
      {
        coordinate: [50.640459164587455, 3.068911297734587],
        id: 40,
        type: "house",
      },
      {
        coordinate: [50.64096266259651, 3.068165643628447],
        id: 41,
        type: "luck",
      },
    ],
    Content: [
      "Prochain loyer /2",
      "Votre Sugar daddy vous paye votre prochain loyer.",
      "luck", // icon
    ], // pattern titre, phrase, icon
    action: {
      actionType: "DICE_MALUS", // bonus_argent, bonus_des, malus_maison, loyer, rien
      interValue: 2000,
      varMoney: 0,
      varReputation: 2,
      dicex2: false,
      dicePlus2: false,
      diceMinus2: false,
      rentDiscount: false,
    },
  }

  // #region game round
  const pileFaceRef = useRef()
  const [userMoney, setUserMoney] = useState(0)
  const [newMoney, setNewMoney] = useState(0)
  const [newReputation, setNewReputation] = useState(0)
  const [playerName, setPlayerName] = useState(0)
  const [dicex2, setDicex2] = useState(false)
  const [dicePlus2, setDicePlus2] = useState(false)
  const [diceMinus2, setDiceMinus2] = useState(false)

  const OpenCard = (type, id) => {
    setPrison(false)
    setPlayerCard(false)
    setHouse(false)
    setInter(false)
    setPolice(false)
    setParkDesc(false)
    setBankDesc(false)
    setLuckDesc(false)
    setMetroDesc(false)
    switch (type) {
      case "house": {
        setHouseId(id)
        setTimeout(() => {
          setHouse(true)
        }, 200)
        break
      }
      case "police": {
        setTimeout(() => {
          setPolice(true)
        }, 200)
        break
      }
      case "parkDesc": {
        setTimeout(() => {
          setParkDesc(true)
        }, 200)
        break
      }
      case "luckDesc": {
        setTimeout(() => {
          setLuckDesc(true)
        }, 200)
        break
      }
      case "metroDesc": {
        setTimeout(() => {
          setMetroDesc(true)
        }, 200)
        break
      }
      case "player": {
        setTimeout(() => {
          setPlayerCard(true)
          setPlayerCardId(id)
        }, 200)
        break
      }
      case "inter": {
        setTimeout(() => {
          setInter(true)
        }, 200)
        break
      }
      case "bankDesc": {
        setTimeout(() => {
          setBankDesc(true)
        }, 200)
        break
      }
    }
  }

  const Players = () => {
    const tiles = []
    players.map((e, index) => {
      // if(playersPos[index].)
      tiles.push(
        <Overlay key={index} anchor={playersPos[index].coordinate}>
          <Avatar
            setPlayerPos={setPlayerPos}
            playerId={e.id}
            playerPos={playersPos}
            newPlayerPos={newPlayerPos}
            openCard={OpenCard}
            setNewPlayerPos={setNewPlayerPos}
            index={index}
            pdp={playersData[index].pdp}
          />
        </Overlay>
      )
      return e
    })
    return tiles
  }

  const setCamToMiddleRound = () => {
    const cam = [
      (roundData.cases[0].coordinate[0] +
        // eslint-disable-next-line no-restricted-globals
        roundData.cases[roundData.cases.length - 1].coordinate[0]) /
        2,

      (roundData.cases[0].coordinate[1] +
        roundData.cases[roundData.cases.length - 1].coordinate[1]) /
        2,
    ]
    setPosCam(cam)
    setZoom(16)
  }

  const luck = () => {
    switch (roundData.action.actionType) {
      case "MONEY_BONUS" || "MONEY_MALUS":
        UIref.current.launchCardAnim(
          roundData.Content[0],
          roundData.Content[1],
          roundData.action.actionType, // icon
          roundData.action.varMoney,
          0 // varReputation = 0
        )
        break

      case "POPULARITY_MALUS":
        UIref.current.launchCardAnim(
          roundData.Content[0],
          roundData.Content[1],
          "POPULARITY_MALUS",
          0, // varMoney = 0
          roundData.action.varReputation
        )
        break

      case "POPULARITY_BONUS":
        UIref.current.launchCardAnim(
          roundData.Content[0],
          roundData.Content[1],
          "POPULARITY_BONUS",
          0, // varMoney = 0
          roundData.action.varReputation
        )
        break
      case "DICE_BONUS":
        UIref.current.launchCardAnim(
          roundData.Content[0],
          roundData.Content[1],
          "DICE_BONUS",
          0, // varMoney = 0
          0 // varReputation = 0
        )
        break
      case "DICE_MALUS":
        UIref.current.launchCardAnim(
          roundData.Content[0],
          roundData.Content[1],
          "DICE_MALUS",
          0, // varMoney = 0
          0 // varReputation = 0
        )
        break
      case "DICE_DOUBLE":
        UIref.current.launchCardAnim(
          roundData.Content[0],
          roundData.Content[1],
          "DICE_BONUS",
          0, // varMoney = 0
          0 // varReputation = 0
        )
        break
      case "RENT_DISCOUNT":
        UIref.current.launchCardAnim(
          roundData.Content[0],
          roundData.Content[1],
          "RENT_DISCOUNT",
          0, // varMoney = 0
          0 // varReputation = 0
        )
        break
      case "IMMUNITY":
        UIref.current.launchCardAnim(
          roundData.Content[0],
          roundData.Content[1],
          "IMMUNITY",
          0, // varMoney = 0
          0 // varReputation = 0
        )
        break
      case "MOVE_BONUS":
        UIref.current.launchCardAnim(
          roundData.Content[0],
          roundData.Content[1],
          "MOVE_BONUS",
          0, // varMoney = 0
          0 // varReputation = 0
        )
        break
      case "HOME_LOSS":
        UIref.current.launchCardAnim(
          roundData.Content[0],
          roundData.Content[1],
          "HOME_LOSS",
          0, // varMoney = 0
          0 // varReputation = 0
        )
        break
    }
  }
  // animation du tour du joueur
  const launchRound = async () => {
    setCamToMiddleRound()

    UIref.current.launchDiceAnim() // entrée des dés
    await new Promise((resolve) => setTimeout(resolve, 1000))
    UIref.current.launchRollDice() // lancer les dés

    await new Promise((resolve) => setTimeout(resolve, 2700))
    UIref.current.launchDiceAnim() // sortie des dés

    await new Promise((resolve) => setTimeout(resolve, 300))
    // déplacement
    let money = newMoney

    for (const currentCase of roundData.cases) {
      setNewSinglePlayerPos(currentCase.id, firebase.auth().currentUser.uid)
      if (
        (currentCase.type === "intersection" || currentCase.type === "start") &&
        currentCase !== roundData.cases[roundData.cases.length - 1]
      ) {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        money += roundData.action.interValue
        setNewMoney(money) // remuneration intersection
        if (currentCase.next.length > 1) {
          // si l'intersection a plusieurs directions possibles -> pile ou face
          await new Promise((resolve) => setTimeout(resolve, 100))
          pileFaceRef?.current?.launchFlip() // entrée
          await new Promise((resolve) => setTimeout(resolve, 3000))
          pileFaceRef?.current?.launchFlip() // sortie
        }
      }

      await new Promise((resolve) => setTimeout(resolve, 800))
    }
    // fin du tour -> action de la dernière case
    await new Promise((resolve) => setTimeout(resolve, 800))

    switch (roundData.cases[roundData.cases.length - 1].type) {
      case "luck":
        luck()
        break

      case "house":
        if (roundData.action.actionType === "ENEMY_HOUSE") {
          if (roundData.action === "rentDiscount") {
            UIref.current.launchBuyAnim(
              "house",
              roundData.action.varMoney,
              roundData.action.owner.name,
              roundData.action.owner.pdp,
              null,
              null,
              roundData.cases[roundData.cases.length - 1].address,
              true
            )
          } else {
            UIref.current.launchBuyAnim(
              "house",
              roundData.action.varMoney,
              roundData.action.owner.name,
              roundData.action.owner.pdp,
              null,
              null,
              roundData.cases[roundData.cases.length - 1].address,
              false
            )
          }
        } else {
          await new Promise((resolve) => setTimeout(resolve, 100))
          setListenUser(
            setNewMoney,
            setNewReputation,
            setPlayerName,
            setTurnPlayed
          )
        }
        break
      case "bank":
        UIref.current.launchBuyAnim(
          "bank",
          roundData.action.varMoney,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        )
        break
      case "tax":
        UIref.current.launchBuyAnim(
          "tax",
          roundData.action.varMoney,
          null,
          null,
          null,
          null,
          null,
          null
        )
        break
      case "prison":
        UIref.current.launchBuyAnim(
          "prison",
          null,
          null,
          null,
          null,
          null,
          null,
          null
        )
        break
      case "park":
        UIref.current.launchBuyAnim(
          "park",
          null,
          null,
          null,
          null,
          roundData.action.varReputation,
          null,
          null
        )
        break
      case "metro":
        UIref.current.launchBuyAnim(
          "metro",
          null,
          null,
          null,
          roundData.cases[roundData.cases.length - 1].id,
          null,
          null,
          null
        )
        break
      case "intersection":
        await new Promise((resolve) => setTimeout(resolve, 100))
        setListenUser(
          setNewMoney,
          setNewReputation,
          setPlayerName,
          setTurnPlayed
        )
        break
      case "police":
        UIref.current.launchBuyAnim(
          "police",
          null,
          null,
          null,
          null,
          null,
          null,
          null
        )
        break
      default:
        break
    }
    setLocalPos(roundData.cases[roundData.cases.length - 1].coordinate) // la position pour le bouton recentrage sur le joueur et set à sa position après le lancer de des
    return true
  }

  // lancement du tour du joueur
  const launchRoundButton = async () => {
    // masquer les cartes actives
    if (house) {
      setHouse(false)
    }
    if (police) {
      setPolice(false)
    }
    if (parkDesc) {
      setParkDesc(false)
    }
    if (inter) {
      setInter(false)
    }
    if (prison) {
      setPrison(false)
    }
    if (bankDesc) {
      setBankDesc(false)
    }
    if (metroDesc) {
      setMetroDesc(false)
    }
    if (luckDesc) {
      setLuckDesc(false)
    }
    await new Promise((resolve) => setTimeout(resolve, 100))

    removeListenUser() // on désactive la synchronisation du front pour les valeurs d'argent et de popularité avec le back
    // requête au serveur pour récupérer le tour du joueur
    const p = new Promise((resolve, reject) =>
      playerTurn()
        .then((result) => {
          if (result.code === 1) {
            roundData = result
            setDice(roundData.dicesValues)
            setDicex2(roundData.action.dicex2)
            setDicePlus2(roundData.action.dicePlus2)
            setDiceMinus2(roundData.action.diceMinus2)
            resolve(result)
            launchRound().then(() => true)
          } else {
            reject(result)
            return false
          }
        })
        .catch((e) => {
          reject(e)
          return false
        })
    )
    toast.promise(
      p,
      {
        loading: "Chargement de votre tour de jeu...",
        success: (data) => <b>{data.message}</b>,
        error: (data) => <b>{data.message}</b>,
      },
      {
        style: {
          backgroundColor: COLORS.white,
          color: COLORS.black,
          fontFamily: "SFProBold",
          height: 35,
          marginTop: -7,
          fontSize: 20,
          minWidth: 200,
          padding: 20,
          whiteSpace: "nowrap",
          boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
        },
      }
    )
    return p
  }

  // #endregion

  return (
    <div style={{ backgroundColor: COLORS.black }}>
      <CardsController left visible={police}>
        <PoliceCard playersData={playersData} />
      </CardsController>
      <CardsController left visible={house}>
        <HouseCard
          name="Cormontaigne"
          cost="45k"
          data={housesData2[houseId]}
          turnPlayed={turnPlayed}
          actionPlayed={actionPlayed}
          playersData={playersData}
          newReputation={newReputation}
        />
      </CardsController>
      <CardsController left visible={house}>
        <HouseCard
          name="Cormontaigne"
          cost="45k"
          data={housesData2[houseId]}
          turnPlayed={turnPlayed}
          actionPlayed={actionPlayed}
          playersData={playersData}
          openCard={OpenCard}
          newReputation={newReputation}
        />
      </CardsController>
      <CardsController left visible={bankDesc}>
        <TaxCard cost="45k" />
      </CardsController>
      <CardsController left visible={prison}>
        <PrisonCard name="Cormontaigne" cost="45k" />
      </CardsController>
      <CardsController left visible={inter}>
        <InterCard />
      </CardsController>
      <CardsController left visible={parkDesc}>
        <ParkCardDesc />
      </CardsController>
      <CardsController left visible={metroDesc}>
        <MetroCardDesc />
      </CardsController>
      <CardsController left visible={playerCard}>
        <PlayerCard playerId={playerCardId} playersData={playersData} />
      </CardsController>

      {/* overlay */}
      <UI
        setMapCenter={setPosCam}
        setZoom={setZoom}
        setHouseId={setHouseId}
        setHouse={setHouse}
        houseData={housesData2}
        ref={UIref}
        playerData={localPlayer}
        playerPos={localPlayerPos}
        launchRound={launchRoundButton}
        newMoney={newMoney}
        setNewMoney={setNewMoney}
        userMoney={userMoney}
        setUserMoney={setUserMoney}
        roundData={roundData}
        newReputation={newReputation}
        setNewReputation={setNewReputation}
        setEverPlayed={setEndGame}
        everPlayed={endGame}
        loseGame={loseGame}
        dice={dice}
        dicex2={dicex2}
        dicePlus2={dicePlus2}
        diceMinus2={diceMinus2}
        turnPlayed={turnPlayed}
        setTurnPlayed={setTurnPlayed}
        setActionPlayed={setActionPlayed}
        setNewSinglePlayerPos={setNewSinglePlayerPos}
        setPosCam={setPosCam}
        playersData={playersData}
        globalData={globalData}
        endGame={endGame}
      />
      <PileFace ref={pileFaceRef} />
      <Map
        height="100vh"
        provider={maptilerProvider}
        minZoom={14.5}
        maxZoom={18}
        defaultCenter={[50.63297, 3.05858]}
        defaultZoom={16}
        zoom={zoom}
        center={posCam}
        zoomSnap={false} // pour éviter rebonds si zoom
        onClick={() => {
          OpenCard("")
        }}
        onBoundsChanged={({ center, zoom }) => {
          setZoom(zoom)
          setPosCam(center)
        }}
        style={{}}
      >
        <GeoJson
          data={housesData}
          onMouseOver={({ payload }) => {
            if (payload.properties.id.length !== 0) {
              for (let i = 0; i < payload.properties.id.length; i++) {
                casesRef.current[
                  payload.properties.id[i]
                ].current.style.backgroundColor = COLORS.red
              }
            }
          }}
          onMouseOut={({ payload }) => {
            if (payload.properties.id === undefined) {
              // console.error(payload)
            }
            if (payload.properties.id.length !== 0) {
              for (let i = 0; i < payload.properties.id.length; i++) {
                casesRef.current[
                  payload.properties.id[i]
                ].current.style.backgroundColor =
                  payload.properties.type === "park"
                    ? COLORS.green
                    : COLORS.grey
              }
            }
          }}
          onClick={({ payload }) => {
            if (payload?.properties?.type === "park") {
              OpenCard("parkDesc")
            } else {
              OpenCard("house", payload.id)
            }
          }}
          styleCallback={(feature) => ({
            fill:
              feature.properties.type === "park"
                ? COLORS.green
                : HOUSE_COLOR_STROKE(
                    housesData2[feature.id]?.state,
                    housesData2[feature.id]?.ownerId
                  ),
            opacity: feature.properties.type === "park" ? 0.5 : 0.4,
            strokeWidth: "2",
            stroke:
              feature.properties.type === "park"
                ? COLORS.green
                : HOUSE_COLOR(
                    housesData2[feature.id]?.state,
                    housesData2[feature.id]?.ownerId
                  ),
            r: "20",
          })}
        />
        {casesData.cases.map((e, index) => (
          <Overlay key={index} anchor={[e.coordinate[0], e.coordinate[1]]}>
            <Case
              reference={casesRef}
              zoom={zoom}
              id={e.house}
              e={e}
              openCard={OpenCard}
              angle={angle[index]}
            />
          </Overlay>
        ))}
        {housesData.features.map((e, index) =>
          e.properties.type === "park" ? (
            <></>
          ) : (
            <Overlay
              key={index}
              anchor={[centroid[index][1], centroid[index][0]]}
            >
              <HouseOverlay
                openCard={OpenCard}
                housesData={housesData2[index]}
                zoom={zoom}
                playersData={playersData}
              />
            </Overlay>
          )
        )}
        {Players().map((e) => e)}
      </Map>
    </div>
  )
}

export const mainData = async () => {
  const playersData = await getPlayers()
  const housesData2 = await getHouse()
  const globalData = await getGlobal()
  const playerData = await getPlayer()

  const centroid = []
  const angle = []
  geoHouse.features.map((e) => {
    centroid.push(CENTROID(e.geometry.coordinates[0]))
  })
  geoCases.cases.map((e, index) => {
    const case1 = geoCases.cases[index]
    const coo1 = [case1.coordinate[0], case1.coordinate[1]]

    const case2 = geoCases.cases[case1.next[0]]
    const coo2 = [case2.coordinate[0], case2.coordinate[1]]

    const X = coo2[0] - coo1[0]
    const Y = coo2[1] - coo1[1]

    let angle2 = radiansToDegrees(Math.atan(Y / X)) - 90
    if ((X < 0 && Y > 0) || (X < 0 && Y < 0)) {
      angle2 += 180
    }
    angle.push(angle2)
  })
  return { playersData, housesData2, centroid, angle, globalData, playerData }
}
