/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */

import React from "react"

import { ReactComponent as GoalSvg } from "../../assets/svg/trophy.svg"
import { ReactComponent as InflationSvg } from "../../assets/svg/inflation.svg"
import { ReactComponent as PopularitySvg } from "../../assets/svg/reputation.svg"
import { ReactComponent as ComplaintSvg } from "../../assets/svg/plaintes.svg"
import { ReactComponent as FlagSvg } from "../../assets/svg/startingFlag.svg"
import { ReactComponent as DiceSvg } from "../../assets/svg/dice.svg"
import { ReactComponent as PathSvg } from "../../assets/svg/path.svg"
import { ReactComponent as TimeSvg } from "../../assets/svg/hourglass.svg"
import { ReactComponent as HouseSvg } from "../../assets/svg/house.svg"
import { ReactComponent as UpgradeSvg } from "../../assets/svg/upgrade.svg"
import { ReactComponent as AuctionSvg } from "../../assets/svg/enchere.svg"
import { ReactComponent as HotelSvg } from "../../assets/svg/hotel.svg"
import { ReactComponent as CardSvg } from "../../assets/svg/luck.svg"
import { ReactComponent as BankSvg } from "../../assets/svg/bank.svg"
import { ReactComponent as SubwaySvg } from "../../assets/svg/metro.svg"
import { ReactComponent as PoliceSvg } from "../../assets/svg/user-police.svg"
import { ReactComponent as ParcSvg } from "../../assets/svg/bench-tree.svg"
import { ReactComponent as CardMoney } from "../../assets/svg/luckyCards/MONEY_CARD.svg"
import { ReactComponent as CardPopularity } from "../../assets/svg/luckyCards/POPULARITY_CARD.svg"
import { ReactComponent as CardDice } from "../../assets/svg/luckyCards/DICE_CARD.svg"
import { ReactComponent as CardImmunity } from "../../assets/svg/luckyCards/IMMUNITY_CARD.svg"
import { ReactComponent as CardDiscount } from "../../assets/svg/luckyCards/DISCOUNT_CARD.svg"
import { ReactComponent as CardMove } from "../../assets/svg/luckyCards/MOVE_CARD.svg"

import { COLORS } from "../../export/const"

import mapImage from "../../assets/images/regleMap.png"
import houseImage from "../../assets/images/regleHouseCard.png"
import Button from "../button"

const RegleDesktop = ({ onHideRules }) => {
  const style = {
    ruleBox: {
      gridTemplateColumns: "740px 740px",
      gridTemplateRows: "10px 100px 200px 370px 800px",
      gap: "0px 0px",
      gridTemplateAreas: `
     "Title Title"
     "Subtitle Subtitle"
     "Objectif_Popularite Inflation_Plaintes"
     "Base Maisons"
     "Cartes Batiments"`,
      height: "83%",
      width: "fit-content",
      display: "grid",
      flexWrap: "wrap",
      backgroundColor: COLORS.white,
      borderRadius: "40px",
      fontFamily: "SFProMedium",
      margin: "auto",
      justifyContent: "center",
      alignItems: "center",
      padding: "30px",
      color: COLORS.black,
      boxShadow: "5px 10px 8px rgba(32, 32, 32, .25)",
      position: "relative",
      top: "140px",
      overflow: "auto",
      scrollbarWidth: "none",
      borderStyle: "solid",
      borderWidth: 15,
      borderColor: COLORS.white,
    },
    objectif_popularite: {
      gridArea: "Objectif_Popularite",
      margin: "auto",
    },
    inflation_plaintes: {
      gridArea: "Inflation_Plaintes",
      margin: "auto",
    },
    title: {
      gridArea: "Title",
      COLORS: COLORS.black,
      textAlign: "center",
      verticalAlign: "top",
      fontSize: "30px",
      lineHeight: "auto",
      width: "400px",
      margin: "auto",
    },
    subTitle: {
      gridArea: "Subtitle",
      COLORS: COLORS.grey,
      textAlign: "center",
      verticalAlign: "top",
      fontSize: "20px",
      lineHeight: "auto",
    },
    base: {
      gridArea: "Base",
      margin: "auto",
      marginTop: "30px",
    },
    maisons: {
      gridArea: "Maisons",
      margin: "auto",
      marginTop: "30px",
    },
    cartes: {
      gridArea: "Cartes",
      margin: "auto",
      marginTop: "30px",
      width: "740px",
    },
    batiments: {
      gridArea: "Batiments",
      margin: "auto",
      marginTop: "30px",
      width: "740px",
    },
    section: {
      COLORS: COLORS.black,
      textAlign: "center",
      verticalAlign: "top",
      fontSize: "20px",
      lineHeight: "auto",
      marginLeft: "20px",
    },
    box: {
      borderRadius: "10px",
      backgroundColor: COLORS.lightGrey,
      color: COLORS.black,
      fontSize: "16px",
      display: "flex",
      margin: "10px",
      padding: "5px",
      alignItems: "center",
    },
    card: {
      height: 300,
      width: 300,
      margin: 5,
    },
    icon: {
      margin: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      borderRadius: "10px",
      margin: "10px",
    },
    gridCard: {
      display: "grid",
      margin: "auto",
      gridAutoColumns: "1fr",
      gridTemplateColumns: "220px 220px 220px",
      gridTemplateRows: "1fr 1fr",
      gap: "0px 0px",
      gridTemplateAreas: `"carte-argent carte-popularité carte-dés-double"
        "carte-immunité carte-remise carte-déplacement"`,
    },
    gridCardMove: {
      gridArea: "carte-déplacement",
      margin: "auto",
    },
    gridCardDiceDouble: {
      gridArea: "carte-dés-double",
      margin: "auto",
    },
    gridCardImmunity: {
      gridArea: "carte-immunité",
      margin: "auto",
    },
    gridCardLoyer: {
      gridArea: "carte-remise",
      margin: "auto",
    },
    gridCardMoney: {
      gridArea: "carte-argent",
      margin: "auto",
    },
    gridCardPopularity: {
      gridArea: "carte-popularité",
      margin: "auto",
    },
    button: {
      position: "fixed",
      top: "140px",
      right: "100px",
      color: COLORS.white,
      width: "80px",
      backgroundColor: COLORS.black,
      cursor: "pointer",
      fontFamily: "SFProMedium",
      borderRadius: 30,
      display: "flex",
      flexDirection: "column",
      lineHeight: 1,
      paddingBottom: 10,
      paddingTop: 10,
      justifyContent: "center",
      alignItems: "center",
      border: `solid ${COLORS.white} 10px`,
    },
    bullet: {
      width: "50px",
      backgroundColor: COLORS.white,
      borderRadius: 500,
      marginRight: 5,
    },
  }
  return (
    <div style={style.ruleBox} className="regleDesktop">
      <div style={{ gridArea: "Title", textAlign: "right" }}>
        <div
          style={{
            width: "10%",
            left: "88%",
            top: "30px",
            position: "relative",
            borderRadius: 50,
            border: `solid ${COLORS.white} 10px`,
            backgroundColor: COLORS.white,
          }}
        >
          <Button
            onClick={() => {
              onHideRules()
            }}
            color={COLORS.black}
            text="Fermer"
          />
        </div>
      </div>
      <div style={style.title}>Règles de CapitaLille</div>
      <div style={style.subTitle}>
        Bienvenue à Lille, le principe du jeu est de s'enrichir en devenant
        propriétaires de maisons à Lille afin de gagner la partie.
      </div>
      <div style={style.objectif_popularite}>
        <div
          style={{
            ...style.box,
            height: "65px",
            width: "710px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={style.bullet}>
            <GoalSvg width={30} style={style.icon} fill={COLORS.yellow} />
          </div>
          <div>
            <u>Objectif</u>
            <br />
            <span>Obtenir 25 % des maisons de Lille.</span>
          </div>
        </div>
        <div
          style={{
            ...style.box,
            height: "65px",
            width: "710px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={style.bullet}>
            <PopularitySvg
              width={35}
              height={35}
              style={style.icon}
              fill={COLORS.blue}
            />
          </div>
          <div>
            <u>Popularité</u>
            <br />
            <span>
              La popularité fera varier le prix immobilier, cette valeur est
              propre à chaque joueur, si un joueur à sa popularité trop basse il
              sera placé en prison.
            </span>
          </div>
        </div>
      </div>
      <div style={style.inflation_plaintes}>
        <div
          style={{
            ...style.box,
            height: "65px",
            width: "710px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={style.bullet}>
            <InflationSvg width={30} style={style.icon} fill={COLORS.green} />
          </div>
          <div>
            <u>Inflation</u>
            <br />
            <span>
              Durant la partie le prix de l’immobilier va varier pour tous les
              joueurs.
            </span>
          </div>
        </div>
        <div
          style={{
            ...style.box,
            height: "65px",
            width: "710px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={style.bullet}>
            <ComplaintSvg
              height={30}
              width={30}
              style={style.icon}
              fill={COLORS.red}
            />
          </div>
          <div>
            <u>Plaintes</u>
            <br />
            <span>
              Les joueurs ont la possibilité de déposer plusieurs plaintes
              contre d’autres joueurs, afin de faire baisser leurs popularités.
            </span>
          </div>
        </div>
      </div>
      <div style={style.base}>
        <span style={style.section}>Le jeu</span>
        <div style={{ display: "flex" }}>
          <div>
            <div
              style={{
                ...style.box,
                height: "45px",
                width: "405px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={style.bullet}>
                <FlagSvg
                  width={30}
                  height={30}
                  style={style.icon}
                  fill={COLORS.red}
                />
              </div>
              <span>
                Chaque joueur commence sur la case départ qui se situe à
                Cormontaigne.
              </span>
            </div>
            <div
              style={{
                ...style.box,
                height: "65px",
                width: "405px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={style.bullet}>
                <DiceSvg
                  width={30}
                  height={30}
                  style={style.icon}
                  fill={COLORS.orange}
                />
              </div>
              <span>
                Chaque tour les joueurs pourront avancer en jetant des dés qui
                les feront avancer selon résultat.
              </span>
            </div>
            <div
              style={{
                ...style.box,
                height: "40px",
                width: "405px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={style.bullet}>
                <PathSvg
                  width={30}
                  height={30}
                  style={style.icon}
                  fill={COLORS.blue}
                />
              </div>
              <span>
                Lors de deux possibilités de chemin, un chemin sera choisi
                aléatoirement.{" "}
              </span>
            </div>
            <div
              style={{
                ...style.box,
                height: "65px",
                width: "405px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={style.bullet}>
                <TimeSvg
                  width={30}
                  height={30}
                  style={style.icon}
                  fill={COLORS.green}
                />
              </div>
              <span>
                Les joueurs pourront faire un tour seulement une demi-journée
                afin de laisser le temps à tous les joueurs de faire leur tour.
              </span>
            </div>
          </div>
          <img style={style.image} src={mapImage} alt="map" height="285px" />
        </div>
      </div>
      <div style={style.maisons}>
        <span style={style.section}>Maisons</span>
        <div style={{ display: "flex" }}>
          <div>
            <div
              style={{
                ...style.box,
                height: "60px",
                width: "405px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={style.bullet}>
                <HouseSvg
                  width={30}
                  height={30}
                  style={style.icon}
                  fill={COLORS.blue}
                />
              </div>
              <span>
                Le joueur tombé sur une maison pourra l'acheter si la celle-ci
                n'appartient à personne ou payer un loyer s'il la maison
                appartient déjà à un joueur.
              </span>
            </div>
            <div
              style={{
                ...style.box,
                height: "40px",
                width: "405px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={style.bullet}>
                <UpgradeSvg
                  width={30}
                  height={30}
                  style={style.icon}
                  fill={COLORS.blue}
                />
              </div>
              <span>
                Les joueurs peuvent améliorer leurs maisons afin d’améliorer le
                prix du loyer.
              </span>
            </div>
            <div
              style={{
                ...style.box,
                height: "55px",
                width: "405px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={style.bullet}>
                <AuctionSvg
                  width={30}
                  height={30}
                  style={style.icon}
                  fill={COLORS.purple}
                />
              </div>
              <span>
                Les joueurs peuvent mettre en vente leurs maisons, les autres
                joueurs peuvent ainsi racheter cette maison à un prix
                concurrentiel.
              </span>
            </div>
            <div
              style={{
                ...style.box,
                height: "60px",
                width: "405px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={style.bullet}>
                <HotelSvg
                  width={30}
                  height={30}
                  style={style.icon}
                  fill={COLORS.yellow}
                />
              </div>
              <span>
                Lorsqu'un joueur possède quatre maisons sur un terrain il peut
                construire un hôtel, sa valeur est plus élevée qu'une maison.
              </span>
            </div>
          </div>
          <img style={style.image} src={houseImage} alt="map" height="285px" />
        </div>
      </div>
      <div style={style.cartes}>
        <span style={{ ...style.section }}>Cartes Chances</span>
        <div style={{ ...style.box, height: "65px", width: "710px" }}>
          <div style={style.bullet}>
            <CardSvg
              width={30}
              height={30}
              style={style.icon}
              fill={COLORS.green}
            />
          </div>
          <span>
            Lorsqu’un joueur tombe sur la case “cartes chances”, il obtient une
            carte chance permettant d’obtenir des bonus ou des malus pendant la
            partie, ses effets sont uniques et s’activent immédiatement ou au
            prochain tour selon ce qui est inscrit sur la carte.
          </span>
        </div>
        <div style={style.gridCard}>
          <div style={style.gridCardMoney}>
            <CardMoney style={style.card} />
          </div>
          <div style={style.gridCardPopularity}>
            <CardPopularity style={style.card} />
          </div>
          <div style={style.gridCardDiceDouble}>
            <CardDice style={style.card} />
          </div>
          <div style={style.gridCardImmunity}>
            <CardImmunity style={style.card} />
          </div>
          <div style={style.gridCardLoyer}>
            <CardDiscount style={style.card} />
          </div>
          <div style={style.gridCardMove}>
            <CardMove style={style.card} />
          </div>
        </div>
      </div>
      <div style={style.batiments}>
        <span style={style.section}>Bâtiments</span>
        <div
          style={{
            ...style.box,
            height: "48px",
            width: "710px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={style.bullet}>
            <BankSvg
              width={30}
              height={30}
              style={style.icon}
              fill={COLORS.red}
            />
          </div>
          <div>
            <u>Banque</u>
            <br />
            <span>Le joueur devra payer ses impôts.</span>
          </div>
        </div>
        <div
          style={{
            ...style.box,
            height: "52px",
            width: "710px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={style.bullet}>
            <SubwaySvg
              width={30}
              height={30}
              style={style.icon}
              fill={COLORS.blue}
            />
          </div>
          <div>
            <u>Métro</u>
            <br />
            <span>Permet au joueur de se déplacer vers une autre station.</span>
          </div>
        </div>
        <div
          style={{
            ...style.box,
            height: "60px",
            width: "710px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={style.bullet}>
            <ParcSvg
              width={30}
              height={30}
              style={style.icon}
              fill={COLORS.green}
            />
          </div>
          <div>
            <u>Parc</u>
            <br />
            <span>
              Le joueur obtient un déplacement +2 au prochain tour avec une
              augmentation de popularité et s’il souhaite peu avoir sa
              popularité au maximum.
            </span>
          </div>
        </div>
        <div
          style={{
            ...style.box,
            height: "45px",
            width: "710px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={style.bullet}>
            <PoliceSvg
              width={30}
              height={30}
              style={style.icon}
              fill={COLORS.blue}
            />
          </div>
          <div>
            <u>Gendarmerie</u>
            <br />
            <span>
              Permet au joueur de déposer une plainte contre un autre joueur.
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegleDesktop
